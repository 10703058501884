import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import endSuction from '../assets/images/endSuctionSelected.jpg';
import horizontalPump from '../assets/images/horizontal-pump.png';
import verticalPump from '../assets/images/vertical-pump.png';
import customFetch from "../utils/axios";
import { HandleUnauthorization } from "../utils/HandleUnauthorization";
import ToastMessage from '../components/ToastMessage';
import { Modal } from 'react-bootstrap';
import loaderImage from '../assets/images/loader.gif';
import { reloadData,postEnquiry } from '../features/cart/cartSlice';
import { useDispatch ,useSelector} from 'react-redux';

const BookingCart = (props) => {
  const dispatch = useDispatch();
  const { isLoading} = useSelector((store) => store.cartBooking);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [stocklist, setStockList] = useState([]);
  // const [cart_qty, setCartQty] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [countryValue, setCountryValue] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [userdata, setUserData] = useState(null);
  const [show_warning, setShowWarning] = useState(false);

  useEffect(()=>{
    getUserData();
  },[props.openCart]);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('loader_view_listing');
    } else {
      document.body.classList.remove('loader_view_listing');
    }
    return () => {
      document.body.classList.remove('loader_view_listing');
    };
  }, [isLoading]);

  const getUserData=()=>{
    setUserData(JSON.parse(localStorage.getItem('user')));
    const user_data = JSON.parse(localStorage.getItem('user'));
    setUserData(user_data);
    if(user_data !== null){
        setName(user_data.name);
        setEmail(user_data.email);
        setContact(user_data.contact_number);
      }
      fetchCartDetails();
  }
  const closeCart = ()=>{
    // sessionStorage.setItem('cartOpen', false);
    setErrors({});
    props.closeCart();
    dispatch(reloadData());
  }

  const fetchCartDetails = async () => {
    try {
        let session_id = localStorage.getItem('sessionId');
        const response = await customFetch.get(`/get_cart_details?session_id=`+session_id);
        const stocklist = response.data.cartdata;
        setStockList(stocklist);
        let total_quantity = 0;
        stocklist.map((item)=>{
          total_quantity = total_quantity + item.quantity;
        })
      props.cartQuantity(total_quantity);
      document.getElementsByClassName('booking_count')[0].innerHTML = total_quantity;
    } catch (err) {
      if(userdata !== null){
        if (err.response.status === 401 || err.response.status === 500) {
          setIsLogout(true)
        }
      }
        window.scrollTo(0,0);
    }
};
  const handleQuantityChange = (event,item,rowindex,maxval) => {
    const { value } = event.target;
    // setCartQty(value);

    if (!isNaN(value)) {
      if(parseInt(value) > maxval){
        return false;
      }
      if(parseInt(value) < 1){
        return false;
      }
        insertToCart(item,value);
        stocklist[rowindex].quantity = value;
        
        let code_value = item.avail_id;
        if(document.getElementsByClassName('cart_code_'+code_value)[0] !== undefined){
          document.getElementsByClassName('cart_code_'+code_value)[0].value = value;
        }
    }
  };
  const insertToCart= async (item,value)=>{
    const formData = new FormData();

    formData.append("pump_id", item.pump.id);
    formData.append("quantity", value);
    formData.append("session_id", localStorage.getItem('sessionId'));
    formData.append("avail_id", item.avail_id);
    
    try {
        setApiSuccessMessage('');
        const response = await customFetch.post('/add_cart',formData);
        const { status, message} = response.data;
        if (status === true) {
            setApiSuccessMessage("Cart updated successfully");
            let cart_count = response.data.cart_count;                           
            document.getElementsByClassName('booking_count')[0].innerHTML = cart_count;
        } 
    } catch (err) {
        if (err.response.status === 401 || err.response.status === 500) {
        setIsLogout(true)
        }
        
        window.scrollTo(0,0);
    }
}

  const addCartEnquiry = async (item,value)=>{
    if(userdata === null){
      setShowWarning(true);
    }else{
      // setIsLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("contact", contact);
      formData.append("location", JSON.stringify(countryValue));
      formData.append("stocklist", JSON.stringify(stocklist));
      
      dispatch(postEnquiry({data: formData}));
      closeCart('Booked');
      // try {
      //     const response = await customFetch.post('/add_cart_enquiry',formData);
      //     const { status, message, error, result } = response.data;
      //     if (status === true) {
      //       setIsLoading(false);
      //       sessionStorage.setItem('cartOpen', false);
      //       setApiSuccessMessage(message);
      //       setTimeout(() => {
      //          closeCart('Booked');
      //       }, 500);
      //       dispatch(reloadData())
      //       // window.location.reload();
      //     } 
      // } catch (err) {
      //   setIsLoading(false);
      //     if (err.response.status === 401 && err.response.status === 500 ) {
      //       setIsLogout(true)
      //     }
      //     setErrorMessage('There is something wrong. Please try again');
      //     window.scrollTo(0,0);
      // }
    }
  }
  const removeFromCart = async (item)=>{
    try {
      setApiSuccessMessage('');
        const response = await customFetch.get('/remove_stock/'+item.id);
        const { status, message} = response.data;
        if (status === true) {
          setApiSuccessMessage(message);
          fetchCartDetails();
          let code_value = item.avail_id;
          if(document.getElementsByClassName('cart_code_'+code_value)[0] !== undefined){
            document.getElementsByClassName('cart_code_'+code_value)[0].value = 0;
          }
        } 
    } catch (err) {
      console.log(err);
        // setIsLoading(false);
        if (err.response.status === 401 ) {
          setIsLogout(true)
        }
        window.scrollTo(0,0);
    }
  }

  const handleClose = () => {
    setShowWarning(false);
}
  return (
    <>
      {
        (isLoading) && (<div className='show_loader_listing'>  <img src={loaderImage} /> </div>)
      }
      <div className="filter_modal_content booking_cart">
        <div className="filter_modal_head">
          {
            (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
          }
          <h2>Booking Cart</h2>
          <Link onClick={() => {closeCart()}} to={window.location.href} className="red_cross">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 19L19 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19 19L5 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </Link>
        </div>
        <div className='product_wrapper'>
        { (stocklist && stocklist.length > 0) && 
          // {
             stocklist.map((item, index) => ( <div className='booking_cart_product'>
          <div className="product_compare">
              {item.pump.pump_type==='End Suction' && <img src={endSuction} alt="pump selected"/>}
              {(item.pump.pump_type==='Splitcase' || item.pump.pump_type==='Split case') && <img src={horizontalPump} alt="pump selected"/>}
              {(item.pump.pump_type==='Vertical Turbine') && <img src={verticalPump} alt="pump selected"/>}
            <div className='product_details_col'>
              <p className='pro_order'>
                <span className="product_name">{item.pump.model}</span>
                <span className="product_id">{item.pump.code}</span>
              </p>
              <p className='gpm'><span>{item.pump.flow} {item.pump.flow_uom}</span> <span>{item.pump.pressure} {item.pump.pressure_uom}</span></p>
              <p className='gpm'><span>{item.pump_location}</span></p>
            </div>
          </div> 
          
          <div className="qty_carts">
              <div className="available_carts">
                   Qty <input type="number" 
                    value={item.quantity}
                    onChange={(e)=>{handleQuantityChange(e,item,index,item.availability.qty_avl)}}
                    name="tentacles" min="1" max={item.availability.qty_avl} />
                    
            </div>  
            <Link onClick={() => {removeFromCart(item)}} className="red_cross">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.75049 14.25L14.2505 3.75" stroke="#BC141A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.2505 14.25L3.75049 3.75" stroke="#BC141A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Link>
          </div>
          
        </div>))}
        </div>
        {stocklist && stocklist.length===0 &&
          <div className='booking_cart_product'>
          <h4>Your cart is empty</h4>
  
          </div>
        }
        
        { stocklist && stocklist.length>0 &&<button onClick={addCartEnquiry} type='button' className='w-100 darkred_btn'>
            <span>{isLoading ? 'loading...' : 'Send Enquiry'} </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>}
      </div>
      <Modal show={show_warning} onHide={handleClose} centered className="delete_popup_modal" backdrop="static">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <p className="confirm_msg">Please Login To Continue.</p>
          
        </Modal.Body>
        <Modal.Footer>
            <a href='/login' className="darkred_btn text_dec">Login</a> 
            <a href='/register' className="darkred_btn text_dec">Sign up</a>
        </Modal.Footer>
    </Modal>
      {
        (isLogout) && (<HandleUnauthorization />)
      }
    </>
  );
};

export default BookingCart;