
const WorkInProgress = () => {

  return (
    <>
      <div className="container">
        <div className="table_box">
          <div className="alignment" style={{
            textAlign: 'center',
            padding: '50px'
          }}>
            <h1>Work In Progress</h1>
          </div>
        </div>
      </div>

    </>
  );
};
export default WorkInProgress;
