import React from 'react';
import { useState, useEffect } from 'react';
import { FormRowSelect } from '.';
import { MultiSelect } from 'primereact/multiselect';
import {DriverType, PumpType } from '../utils/clientMasterAPI';
import customFetch from "../utils/axios";


const PumpFilterModal = (props) => {
    
    const closeMenu = () => {
      props.closeModal();
    };
    
    const [pumpTypeList, setPumpTypeList] = useState([]);
    const [pumpTypeValue, setPumpTypeValue] = useState('');

    const [dutyPointList, setDutyPointList] = useState([]);
    const [dutyPointValue, setDutyPointValue] = useState('');

    const [driverTypeList, setDriverTypeList] = useState([]);
    const [driverTypeValue, setDriverTypeValue] = useState('');

    const [visibilityList, setVisibilityList] = useState(["Yes","No"]);
    const [visibilityVal, setVisibilityVal] = useState('');

    const [itemCode, setItemCode] = useState('');

    const handleChange = (e, name) => {
      let value = e.target.value;
      
      if(name === 'dutyPoint') {
        setDutyPointValue(value)
      } else if(name === 'pump_type'){
        setPumpTypeValue(value);
      } else if(name === 'driverType'){
        setDriverTypeValue(value);
      } else if(name === 'visibility') {
        setVisibilityVal(value);  
      }
    }

    const applyFilter = ()=>{
        props.pumpTypeValues(pumpTypeValue);
        props.dutyPointValues(dutyPointValue);
        props.driverTypeValues(driverTypeValue);
        props.visibilityVals(visibilityVal);
        props.itemCodeValues(itemCode);

        let filterValue = {
          'pump_type'   : pumpTypeValue, 
          'duty_point'  : dutyPointValue,
          'driver_type' : driverTypeValue, 
          'visibility'  : visibilityVal 
        };

        //sessionStorage.setItem('stockPumpFilterValue',JSON.stringify(filterValue));
        
      closeMenu();
    }

    const clearFilter = ()=>{
      props.pumpTypeValues('');
      props.dutyPointValues('');
      props.driverTypeValues('');
      props.visibilityVals('');
      props.itemCodeValues('');

      setDutyPointValue('');
      setPumpTypeValue('');
      setDriverTypeValue('');
      setVisibilityVal(''); 

      //sessionStorage.setItem('stockPumpFilterValue',JSON.stringify(filterValue));
      
    closeMenu();
  }


    


    useEffect(() => {
      fetchPumpType();
      fetchDriverType();
      fetchDutyPoint();
      
      if(props.clearFilterData==true){
        setDutyPointValue(null)
        setPumpTypeValue(null)
        setDriverTypeValue(null)
        setVisibilityVal(null)

        // setCountryValue(null);
        // setstatusValue([]);
        // setEnquirySendValue('');
        props.locationValue(null);
        props.statusValue(null);
        props.enquirySendValueCall(null);
      }
    }, [props.clearFilterData]);

    const fetchPumpType = async () => {
      const getPumpTypeData =  await PumpType();
      if(getPumpTypeData[0]) {
        
        let dutyPointArr = []
        getPumpTypeData[1].map((item, index) => {
          dutyPointArr[index] =  item.name;
        });
        
        setPumpTypeList(dutyPointArr)

      } 
    }

    const fetchDriverType = async () => {
      const getDriverTypeData =  await DriverType();
      
      if(getDriverTypeData[0]) {
        setDriverTypeList(getDriverTypeData[1])
      } 
    }

    const fetchDutyPoint = async () => {
      const response = await customFetch.get(`/get-duty-point`);
      const getDutyPoint =  response.data.data;
      
     

      if(getDutyPoint) {
        setDutyPointList(getDutyPoint);
      } 
    }

    return(
        <>            
            <div className="filter_modal_content" >              
                <div className="filter_modal_head">
                    <h2>Filters</h2>
                    <a href="javascript:;" onClick={closeMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 19L19 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19 19L5 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className="filter_modal_body">
                    <form>
                        <div className="new_label">
                            
                            <label className='form-label'>Item Code</label>
                            <div className='form-row'>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="item_code"
                                onChange={ (e) => setItemCode(e.target.value) }
                            />
                          </div>
                        </div>
                        
                        
                        
                        <div className="new_label">
                          <label>Pump Type</label>
                          <FormRowSelect
                            name='PumpType'
                            list={pumpTypeList}
                            value={pumpTypeValue}
                            placeholder="All" 
                            defaultValue={"placeholder"}
                            handleChange={(e)=>{ handleChange(e,'pump_type')}}
                          /> 
                        </div>  
                          
                        <div className='new_label'>
                          <label>Duty Point</label>
                          <FormRowSelect
                            name='dutyPoint'
                            list={dutyPointList}
                            value={dutyPointValue}
                            placeholder="Select Duty Point" 
                            defaultValue={"placeholder"}
                            handleChange={(e)=>{ handleChange(e,'dutyPoint')}}
                          />
                        </div>
                        <div className='new_label'>
                          <label>Driver Type</label>
                          <FormRowSelect
                            name='driverType'
                            list={driverTypeList}
                            value={driverTypeValue}
                            placeholder="Select Duty Point" 
                            defaultValue={"placeholder"}
                            handleChange={(e)=>{ handleChange(e,'driverType')}}
                            callFrom='enquiry_filter'
                            
                          />
                        </div>  

                        <div className='new_label'>
                          <label>Visibility</label>
                          <FormRowSelect
                            name='visibility'
                            list={visibilityList}
                            value={visibilityVal}
                            placeholder="Select Visibility" 
                            defaultValue={"placeholder"}
                            handleChange={(e)=>{ handleChange(e,'visibility')}}
                            callFrom='enquiry_filter'
                            
                          />
                        </div>  

                        <button type='button' onClick={applyFilter} className='w-100 darkred_btn'>
                            <span>Apply Filter</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <button type='button' onClick={clearFilter} className='w-100 darkred_btn'>
                            <span>Clear</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PumpFilterModal;