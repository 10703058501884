const Footer = () => {
  return(
    <>
        <footer>
            <div className="container">
                <h6>@ {(new Date().getFullYear())} Copyrights NAFFCO. All Rights reserved.</h6>
            </div>
        </footer>
    </>
  );
};
export default Footer;