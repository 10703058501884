import React, { useState } from "react";
import {EnquiriesHead, EnquiriesTabs} from '../../../components';
        
const EnquiresList = (props) => {

  const [location_value, setLocationValue] = useState(null);
  const [status_Value, setstatusValue] = useState(null);
  const [enquiry_send_value, setEnquirySendValue] = useState(null);
  const [enquiry_number_value, setEnquiryNumberValue] = useState('');

  const statusValue = (value) =>{
    setstatusValue(value);
  };
  
  const locationValue = (value) =>{
    setLocationValue(value);
  };
  
  const enquirySendValue = (value) =>{
    setEnquirySendValue(value);
  };

  const enquiryNumberValue = (value) =>{
    setEnquiryNumberValue(value);
  };
    
  return (
      <>
      <div className="container">
        <div className="table_box">
          <EnquiriesHead heading="Enquiries" statusValue={statusValue} locationValue={locationValue} enquirySendValue={enquirySendValue} enquiryNumberValue={enquiryNumberValue} />
          <EnquiriesTabs statusValue={status_Value} locationValue={location_value} enquirySendValue={enquiry_send_value} enquiryNumberValue={enquiry_number_value}/>        
        </div>   
      </div>
    </>
  );
  }
  
export default EnquiresList