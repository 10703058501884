import ReactDOM from "react-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import React, { useState, useEffect, useRef } from "react";
import { SplitButton } from 'primereact/splitbutton';
import PopupModalEdit from './PopupModalEdit';
import PopupModalDelete from './PopupModalDelete';
import ToastMessage from './ToastMessage';

export default function DataTableField({callFrom, addOrEditMode, dataList, callBackList, changePage, pagelimit}) {

  /**hooks */
  const toast = useRef(null);
  const dt = useRef(null);

  /**states */
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const [first, setFirst] = useState(0);
  useEffect(() => {

  }, []);

  const username = (usernameData) =>{
      return (
        <div className="newUser" style={{ textTransform: 'capitalize'}}>{usernameData.name}</div>
      );
    } 
    const companyId = (data) =>{
      return <td>{(data.company_id !== null && data.company_id !== '')  ? data.company_id : 'N/A'}</td>;
    } 
   const userRole = (data) =>{
      return data.role.charAt(0).toUpperCase() + data.role.slice(1);
    } 
    const contactNumber = (data) =>{
      return <td>{data.contact_number !== null ? data.contact_number : 'N/A'}</td>;
    } 
    const statusUpdate = (rowData) => {
      const dotColor = rowData.status === 'active' ? '#0BDA6C' : '#F57E20';

      return (
        <div className="status_shows">
          <svg width="8" height="8" style={{ marginRight: '6px' }}>
            <circle cx="4" cy="4" r="4" fill={dotColor} />
          </svg>
          <div  style={{ textTransform: 'capitalize'}}> {rowData.status}</div>
        </div>
      );
    };
   
    const items =(data) => ([
      {
        label: "Edit",
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.0529 4.28273L4.21122 11.5244C3.95289 11.7994 3.70289 12.3411 3.65289 12.7161L3.34455 15.4161C3.23622 16.3911 3.93622 17.0577 4.90289 16.8911L7.58622 16.4327C7.96122 16.3661 8.48622 16.0911 8.74455 15.8077L15.5862 8.56607C16.7696 7.31607 17.3029 5.89107 15.4612 4.1494C13.6279 2.4244 12.2362 3.03273 11.0529 4.28273Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M9.91113 5.49072C10.2695 7.79072 12.1361 9.54906 14.4528 9.78239" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
        command: async (event) => {
         // alert(JSON.stringify(data))
          setSelectedRow(data)
          setApiSuccessMessage('')
          setIsEditOpen(true)
        },
      },
      {
        label: "Delete",
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.5 4.98356C14.725 4.70856 11.9333 4.56689 9.15 4.56689C7.5 4.56689 5.85 4.65023 4.2 4.81689L2.5 4.98356" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.0835 4.1415L7.26683 3.04984C7.40016 2.25817 7.50016 1.6665 8.9085 1.6665H11.0918C12.5002 1.6665 12.6085 2.2915 12.7335 3.05817L12.9168 4.1415" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.6084 13.75H11.3834" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9165 10.4165H12.0832" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
        command: () => {
          setSelectedRow(data)
          setApiSuccessMessage('')
          setIsDeleteOpen(true)
        },
      },
    ]);
    const dotsShows = (rowDataMy) => 
    {
      return (
        <SplitButton        
          model={items(rowDataMy)}
        />

      )
    }
  /**events */
    const onCloseCallBack = (message=null) => {
      setApiSuccessMessage('')
      if(message) {
        setApiSuccessMessage(message)

        callBackList();
      }
      setIsEditOpen(false)
      setIsDeleteOpen(false)
    }

    const onPageChange = (event) => {
      setFirst(event.first);
      let page = event.page + 1;
      changePage(page);
  };

  return (
    <>
      {/* <div className="container"> */}
      
      {/* {
        (apiSuccessMessage) && (<div className="api-success">{apiSuccessMessage}</div>)
      }
      {
        (apiErrorMessage) && (<div className="api-error">{apiErrorMessage}</div>)
      } */}
        {
          (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
        }
         {
          (apiErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={apiErrorMessage} />)
        }
                
      <div>
        <div className="custom_dataTable">
          <DataTable
            ref={dt}
            value={dataList.data}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
          >
            <Column field="name" header="Name" body={username}  sortable></Column>
            {(callFrom!='User') && <Column body={statusUpdate} header="Status" field="status" />}
            {(callFrom!='User')  && <Column field="company_id" header="Company ID" body={companyId} sortable></Column>}
            <Column field="email" header="Email" sortable></Column>
            {(callFrom=='User') &&  <Column field="role" header="Role" body={userRole}  sortable></Column>}
            {(callFrom!='User') &&  <Column field="contact_number" header="Contact" body={contactNumber} sortable></Column>}
            {(callFrom!='User') &&  <Column field="company_name" header="Company Name" sortable></Column>}
            <Column body={dotsShows} field="action" header="Action" sortable className="dots_col" style={{ textAlign: 'right', width: '100px', minWidth: '100px'}}></Column>
          </DataTable>      
        </div>
      </div>
      {
        (isEditOpen) && (<PopupModalEdit callFrom={callFrom} addOrEditMode={addOrEditMode} onCloseCallBack={onCloseCallBack} rowdata={selectedRow} />)
      }
      {
        (isDeleteOpen) && (<PopupModalDelete  rowdata={selectedRow} onCloseCallBack={onCloseCallBack} callFrom={callFrom}/>)
      }
      {
        (dataList.total>0) && <div className="card">
        <Paginator first={first} rows={pagelimit} totalRecords={dataList.total}  onPageChange={onPageChange} />
      </div>
      }
    </>
  );
}