import React, { useState, useEffect } from "react";
import { DataTableField, EnquiriesHead } from '../../../components';
import customFetch from "../../../utils/axios";
import PopupModalEdit from '../../../components/PopupModalEdit';
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";
import ToastMessage from '../../../components/ToastMessage';

const UserList = () => {
  const [userlist, setUserList] = useState([]);
  const [show, setShow] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [pagelimit, setpagelimit] = useState(10);
  const [pageNo, setpageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [user_name, setUserName] = useState('');
  const [user_role, setUserRole] = useState('');
  const [user_email, setUserEmail] = useState('');

  useEffect(() => {
    fetchUser();
}, [pageNo,user_name,user_role,user_email]);

  const changePage = (page=1)=>{
    setpageNo(page);
  }
    const fetchUser = async () => {
      try {
          const response = await customFetch.get(`/userslist/user/`+pagelimit+`?page=`+pageNo,{params:{
            name:user_name,
            role:user_role,
            email:user_email
        }});
          const userlist = response.data.userslist;
          setUserList(userlist);

      } catch (err) {
          // console.log(err);
          if (err.response.status === 401) {
            setIsLogout(true)
          }
          setApiErrorMessage('There is something wrong. Please try again.')
          window.scrollTo(0,0);
      }
    };
    const callBackList = () => {
      fetchUser();
    }
    const handleShow=()=>{
      setApiSuccessMessage('')
      setShow(true);
    }

    const initialState = {
      first_name: '',
      last_name: '',
      email: '',
      role: null,
      status: '',
      company_id: '',
      id:null,
    };

    const onCloseCallBack = (message=null) => {
      setApiSuccessMessage('')
      if(message) {
        setApiSuccessMessage(message)

        callBackList();
      }
      setShow(false)
    }
    const UserName = (value)=>{
      setUserName(value)
    }
    const UserRole = (value) =>{
      setUserRole(value);
    }; 
    const UserEmail = (value) =>{
      setUserEmail(value);
    };

    return (
      <>
      {
          (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
        }
         {
          (apiErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={apiErrorMessage} />)
        }
        <div className="container">
          <div className="table_box" >
            <EnquiriesHead heading="Users" btnContent="Add New User" classToSee="addNewBtnShow" styleToSee={{marginRight:'15px'}} handleAddNewClick={handleShow} UserName={UserName} UserRole={UserRole} UserEmail={UserEmail}   />
            <div className="user_table">
            <DataTableField callFrom="User" addOrEditMode="Edit" dataList={userlist} callBackList={callBackList} changePage={changePage} pagelimit={pagelimit}/>
            </div>
            {
            (show) && (<PopupModalEdit callFrom="User" addOrEditMode='Add'  rowdata={initialState} onCloseCallBack={onCloseCallBack} />)
            }
          </div>
        </div>
        {
          (isLogout) && (<HandleUnauthorization />)
        }
      </>
    )
  }
  
export default UserList