import React, { useState, useEffect } from "react";
import {EnquiriesFirePump} from '../components';
import {EnquiriesShockPump} from '../components';
import { TabView, TabPanel } from 'primereact/tabview';

const EnquiriesTabs = (props) => {

  let selectTab = sessionStorage.getItem('back_stock_enquiry');
  
  if(selectTab == 1) {
   sessionStorage.removeItem('back_stock_enquiry'); 
  } else {
    selectTab = 0;  
  }

  const [activeIndex, setActiveIndex] = useState(selectTab);
  
  setTimeout(() => {
    if(selectTab == 1) {
      let tab_id = document.getElementsByClassName('p-tabview-nav-content')[0].id
      var link = document.getElementById(tab_id+'_header_'+selectTab);
      link.click();
    }   
  }, 100);

 
    return(
      <>        
        <div className="pumps_tabs">
            <TabView activeIndex={activeIndex}>
                <TabPanel header="Fire Pump">
                   <EnquiriesFirePump statusValue={props.statusValue} locationValue={props.locationValue} enquirySendValue={props.enquirySendValue} enquiryNumberValue={props.enquiryNumberValue}/>
                </TabPanel>
                <TabPanel header="Stock Pump">
                   <EnquiriesShockPump statusValue={props.statusValue} locationValue={props.locationValue} enquirySendValue={props.enquirySendValue} enquiryNumberValue={props.enquiryNumberValue} />
                </TabPanel>
            </TabView>
        </div>
      </>
    );
};

export default EnquiriesTabs;