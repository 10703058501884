import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { FormRow } from '../components';
function LoginPopupModal() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="custom_modal d-none">
                <Button variant="primary" onClick={handleShow}>
                  Login
                </Button>

                <Modal show={show} onHide={handleClose} centered className="login_popup_modal">
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Login</h2></Modal.Title>
                    </Modal.Header>
                    <form className=''>
                        <Modal.Body>
                        <div className='row'>
                            <div className='col-md-12 px_10'>
                            <FormRow
                                type='text'
                                labelText='Email'
                                name='email'
                                />  
                            </div> 
                            <div className='col-md-12 px_10 mb__0'>
                            <FormRow
                                type='text'
                                labelText='Password'
                                name='password'
                                />    
                            </div>  
                        </div>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button type="submit" className="darkred_btn">
                               Login
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </Button>
                            <p className="footer_signup">Don’t have an account? <span>Signup</span></p>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        </>
    );
}

export default LoginPopupModal;
