import React, { useState, useEffect  } from "react";
import FilterModal from "./FilterModal";
import PumpFilterModal from "./PumpFilterModal";
import EnquiryFilterModal from "../pages/admin/myOrder/EnquiryFilterModal";
import UserFilterModal from "../pages/admin/UserFilterModal";

const EnquiriesHead = (props) => {

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [clear_filter, setClearFilter] = useState(false);
  const [locationvalue, setLocationValue] = useState(null);
  const [statusvalue, setStatusValue] = useState(null);
  const [enquirySendValue, setEnquirySendValue] = useState(null);
  const [enquiryNumberValue, setEnquiryNumberValue] = useState('');

  // Manage Stock Pump 
  const [pumpTypeValue, SetPumpTypeValue] = useState('');
  const [dutyPointValue, SetDutyPointValue] = useState('');
  const [driverTypeValue, SetDriverTypeValue] = useState('');
  const [visibilityVal, SetVisibilityVal] = useState('');
  const [pump_model, setEnquiryNum] = useState('');

  //user
  const [user_name, setUserName] = useState(null);
  const [user_email, setUserEmail] = useState(null);
  const [user_role, setUserRole] = useState(null);
  const [company_name, setCompanyName] = useState(null);

    useEffect(() => {
        if (isFilterOpen) {
          document.body.classList.add('overflow_hidden');
        } else {
          document.body.classList.remove('overflow_hidden');
        }
        return () => {
          document.body.classList.remove('overflow_hidden');
        };
      }, [isFilterOpen]);
    
      const handleToggle = () => {
        setIsFilterOpen(true);
        setClearFilter(false);

      };  
      const handleAddNew = () => {
        props.handleAddNewClick();
      };

      const statusValue = (value) =>{
        setStatusValue(value);
        props.statusValue(value);

      };
      const locationValue = (value) =>{
        setLocationValue(value);
        props.locationValue(value);

      };
      const enquirySendValueCall = (value) =>
      {
        setEnquirySendValue(value);
        props.enquirySendValue(value);
      }
      
      const enquirySendNumberCall = (value) =>
      {
        setEnquiryNumberValue(value);
        props.enquiryNumberValue(value);
      }

      const enquiryNum = (value) =>
      {
        setEnquiryNum(value);
        props.enquiryNum(value);
      }
      const UserName = (value) =>{
        setUserName(value);
        props.UserName(value);
      }; 
      const UserRole = (value) =>{
        setUserRole(value);
        props.UserRole(value);
      }; 
      const UserEmail = (value) =>{
        setUserEmail(value);
        props.UserEmail(value);
      };
      const CompanyName = (value) =>{
        setCompanyName(value);
        props.CompanyName(value);
      };
      const closeModal = () =>{
        setIsFilterOpen(false);
      };
      const clearFilter = () =>{
        setClearFilter(true);
        setLocationValue(null);
        setStatusValue(null);
        setEnquirySendValue(null);
        setEnquiryNum(null);
        setEnquiryNumberValue('')
      };
    return(
      <>
      <div className="table-header table_heading_head">
        <div className="table_heading_content">
            <h1 className="p-m-0">{props.heading}</h1>
            <div className="conditional_block_show">
              <div className={`add_new d-none ${props.classToSee}`}>
                  <button className='darkred_btn' style={props.styleToSee} onClick={handleAddNew} >
                      <span>{props.btnContent}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                          <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                  </button>
              </div>


              <div className={`search_field_block d-none ${props.classSHows}`}>
              <div className="form-row"> 
                <span className="search_svg">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.66634 14.0002C11.1641 14.0002 13.9997 11.1646 13.9997 7.66683C13.9997 4.16903 11.1641 1.3335 7.66634 1.3335C4.16854 1.3335 1.33301 4.16903 1.33301 7.66683C1.33301 11.1646 4.16854 14.0002 7.66634 14.0002Z" stroke="#868594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M14.6663 14.6668L13.333 13.3335" stroke="#868594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
                <input className="search_field" placeholder="Search" />
              </div>
              </div>
              {(locationvalue !== null || statusvalue !== null || enquirySendValue != null) &&
                (props.heading=='Enquiries') && <a href="javascript:;" className="link_text" onClick={clearFilter} >clear</a>}
              <div className="filter_block">
                <div className={`filter_box ${isFilterOpen ? 'filterOpen' : ''}`}  >
                    {(props.heading=='Enquiries') && <a href="javascript:;" className="filter_link" onClick={handleToggle}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.50016 1.75H15.5002C16.4168 1.75 17.1668 2.5 17.1668 3.41667V5.25C17.1668 5.91667 16.7502 6.75 16.3335 7.16667L12.7502 10.3333C12.2502 10.75 11.9168 11.5833 11.9168 12.25V15.8333C11.9168 16.3333 11.5835 17 11.1668 17.25L10.0002 18C8.91683 18.6667 7.41683 17.9167 7.41683 16.5833V12.1667C7.41683 11.5833 7.0835 10.8333 6.75016 10.4167L3.5835 7.08333C3.16683 6.66667 2.8335 5.91667 2.8335 5.41667V3.5C2.8335 2.5 3.5835 1.75 4.50016 1.75Z" stroke="#393D53" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>filter</span>
                    </a> }
                    <div className="filter_modal">
                    <div className='overlay'></div>
                    {props.heading=="Enquiries" && <FilterModal statusValue={statusValue} locationValue={locationValue} enquirySendValueCall={enquirySendValueCall} enquirySendNumberCall={enquirySendNumberCall}  closeModal={closeModal} clearFilterData={clear_filter}/>         }            
                    </div>
                </div>
              </div>

              {/* {(pumpTypeValue !== null || dutyPointValue !== null || driverTypeValue != null || visibilityVal != null) &&
              (props.heading=='Manage Stock Pumps') && <a href="javascript:;" className="link_text" onClick={clearFilter} >clear</a>} */}
              <div className="filter_block">
                <div className={`filter_box ${isFilterOpen ? 'filterOpen' : ''}`}  >
                    {(props.heading=='Manage Stock Pumps') && <a href="javascript:;" className="filter_link" onClick={handleToggle}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.50016 1.75H15.5002C16.4168 1.75 17.1668 2.5 17.1668 3.41667V5.25C17.1668 5.91667 16.7502 6.75 16.3335 7.16667L12.7502 10.3333C12.2502 10.75 11.9168 11.5833 11.9168 12.25V15.8333C11.9168 16.3333 11.5835 17 11.1668 17.25L10.0002 18C8.91683 18.6667 7.41683 17.9167 7.41683 16.5833V12.1667C7.41683 11.5833 7.0835 10.8333 6.75016 10.4167L3.5835 7.08333C3.16683 6.66667 2.8335 5.91667 2.8335 5.41667V3.5C2.8335 2.5 3.5835 1.75 4.50016 1.75Z" stroke="#393D53" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>filter</span>
                    </a> }
                    <div className="filter_modal">
                    <div className='overlay'></div>
                    {props.heading=="Manage Stock Pumps" && <PumpFilterModal pumpTypeValues={pumpTypeValue} dutyPointValues={dutyPointValue} driverTypeValues={driverTypeValue} visibilityVals={visibilityVal}  closeModal={closeModal} clearFilterData={clear_filter}/>         }            
                    </div>
                </div>
              </div>
             
              {((pump_model !== '' && pump_model !== null) || statusvalue !== null) &&
                (props.heading=='My Enquiries') && <a href="javascript:;" className="link_text" onClick={clearFilter} >clear</a>}
              <div className="filter_block">
                <div className={`filter_box ${isFilterOpen ? 'filterOpen' : ''}`}  >
                    {(props.heading=='My Enquiries') && <a href="javascript:;" className="filter_link" onClick={handleToggle}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.50016 1.75H15.5002C16.4168 1.75 17.1668 2.5 17.1668 3.41667V5.25C17.1668 5.91667 16.7502 6.75 16.3335 7.16667L12.7502 10.3333C12.2502 10.75 11.9168 11.5833 11.9168 12.25V15.8333C11.9168 16.3333 11.5835 17 11.1668 17.25L10.0002 18C8.91683 18.6667 7.41683 17.9167 7.41683 16.5833V12.1667C7.41683 11.5833 7.0835 10.8333 6.75016 10.4167L3.5835 7.08333C3.16683 6.66667 2.8335 5.91667 2.8335 5.41667V3.5C2.8335 2.5 3.5835 1.75 4.50016 1.75Z" stroke="#393D53" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>filter</span>
                    </a> }
                    <div className="filter_modal">
                    <div className='overlay'></div>
                    {props.heading==="My Enquiries" && <EnquiryFilterModal enquiryNum={enquiryNum} statusValue={statusValue}  closeModal={closeModal} clearFilterData={clear_filter}/>         }            
                    </div>
                </div>
              </div>
              {(user_name !== null || user_email !== null || user_role !== null || company_name !==null || statusvalue !== null) &&
                (props.heading==='Users' || props.heading==='Customers') && <a href="javascript:;" className="link_text" onClick={clearFilter} >clear</a>}
              <div className="filter_block">
                <div className={`filter_box ${isFilterOpen ? 'filterOpen' : ''}`}  >
                    {(props.heading==='Users' || props.heading==='Customers') && <a href="javascript:;" className="filter_link" onClick={handleToggle}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.50016 1.75H15.5002C16.4168 1.75 17.1668 2.5 17.1668 3.41667V5.25C17.1668 5.91667 16.7502 6.75 16.3335 7.16667L12.7502 10.3333C12.2502 10.75 11.9168 11.5833 11.9168 12.25V15.8333C11.9168 16.3333 11.5835 17 11.1668 17.25L10.0002 18C8.91683 18.6667 7.41683 17.9167 7.41683 16.5833V12.1667C7.41683 11.5833 7.0835 10.8333 6.75016 10.4167L3.5835 7.08333C3.16683 6.66667 2.8335 5.91667 2.8335 5.41667V3.5C2.8335 2.5 3.5835 1.75 4.50016 1.75Z" stroke="#393D53" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>filter</span>
                    </a> }
                    <div className="filter_modal">
                    <div className='overlay'></div>
                    {props.heading==="Users" && <UserFilterModal setUserName={UserName} setUserRole={UserRole} setUserEmail={UserEmail} closeModal={closeModal} clearFilterData={clear_filter} FromPage={props.heading} /> }         
                    {props.heading==="Customers" && <UserFilterModal statusValue={statusValue} setUserName={UserName}  setUserEmail={UserEmail}  setCompanyName={CompanyName} closeModal={closeModal} clearFilterData={clear_filter} FromPage={props.heading}/>         }            
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      </>
    );
  };
  export default EnquiriesHead;