import React from "react";
import backBtn from '../assets/images/arrow-left_1.svg';
import { Link } from 'react-router-dom';
import customFetch from "../utils/axios";
import  { useState } from "react";
import { useNavigate} from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const BreadcrumbAndHeading = (props) => {
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [isLogout, setIsLogout] = useState(false);
  const [show, setShow] = useState(false);
  const [warningShow, setWarningShow] = useState(false);

  const navigate = useNavigate();

  function getCapital(str) {
    return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
      return $1.toUpperCase();
    });
  }

  let returnURL = (props.url) ? props.url : '#'

  let checked   = (props.isVisible) ? 'checked'  : '';

  if(props.enquiryType === 'cart') {
    sessionStorage.setItem('back_stock_enquiry',1);
  }
  const handleClose = () => {
    setShow(false);
    setWarningShow(false);
  }
  const deleteStockPump = async ()=>{
      try{
        setShow(true);
        setApiSuccessMessage('');
        const response = await customFetch.get('delete-stock-pump/'+props.pumpID);
        if(response.data.status === true){
          setApiSuccessMessage(response.data.message);
          sessionStorage.setItem('success_msg',response.data.message);
          navigate('/stock-pump-master');  
        }else if(response.data.message === 'cart dependency'){
          setWarningShow(true);
        }
        setShow(false);
      }catch(err){
        if (err.response.status === 401) {
          setIsLogout(true)
        }
        window.scrollTo(0,0);
      }      
  }
    return(
        <>  
            <div className="details_head_block">
              <div className="breadcrumb_and_heading">
                <div className="breadcrumb">
                  <ul>
                    <li>
                      <Link to={returnURL} className="crumb_link">{props.preUrl}</Link>
                    </li>
                    <li>
                      <a href="#" className="crumb_link">{getCapital(props.name)}</a>
                    </li>
                  </ul>
                </div>
                <div className="details_heading_block">
                  <Link to={returnURL} className="back_svg">
                    <img src={backBtn} alt="back btn" />
                  </Link>
                  <h1 className="heading_details">{getCapital(props.name)}</h1>
                </div>
              </div>

              {/* order track details start */}
              <div className="upload_offer_btn">
                <button className={`transparent_btn d-none  ${props.classShows}`}>
                    <span>Upload Offer Document</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                </button>
                {/* block after upload pdf */}
                <div className="uploaded_pdf d-none">
                    <div className="pdf_left">
                      <div className="pdf_svg">
                          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M33.3337 37.5H6.66699V2.5H25.0003L33.3337 10.8333V37.5Z" fill="#ED1D24"/>
                              <path d="M32.0837 11.6667H24.167V3.75L32.0837 11.6667Z" fill="#EFC8B8"/>
                              <path d="M13.3334 32.5C13.0001 32.5 12.7501 32.4167 12.5001 32.3333C11.5834 31.8333 11.5001 31.0833 11.6667 30.5C12.0001 29.5 13.8334 28.25 16.2501 27.1667C17.3334 25.1667 18.1667 23.0833 18.6667 21.3333C17.8334 19.75 17.4167 18.25 17.4167 17.1667C17.4167 16.5833 17.5834 16.0833 17.8334 15.6667C18.1667 15.25 18.6667 15 19.3334 15C20.0834 15 20.6667 15.4167 20.9167 16.1667C21.3334 17.1667 21.0834 19 20.5001 21.0833C21.3334 22.5 22.3334 23.8333 23.4167 24.8333C25.0001 24.5 26.4167 24.3333 27.3334 24.5C28.9167 24.75 29.1667 25.8333 29.1667 26.25C29.1667 28 27.3334 28 26.6667 28C25.4167 28 24.1667 27.5 23.0834 26.5833C21.0834 27.0833 19.0834 27.75 17.5001 28.5C16.6667 29.9167 15.8334 31.0833 15.0834 31.75C14.3334 32.3333 13.7501 32.5 13.3334 32.5ZM14.3334 30.0833C13.9167 30.3333 13.5834 30.5833 13.4167 30.8333C13.5834 30.75 13.9167 30.5833 14.3334 30.0833ZM25.6667 26.1667C26.0001 26.25 26.3334 26.3333 26.6667 26.3333C27.1667 26.3333 27.4167 26.25 27.5001 26.25C27.4167 26.1667 26.8334 26 25.6667 26.1667ZM19.8334 23.1667C19.5001 24.1667 19.0001 25.25 18.5834 26.25C19.5834 25.9167 20.5834 25.5833 21.5834 25.3333C20.9167 24.6667 20.3334 23.9167 19.8334 23.1667ZM19.3334 16.6667C19.2501 16.6667 19.2501 16.6667 19.2501 16.6667C19.1667 16.75 19.0834 17.3333 19.4167 18.5833C19.5001 17.5833 19.5001 16.8333 19.3334 16.6667Z" fill="white"/>
                          </svg>
                      </div>
                      <div className="pdf_details">
                        <p>Offer Document</p>
                        <a href="#">offer-doc-oct28.pdf</a>
                      </div>
                    </div>
                    <div className="pdf_right_icons">
                      <div className="pdf_view">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                           <path d="M11.9998 20.2702C15.5298 20.2702 18.8198 18.1902 21.1098 14.5902C22.0098 13.1802 22.0098 10.8102 21.1098 9.40021C18.8198 5.80021 15.5298 3.72021 11.9998 3.72021C8.46984 3.72021 5.17984 5.80021 2.88984 9.40021C1.98984 10.8102 1.98984 13.1802 2.88984 14.5902C5.17984 18.1902 8.46984 20.2702 11.9998 20.2702Z" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <div className="pdf_delete">
                       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.8504 9.14014L18.2004 19.2101C18.0904 20.7801 18.0004 22.0001 15.2104 22.0001H8.79039C6.00039 22.0001 5.91039 20.7801 5.80039 19.2101L5.15039 9.14014" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.3301 16.5H13.6601" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.5 12.5H14.5" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    </div>
                </div>
              </div>
              {/* order track details end */}


              <div className={`modification_box d-none ${props.classSHows}`}>
                <div className="visible_toggle">
                  <span>Visibility</span>
                  <div className="toggle_checkbox">
                    <input type="checkbox" id="check-2" disabled checked={checked}  />
                    <label htmlFor="check-2"></label>
                  </div>
                </div>
                <div className="edit_deleted_box">
                    <div className="edit_box">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.0575 14.1665L2.5 17.4998L5.83333 16.9423L3.0575 14.1665Z" fill="#ED1D24"/>
                        <path d="M18.0895 4.06567L15.9345 1.91067C15.772 1.74817 15.5587 1.6665 15.3453 1.6665C15.132 1.6665 14.9187 1.74817 14.7562 1.91067L4.16699 12.4998C4.16699 12.4998 5.00449 12.504 5.41699 12.9165C5.82949 13.329 5.82033 14.1532 5.82033 14.1532C5.82033 14.1532 6.66949 14.169 7.08366 14.5832C7.49783 14.9973 7.50033 15.8332 7.50033 15.8332L18.0895 5.244C18.4153 4.91817 18.4153 4.39067 18.0895 4.06567ZM7.23866 12.7565C7.15699 12.5415 7.04949 12.324 6.90783 12.1157L13.2928 5.73067L14.2695 6.70734L7.88449 13.0923C7.67533 12.949 7.45533 12.8398 7.23866 12.7565ZM15.4478 5.529L14.4712 4.55234L15.3453 3.67817L16.322 4.65484L15.4478 5.529Z" fill="#ED1D24"/>
                      </svg>
                      <Link to={'/edit-stock-pump/'+props.pumpID}>Edit</Link>
                      
                    </div>
                    <div className="delete_box">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5 3.3335H2.5" stroke="#ED1D24" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M4.16699 3.3335L5.73699 16.7768C5.79199 17.191 6.14533 17.5002 6.56283 17.5002H13.437C13.8545 17.5002 14.2078 17.191 14.2628 16.7768L15.8337 3.3335" stroke="#ED1D24" strokeWidth="2" strokeMiterlimit="10"/>
                        <path d="M11.4596 2.1665L12 2.70694V2.83317H8V2.70694L8.54044 2.1665H11.4596Z" fill="white" stroke="#ED1D24"/>
                      </svg>
                      <Link  onClick={(e) => {setShow(true)}}>Delete</Link>
                      {/* <a onClick={deleteStockPump}>Delete</a> */}
                    </div>
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose} centered className="delete_popup_modal" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Delete Stock Pump</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="confirm_msg">Are you sure you want to delete this record?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="outline_btn" onClick={deleteStockPump}>
                        Yes, Delete
                        </Button>
                        <Button variant="primary" className="darkred_btn" onClick={handleClose}>
                        No
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={warningShow} onHide={handleClose} centered className="delete_popup_modal" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Warning: Pump Cannot Be Deleted</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="confirm_msg">The pump you are trying to delete has either been added to a user's cart or has pending enquiries. Removing this pump will affect their current orders.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="outline_btn" onClick={handleClose}>
                        Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
        </>
    );
}
export default BreadcrumbAndHeading