import { useState } from 'react';

const ToastMessage = (props) => {
    const [showPanel, setShowPanel] = useState(props.showHidePanel);
    
    const hidePanel = () => {
      if(props.toastMessageCallBack)
      {
        props.toastMessageCallBack();
      }
        
      setShowPanel(false)
    }
    setTimeout(() => {
      setShowPanel(false);
    }, 10000);
    return(
      <>
      {
      (showPanel) && (<div className={`result_found_wrapper ${(props.isError === 1) ? (props.multipleToastMessage) ? 'error-toaster ToastMsgAdd' : 'error-toaster' : (props.multipleToastMessage) ? 'ToastMsgAdd' : ''}`}>
        <div className='container result_found'>
          <p className='result_found_text'>{props.message}</p>
          <button type="button" onClick={hidePanel}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.16699 15.8333L15.8337 4.16663" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.8337 15.8333L4.16699 4.16663" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>)
      }
      </>
    );
  };
  export default ToastMessage;