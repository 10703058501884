import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSearchCriteriaThunk, postEnquiryThunk, getSubmittalThunk } from './pumpSelectionThunk';

const initialState = {
    isLoading: false,
    isRedirectfromListing: false,
    selected_country:'',
    selected_country_text:'',
    pump_selection: [],
    selection_criteria: [],
    status_api: false,
    search_result_api: [],
    message_api: '',
    list_page_number:'',
    flow_api_data:[],
    head_api_data:[],
    certification_api_data:[],
    driver_type_api_data:[],
    country_api_data:[],
    search_result_data_selected:{},
    is_row_selected: false,
    submittal_file:'',
    submittal_status_api: false,
    records_not_found:false,
};

export const searchCriteria = createAsyncThunk('selectPump/searchCriteria', createSearchCriteriaThunk);

export const postEnquiry = createAsyncThunk('selectPump/postEnquiry', postEnquiryThunk);

export const getSubmittal = createAsyncThunk('selectPump/getSubmittal', getSubmittalThunk);

const pumpSelectionSlice = createSlice({
  name: 'pumpSelection',
  initialState,
  reducers: {
    clearValues: (state) => initialState,
    setPumpSearchValues: (state, { payload }) => {
        //alert(JSON.stringify(payload.criteria))
        //alert(JSON.stringify(payload.select_pump))
      //return { ...state, ...payload };
      state.selection_criteria = payload.criteria;
      state.pump_selection = [payload.select_pump];
      
      state.selected_country = payload.selected_country;
      state.selected_country_text = payload.selected_country_text;
      state.flow_api_data = payload.flow_api_data;
      state.head_api_data = payload.head_api_data;
      state.certification_api_data = payload.certification_api_data;
      state.driver_type_api_data = payload.driver_type_api_data;
      state.country_api_data = payload.country_api_data;

      state.list_page_number = 'first';
      //alert(JSON.stringify(state.pump_selection))
    },
    setListPageNumber: (state, { payload }) => {
      state.list_page_number = payload;
      state.is_row_selected = false;
    },
    updateRedirectStatus: (state, { payload }) => {
      state.isRedirectfromListing = payload;
      state.search_result_data_selected = {}
      state.is_row_selected = false;
    },
    updateAPIStatus: (state) => {
      state.status_api = false;
      state.message_api = '';
    },
    updateSearchResultSelectedData: (state, { payload }) => {
      state.is_row_selected = true;
      if(state.list_page_number === 'first') {
        state.search_result_data_selected = { ...state.search_result_data_selected, 'first' : payload.data}
      } else {
        state.search_result_data_selected = { ...state.search_result_data_selected, 'second' : payload.data}
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchCriteria.pending, (state) => {
        state.isLoading = true;
        state.status_api = false;
        state.submittal_status_api = false;
        state.message_api = '';
        state.records_not_found = false;
      })
      .addCase(searchCriteria.fulfilled, (state, { payload }) => {
        if(!payload.hasOwnProperty('data')) {
          state.status_api = false;
          state.search_result_api = [];

          state.records_not_found = true;
          // state.message_api = 'No records found. Please try with different parameters.';
        } else {
          const {data, success, message} = payload;
          state.status_api = success;

          if(success) {

            let isDataExists = 0;
            if(data.length === 1) {
              if(data[0].length > 0) {
                isDataExists = 1;
              }
            } else if(data.length === 2) {
              if(data[0].length > 0) {
                isDataExists = 1;
              }
              if(data[1].length > 0) {
                isDataExists = 1;
              }
            }
            if(isDataExists === 1) {
              state.search_result_api = data;
              state.message_api = '';
            } else {
              state.status_api = false;
              state.search_result_api = [];
              // state.message_api = 'No records found. Please try with different parameters.';
              state.message_api = '';
              state.records_not_found = true;
            }
          } else {
            state.message_api = message;
          }
        }
        state.isLoading = false;
      })
      .addCase(searchCriteria.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getSubmittal.pending, (state) => {
        state.isLoading = true;
        state.status_api = false;
        state.submittal_status_api = false;
        state.message_api = '';
        state.submittal_file = '';
      })
      .addCase(getSubmittal.fulfilled, (state, { payload }) => {
        
        if(!payload.hasOwnProperty('data')) {
          state.submittal_status_api = false;
          state.message_api = 'No records found for PDF file.';
          state.records_not_found = false;
        } else {
          const {data, success, message} = payload;
          state.submittal_status_api = success;

          if(success) {
            if(data) {
              state.submittal_file = data;
            } else {
              if(data.length === 1) {
                if(data[0].length > 0) {
                  state.message_api = 'No records found for PDF file.';
                }
              }
              state.submittal_status_api = false;
              state.submittal_file = '';
            }
          } else {
            state.message_api = message;
          }
        }
        state.isLoading = false;
      })
      .addCase(getSubmittal.rejected, (state, { payload }) => {
        state.submittal_status_api = false;
        state.message_api = 'Something went wrong. Please try after sometime.';
        state.isLoading = false;
      })
      .addCase(postEnquiry.pending, (state) => {
        state.submittal_status_api = false;
        state.isLoading = true;
        state.status_api = false;
        state.message_api = '';
      })
      .addCase(postEnquiry.fulfilled, (state, { payload }) => {
        const { error, status, message } = payload;
        //alert(user)
        state.isLoading = false;
        if(status) {
          state.status_api = true;
          state.records_not_found = false;
        } else {
          state.status_api = false;
          state.message_api = message;
          if(error.length > 0) {
            state.message_api = error[0];
          }
        }
      })
      .addCase(postEnquiry.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status_api = false;
        state.message_api = 'Something went wrong.';
      })
  },
});

export const { setPumpSearchValues, setListPageNumber, updateRedirectStatus, clearValues, updateAPIStatus, updateSearchResultSelectedData } = pumpSelectionSlice.actions;

export default pumpSelectionSlice.reducer;