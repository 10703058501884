import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { jwtDecode } from "jwt-decode";
import { HandleUnauthorization } from '../utils/HandleUnauthorization';

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((store) => store.user);
  const location = useLocation();
  
  if(user !== null) {
    let token = user.access_token;
    let decoded = jwtDecode(token);
    let currentDate = new Date().getTime();
    
    let expiryToken = decoded.exp;
    //let expiryToken = 1717468744;

    if(expiryToken * 1000 < currentDate) {
     return <HandleUnauthorization />
      
    }
  }

  if (!user) {
    return <Navigate to='/login' />;
  } else {
    if (user.role === 'customer') {
      return children;
    } else if (user.role === 'sales') {
      if(location.pathname === '/work-in-progress')
        return children;
      else
        return <Navigate to='/work-in-progress' />;
    }
  }
  return children;
};
export default ProtectedRoute;
