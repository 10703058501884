import { useState } from "react";
import React from "react";
import { RadioButton } from 'primereact/radiobutton';

const PumpListAccordionHead = ({ selectedValue, onChange, handleGraphModal, rowData }) => (
    <div className='main_toggle_mobile'>
      <div className='radio_and_text'>
      <RadioButton name="selectModelPumpRow"  value={rowData.id} onChange={onChange} checked={selectedValue === rowData.id} />
      
      <h4>{rowData.pumpModel}
        <a href="javascript:;" className='show_graph_mobile' onClick={()=>{handleGraphModal(rowData)}}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.7334 15.125V13.4" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M10 15.125V11.675" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14.2666 15.125V9.94165" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14.2667 4.875L13.8834 5.325C11.7584 7.80833 8.9084 9.56667 5.7334 10.3583" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M11.8252 4.875H14.2669V7.30833" stroke="#808285" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.50033 18.3333H12.5003C16.667 18.3333 18.3337 16.6666 18.3337 12.5V7.49996C18.3337 3.33329 16.667 1.66663 12.5003 1.66663H7.50033C3.33366 1.66663 1.66699 3.33329 1.66699 7.49996V12.5C1.66699 16.6666 3.33366 18.3333 7.50033 18.3333Z" stroke="#808285" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </h4>
      </div>
      <div className='stage_details'>
          <span className='stage_content'>{rowData.stage}</span>
      </div>
    </div>
);

export default PumpListAccordionHead;