import React,  { useState, useEffect } from 'react';
import {BreadcrumbAndHeading, OverViewPump, TechnicalData} from '../../../components';
import { TabView, TabPanel } from 'primereact/tabview';
import {useParams} from 'react-router-dom';
import customFetch from "../../../utils/axios";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { useLocation } from "react-router-dom";

const ViewPumpResults = () => {

    // const { string,frompage,showsubmital } = useParams();

    const [pumpModel, setPumpModel] = useState('');
    const [pumpRecord, setPumpRecord] = useState([]);
    const [isVisible, setIsVisible] = useState('');
    const [url, setUrl] = useState('/stock-availability');
    const [technicalFileStatus, setTechnicalFileStatus] = useState(0);
    const [submitalFileStatus, setSubmitalFileStatus] = useState(0);  
    
    const [technicalFileURL, setTechnicalFileURL] = useState('');
    const [submitalFileURL, setSubmitalFileURL] = useState('');
    const secretKey = 'NAFFCO2023@GALAXY';
    const search = useLocation().search;
    const pumpdetails = new URLSearchParams(search).get('r');
    const [frompage, setFrompage] = useState('');
    const [showsubmital, setShowsubmital] = useState('');
    const [pumpid, setPumpId] = useState(null);
    const [location, setLocation] = useState('');

    useEffect(() => {
      pumpDetails()
      
    },[]);

    useEffect(()=>{
      if(frompage === 'myorder'){
        setUrl('/my-order');      
      }else{
        if(window.innerWidth <= 768){
          setUrl('/stock-availability-mob?n='+'from_compare');         
        }else{
            setUrl('/stock-availability?n='+'from_compare');
        }
      }
     
  });
    const pumpDetails = async () => {

      // Create FormData object
      const formData = new FormData();
      let pumpdata = AES.decrypt(pumpdetails, secretKey);
      pumpdata = JSON.parse(pumpdata.toString(Utf8));
      setFrompage(pumpdata.frompage);
      setShowsubmital(pumpdata.showsubmital);
      setPumpId(pumpdata.id);
      setLocation(pumpdata.location);
      formData.append('pump_id', pumpdata.id);
      
      try {
          const response = await customFetch.post('/pump-details',formData);
          const { status } = response.data;
          if (status === true) {
              
              let result = response.data.data;
              setPumpRecord(result);
              setPumpModel(result.model);
              setIsVisible(result.is_visible)

              // showStockAvailability(result.code)
              
              setTechnicalFileStatus(response.data.techinal_data_sheet_status);
              setSubmitalFileStatus(response.data.submital_data_sheet_status);

              setTechnicalFileURL(response.data.techinal_data_sheet_url)
              setSubmitalFileURL(response.data.submital_data_sheet_url)
          } 
      } catch (err) {
          // if (err.response.status === 401) {
          //   setIsLogout(true)
          // }
          
         // setErrorMessage('There is something wrong. Please try again');
          //window.scrollTo(0,0);
      }
      
    };


    return (
      <>
        <div className='stock_pump_master'>
          <div className='container'>
            <BreadcrumbAndHeading name={pumpModel} preUrl="Stock Booking"  pumpID={pumpid} isVisible={isVisible} url={url}  />
            <div className="pumps_tabs">
                <TabView>
                    <TabPanel header="Overview">
                      <OverViewPump pumpRecord={pumpRecord} location={location} />
                    </TabPanel>
                    <TabPanel header="Technical Data Sheet">
                      <TechnicalData pdfImg={technicalFileURL} fileStatus={technicalFileStatus} title="Technical Data Sheet" />
                    </TabPanel>
                    {frompage === 'myorder' && showsubmital === 1 && <TabPanel header="Technical Submittals">
                   <TechnicalData pdfImg={submitalFileURL} fileStatus={submitalFileStatus} title="Technical Submittals"/>
                    </TabPanel>}
                </TabView>
            </div>            
          </div>
        </div>
      </>
    )
  }
  
export default ViewPumpResults