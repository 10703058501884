import ReactDOM from "react-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import customFetch from "../utils/axios";
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';

export default function ManageImportedStockDataTable({addOrEditMode, dataList, callBackList, changePage, pagelimit}) {
  let emptyProduct = {
    id: null,
    name: "",
    status: null,
    companyid: "",
    email: null,
    contact: 0,
    action: 0,
  };

  /**hooks */
  const toast = useRef(null);
  const dt = useRef(null);

  /**states */
  const [products, setProducts] = useState(dataList.data);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);  
  const [first, setFirst] = useState(0);

    const model = (Data) =>{
      return (
        <div className="newUser" style={{ textTransform: 'capitalize'}}>{Data.model}</div> 
      );
    } 
    const stockavail = (Data) =>{
     // console.log(Data.stockavail)

      let qty_avl = 0;
      Data.stockavail.forEach(function(item, index) {
          qty_avl = parseInt(qty_avl) + parseInt(item.qty_avl);
      });

      return (
        <div >{qty_avl}</div>
      );
    } 
    const dutyPoint = (Data) =>{
      return (
        <div style={{ textTransform: 'capitalize'}}>{Data.flow} {Data.flow_uom} @ {Data.pressure} {Data.pressure_uom}</div>
      );
    } 
    const statusUpdate = (rowData) => {
      const dotColor = rowData.status === 'active' ? '#0BDA6C' : '#F57E20';

      return (
        <div className="status_shows">
          <svg width="8" height="8" style={{ marginRight: '6px' }}>
            <circle cx="4" cy="4" r="4" fill={dotColor} />
          </svg>
          <div  style={{ textTransform: 'capitalize'}}> {rowData.status}</div>
        </div>
      );
    };

    const statusBodyTemplate = (item) => {
      
      if(item.error_status == 1) {
        return <Tag value='Error' className="p_3" severity="danger" placeholder="Mouse" ></Tag>;
      } else {
        return <Tag value='Success' className="p_3" severity="success" placeholder="Mouse" ></Tag>;  
      }
    
    };

    const onPageChange = (event) => {
      setFirst(event.first);
      let page = event.page + 1;
      changePage(page);
    };
    const handleTdClick = (rowDataMy) => {
      setSelectedItem(rowDataMy);
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownClick = (option) => {
      setIsDropdownOpen(false);
    };
    const items =(data) => ([
      {
        label: "View",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
        command: async (event) => {
          alert('View')
        },
      },
      {
        label: "Error Message",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
        command: async (event) => {
          alert(data.error_log);
        },
      },
      ,
    ]);
    const dotsShows = (rowDataMy) => 
    {
      return (
        <SplitButton
          label="Edit"
          icon="pi pi-pencil"
          model={items(rowDataMy)}
        />

      )
    }   
    const visibleBtn = (Data) => {
      if(Data.is_visible == 1){
        Data.is_visible = 'true';
      }else{
        Data.is_visible = 'false';
      }
      return (
        <div className="visible_toggle">
                  <div className="toggle_checkbox" >
                    <input type="checkbox" id="check-1"  />
                    <label htmlFor="check-2"></label>
                  </div>
            </div>
      );
    };

  return (
    <>
      
        <div className="custom_dataTable">
        
          <DataTable
            ref={dt}
            value={dataList.data}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            //paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {PageLinks} {NextPageLink} {LastPageLink} {CurrentPageReport}"
          >
            <Column field="model" header="model" body={model}  sortable></Column>
            <Column field="code" header="Code"   sortable></Column>
            <Column field="item_code" header="Item Code" sortable></Column>
            <Column field="is_visible" header="visibility" body={visibleBtn}  sortable></Column>
            <Column header="duty point" field="dutyPoint"  body={dutyPoint}  />
            <Column field="pump_type" header="type" sortable></Column> 
            
            <Column field="primemover_type" header="Driver Type" sortable></Column>
            <Column field="error_status" header="Status" body={statusBodyTemplate} sortable></Column>

            <Column field="error_log" header="Error Message" ></Column>

          </DataTable>      
        </div>
        {(dataList.total>0) && <div className="card">
          <Paginator first={first} rows={pagelimit} totalRecords={dataList.total}  onPageChange={onPageChange} />
        </div>
        }
    </>
  );
}