import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate} from 'react-router-dom';
import customFetch from "../utils/axios";
import { Paginator } from 'primereact/paginator';
import AES from 'crypto-js/aes';
import { HandleUnauthorization } from "../utils/HandleUnauthorization";
import moment from 'moment';

const EnquiriesStockPump = (props) => {

  const secretKey = 'NAFFCO2023@GALAXY';
  const navigate = useNavigate();
  /**hooks */
  const toast = useRef(null);
  const dt = useRef(null);

  /**states */
  const [errorMessage, setErrorMessage] = useState('');
  const [enquiryList, setEnquiryList] = useState([]);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [pagelimit, setpagelimit] = useState(10);
  const [dataList, setdataList] = useState(10);
  const [first, setFirst] = useState(0);
  const [pageNo, setpageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [location_value, setLocationValue] = useState(props.locationValue);
  const [status_value, setstatusValue] = useState(props.statusValue);

  //props

    const location = (locationData) => {
      if(locationData.user_location){
        return(
          <div className="location_td">
            {locationData.user_location}
          </div>
        );
      }else{
        return(
          <div className="location_td">
           {'N/A'}
          </div>
        );
      }
    };

    const formatdate = (data) => {

      if(data.enquiry_date != null){
        return(
          <div>
           {moment(data.enquiry_date ).format('MMM DD YYYY')}
          </div>
        );
      }else{
        return(
          <div>
           {'N/A'}
          </div>
        );
      }
    };
    function getCapital(str) {
      return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
      });
    }
    
    const enquiryByCall = (data) => {
      return(
        <div>
         {getCapital(data.name)}
        </div>
      );
    }
    const enquirySend = (data) => {
      if(data.enquiry_status_master_id === null){
        return(
          <div>
           No
          </div>
        );
      }else{
        return(
          <div>
           Yes
          </div>
        );
      }
    }
    
    useEffect(() => {
      fetchEnquiry();
  }, [pageNo,props.statusValue,props.locationValue,props.enquirySendValue,props.enquiryNumberValue]);

    const fetchEnquiry = async () => {
      setErrorMessage('');
      try {
        // const serachdata = 
          const response = await customFetch.get(`/pump-enquiry/`+pagelimit+`?page=`+pageNo,{
              params: {search_status: props.statusValue, search_location : props.locationValue,  search_enquiry_send: props.enquirySendValue, pump_code:props.pumpCode, search_enquiry_number: props.enquiryNumberValue }
          });
          setdataList(response.data.enquirylist);
          const enquirydata = response.data.enquirylist.data;
          const enquirylist = response.data.enquirylist.data;
          let enquiryFor = 'N/A';
          let enquiry_count = 0;
          let status = 'N/A';
          let enquiryToolTip = [];

          enquirylist.map((item,index) => {
            
            if(item.enquiry_details.length > 0){
              enquiryFor = item.enquiry_details[0].pump_model;
              
              
              enquiry_count = item.enquiry_details.length;
                item.enquiry_details.map((row,index)=>{
                  if(index>0){
                    enquiryToolTip.push(row.pump_model);
                  }
                })

              
            }
            if(item.status_master){
              status = item.status_master.name;
              enquirylist[index]['status'] = status;
            }
            enquirylist[index]['enquiryFor'] = enquiryFor;
            enquirylist[index]['enquiry_count'] = enquiry_count;
            enquirylist[index]['enquiryToolTip'] = enquiryToolTip;
            enquiryToolTip = [];
          })
          setEnquiryList(enquirylist);

      } catch (err) {
        if (err.response.status === 401) {
          setIsLogout(true)
        }
          // console.log(err);
          setErrorMessage('There is something wrong. Please try again');
          window.scrollTo(0,0);
      }
    };

    const onPageChange = (event) => {
      setFirst(event.first);
      let page = event.page + 1;
      setpageNo(page);
    };

    const enquiryFor = (usernameData) =>{
      if(usernameData.enquiryFor!=null){
        return (
          <div className="newUser" style={{ textTransform: 'capitalize'}}>{usernameData.enquiryFor}
            {(usernameData.enquiry_count>1) && <div className="show_enquiries_detail">{usernameData.enquiry_count - 1}+
              <ul className="tooltip_show">
                <li>
                  <span className="tooltip_show_data">
                  {usernameData.enquiryToolTip.map(recipe => {
                     return( <li>{recipe}</li>)
                    })
                  }
                  </span>
                </li>
              </ul>
            </div>}
          </div>
        );
      }
     
    } 
    const statusUpdate = (rowData) => {
      let  dotColor = rowData.status === 'contacted' ? '#3AA8E5' : '#FFC82C' || '#21BA31';
      if(rowData.status === 'enquiry_received'){
        dotColor = '#FFC82C' ;
      }  else if(rowData.status === 'contacted'){
        dotColor = '#3AA8E5' ;
      }else if(rowData.status === 'working_on_requirements'){
        dotColor = '#21BA31' ;
      }else if(rowData.status === 'qualified'){
        dotColor = '#21BA31' ;
      }else if(rowData.status === 'quotation_sent'){
        dotColor = '#5f5189' ;
      }else{
        dotColor = '#4e7926';
      }
      if(rowData.status != null){
        var i,frags = rowData.status.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
      let status = frags.join(' ');
    
      return (
        <div className="status_shows">
          <svg width="8" height="8" style={{ marginRight: '6px' }}>
            <circle cx="4" cy="4" r="4" fill={dotColor} />
          </svg>

          <div  style={{ textTransform: 'capitalize'}}> {status}</div>
        </div>
      );
      }else{
        return 'N/A';
      }
     
    };
    const redirectToDetail = (rowId) => {
     // alert(rowId)

      const rowIdEncryptedString = AES.encrypt(JSON.stringify(rowId.id), secretKey).toString();
      let finalUrl = 'r=' + encodeURIComponent(rowIdEncryptedString);
      //alert(finalUrl)
      navigate('/enquires-detail?' + finalUrl);

    }
    const eyesShows = (rowData) => (
      
      <a href="javascript:;" onClick={() => {redirectToDetail(rowData)}}>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12Z" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </a>
    ); 
    
    const showPhone = (data) => {

      if(data.contact_number != null){
        return(
          <div>
           {data.contact_number}
          </div>
        );
      }else{
        return(
          <div>
           {'N/A'}
          </div>
        );
      }
    };  
  /**events */

  return (
    <>
        <div className="custom_dataTable enquiries_table">
          <DataTable
            ref={dt}
            value={enquiryList}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
          >
            <Column field="enquiryFor" header="Enquiry for" body={enquiryFor}  sortable></Column>
            <Column field="enquiry_number" header="Enquiry Number" sortable></Column>
            {/* <Column field="name" header="enquiry by" body={enquiryByCall} sortable></Column> */}
            <Column field="contact_number" header="phone" body={showPhone} sortable></Column>
            <Column field="email" header="mail" sortable></Column>
            <Column headerClassName="location_cell_th"  body={location}  field="user_location" header="location" sortable></Column>
            <Column field="enquiry_date" header="date" body={formatdate} sortable></Column>
            <Column body={statusUpdate} header="Status" field="status" />
            <Column body={eyesShows} field="action" header="Action" sortable className="dots_col" style={{ textAlign: 'right', width: '50px', minWidth: '50px'}}></Column>
          </DataTable>  
        </div>
        {
        (dataList.total>0) && <div className="card">
        <Paginator first={first} rows={pagelimit} totalRecords={dataList.total}  onPageChange={onPageChange} />
      </div>
      }
      {
        (isLogout) && (<HandleUnauthorization />)
      }
    </>
  );
  }
  
export default EnquiriesStockPump;