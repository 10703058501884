import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cartThunk} from './cartThunk';

const initialState = {
    isReload: false,
    status_api: false,
    message_api: '',
    isLoading:false,
};

export const postEnquiry = createAsyncThunk('postEnquiry', cartThunk);

const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    clearValues: (state) => initialState,
    reloadData: (state) => {
      state.status_api = false;
      state.isReload = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEnquiry.pending, (state) => {
        state.isLoading = true;
        state.status_api = false;
        state.message_api = '';
      })
      .addCase(postEnquiry.fulfilled, (state, { payload }) => {
        const { error, status, message } = payload;
        state.isLoading = false;
        if(status) {
          state.status_api = true;
          state.message_api = 'Enquiry sent successfully';
        } else {
          state.status_api = false;
          state.message_api = message;
          if(error.length > 0) {
            state.message_api = error[0];
          }
        }
      })
      .addCase(postEnquiry.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status_api = false;
        state.message_api = 'Something went wrong.';
      })
  },
});

export const {  clearValues,reloadData} = cartSlice.actions;

export default cartSlice.reducer;