import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import customFetch from "../utils/axios";
import logo from '../assets/images/new_logo.png';
// import login_banner from '../assets/images/real-login-bg.jpg';
import login_banner from '../assets/images/LOGIN-WEB.jpg';
// import mobile_login_banner from '../assets/images/mobile_login_bg.jpg';
import mobile_login_banner from '../assets/images/LOGIN-MOBILE.jpg';
import closedEye from '../assets/images/closed-eye.svg';
import openEye from '../assets/images/Eye.svg';
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordUser, clearSuccessStatus } from '../features/user/userSlice';
import { FormRow, ModalComponent, ToastMessage } from '../components';

const initialState = {
    user_id:'',
    password: '',
    confirmPassword: ''
};

function ContentHtml() {
    return <p>Please click below button to login.</p>;
}
const modalComponentInitialState = {
    showModal: false,
    title: '',
    content: '',
    redirectLink: ''
};
const ResetPassword = () => {
    const [modalValues, setModalValues] = useState(modalComponentInitialState);
    
    const search = useLocation().search;
    const verificationCode = new URLSearchParams(search).get('c');
    const [isLinkExpired, setIsLinkExpired] = useState(false);

    const [passwordShowHide, setPasswordShowHide] = useState(false);
    const [confirmPasswordShowHide, setConfirmPasswordShowHide] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { isLoading, isSuccessAPI, apiErrorMessage } = useSelector((store) => store.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isSuccessAPI) {
            setModalValues({
                showModal: true,
                title: 'Password reset successfully',
                content: <ContentHtml />,
                redirectLink: '/login'
            })
            formik.resetForm();
        }
        if (!isSuccessAPI) {
            window.scrollTo(0, 0);
        }
      }, [isSuccessAPI]);

      useEffect(() => {
       
        if (apiErrorMessage) {
            window.scrollTo(0, 0);
        }
      }, [apiErrorMessage]);

    useEffect(() => {
        const verifyUser = async () => {
            setErrorMessage('');
            try {
                const response = await customFetch.post(`/verify-reset-password-link/${verificationCode}`);
                const { status, message, error, result } = response.data;
                let finalApiError = "";

                if (status === false) {
                    if (error.length > 0) {
                        error.map(
                            (errorMessageData) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessageData)
                        );
                    } else {
                        finalApiError = message;
                    }
                    setErrorMessage(finalApiError);
                    // alert(Result.is_expired)
                    if (result.is_expired) {
                        setIsLinkExpired(true);
                    }
                    else {
                        
                        setIsLinkExpired(false);
                    }
                    window.scrollTo(0, 0);
                } else {
                    formik.setFieldValue("user_id", result.user_id)
                }
            } catch (err) {
                // console.log(err);
                setErrorMessage('There is something wrong. Please try again');
                window.scrollTo(0, 0);
            }
        };

        verifyUser();
    }, []);

    const modalCallBack = () => {
        dispatch(clearSuccessStatus());
        setModalValues(modalComponentInitialState)
    }

    const formik = useFormik({
        initialValues: initialState,
        validate: (data) => {
            let errors = {};
            let isValid = true;
            
            if (data.password == null || data.password.trim() === '') {
                errors.password = 'Password is required.';
            } else {
                const isPassValid = validatePassword(data.password);
                if(isPassValid.length > 0) {
                    errors.password = 'Please enter valid password.';
                }
            }

            if (data.confirmPassword == null || data.confirmPassword.trim() === '') {
                errors.confirmPassword = 'Confirm password is required.';
            }
            if (data.password && data.confirmPassword) {
                if (data.password != data.confirmPassword) {
                    errors.confirmPassword = 'Password and confirm password must be same.';
                }
            }

            if (isValid) {
                return errors;
            } else {
                return errors;
            }

        },
        onSubmit: (data) => {
            handleSubmit(data);
        }
    });
    const validatePassword = (pass) => {
        let errors = [];
        if (pass.length < 8) {
            errors.push("Password must be at least 8 characters");
        }
        if (pass.search(/[0-9]/) < 0) {
            errors.push("Password must contain at least one digit.");
        }
    
        if (pass.search(/^\S*$/) < 0) {
          errors.push("Password must not contain Whitespaces.");
        }
    
        if (pass.search(/^(?=.*[A-Z]).*$/) < 0) {
          errors.push("Password must have at least one Uppercase Character.");
        }
    
        if (pass.search(/^(?=.*[a-z]).*$/) < 0) {
          errors.push("Password must have at least one Lowercase Character.");
        }
    
        if (pass.search(/^(?=.*[!@#$%^&*]).*$/) < 0) {
          errors.push("Password must contain at least one Special Symbol.");
        }
    
        return errors.join("\n")
      }
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSubmit = async (data) => {
        const { user_id, password, confirmPassword } = data;
        dispatch(resetPasswordUser({ user_id, password, password_confirmation:confirmPassword }));
    };

    const togglePassword = (mode) => {
        if (mode === 'password') {
            setPasswordShowHide(!passwordShowHide)
        } else {
            setConfirmPasswordShowHide(!confirmPasswordShowHide)
        }
    }
    const passwordCheckStrength = (e) => {
        let password = e.target.value;
        let strength = 0;
    
        let passwordStrength = document.getElementById("password-strength");
        
        //If password contains both lower and uppercase characters
        if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
          strength += 1;
          document.querySelector(".low-upper-case-right").style.display = 'flex';
          document.querySelector(".low-upper-case-dot").style.display = 'none';
        } else {
          document.querySelector(".low-upper-case-right").style.display = 'none';
          document.querySelector(".low-upper-case-dot").style.display = 'flex';
        }
        //If it has numbers and characters
        if (password.match(/([0-9])/)) {
          strength += 1;
          document.querySelector(".one-number-right").style.display = 'flex';
          document.querySelector(".one-number-dot").style.display = 'none';
        } else {
          document.querySelector(".one-number-right").style.display = 'none';
          document.querySelector(".one-number-dot").style.display = 'flex';
        }
        //If it has one special character
        if (password.match(/([!,%,&,@,#,$,^,*])/)) {
          strength += 1;
          document.querySelector(".one-special-char-right").style.display = 'flex';
          document.querySelector(".one-special-char-dot").style.display = 'none';
        } else {
          document.querySelector(".one-special-char-right").style.display = 'none';
          document.querySelector(".one-special-char-dot").style.display = 'flex';
        }
        //If password is greater than 7
        if (password.length > 7) {
          strength += 1;
          document.querySelector(".eight-character-right").style.display = 'flex';
          document.querySelector(".eight-character-dot").style.display = 'none';
        } else {
          document.querySelector(".eight-character-right").style.display = 'none';
          document.querySelector(".eight-character-dot").style.display = 'flex';
        }
    
        // If value is less than 2
        if (strength < 2) {
            passwordStrength.classList.remove('progress-bar-warning');
            passwordStrength.classList.remove('progress-bar-success');
            passwordStrength.classList.add('progress-bar-danger');
            passwordStrength.style = 'width: 10%';
        } else if (strength == 3) {
            passwordStrength.classList.remove('progress-bar-success');
            passwordStrength.classList.remove('progress-bar-danger');
            passwordStrength.classList.add('progress-bar-warning');
            passwordStrength.style = 'width: 60%';
        } else if (strength == 4) {
            passwordStrength.classList.remove('progress-bar-warning');
            passwordStrength.classList.remove('progress-bar-danger');
            passwordStrength.classList.add('progress-bar-success');
            passwordStrength.style = 'width: 100%';
        }
      }
    return (
        <>
      <div className='login_block'>
        <div className='row w-100 mx-auto'>
        <div className= {"col-md-7 px-0 bg_login " + (isLinkExpired ? 'h100vh' : 'h100vh_min')}>
          {/* <div className="col-md-7 px-0 bg_login h100vh_min"> */}
          <img src={login_banner} alt="logo" className='login_banner desktop_login_bg' />
          <img src={mobile_login_banner} alt="logo" className='login_banner mobile_login_bg' />
          </div>
          <div className='col-md-5 px-0 login_form_col'>
            <div className="form-inner-col">
              <div className='login_logo'>
              <NavLink to="/select-pump"><img src={logo} alt="logo" className='login_logo_img' /></NavLink>
                
              </div>
              <div className="form-wrapper verification-container">
                
                        {errorMessage && (
                             <div className="simple-text">
                                {errorMessage}
                            </div>
                        )}
                    {
                        (isLinkExpired) && (
                        <>

                            <p className="simple-text">Your link is expired. Please again make a request for reset
                                password.</p>
                            <br />
                            <div className="form-group">
                                <NavLink to="/forgot-password" className="btn darkred_btn">Forgot Password</NavLink>
                            </div>
                        </>
                )}
                {
                    (isLinkExpired === false) && (
              <form className='form' onSubmit={formik.handleSubmit}>
                <h1>Create New Password</h1>

                <p className="sub_name_para">Your new password must be different from previous password</p>
                
                {/* first name field */}
                {
                  (apiErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={apiErrorMessage} />)
                }
                <div className="fields-group">
                    <div className='password_field_box'>
                        <FormRow
                            type={!passwordShowHide ? 'password' : 'text'}
                            name='password'
                            value={formik.values.password}
                            handleChange={(e) => {formik.handleChange(e); passwordCheckStrength(e)}}
                            maxlengthValue={30}
                        />
                        <span className='eye_close_box'>
                            <img src={!passwordShowHide ? closedEye : openEye} alt={!passwordShowHide ? closedEye : openEye} onClick={() => {togglePassword('password')}} className='closed_eye_img' />
                        </span>
                    </div>
                {getFormErrorMessage('password')}
                </div>
                <div className="fields-group">
                <div className='password_field_box'>
                    <FormRow
                        type={!confirmPasswordShowHide ? 'password' : 'text'}
                        labelText='confirm password'
                        name='confirmPassword'
                        value={formik.values.confirmPassword}
                        handleChange={formik.handleChange}
                        maxlengthValue={30}
                    />
                    <span className='eye_close_box'>
                        <img src={!confirmPasswordShowHide ? closedEye : openEye} alt={!confirmPasswordShowHide ? closedEye : openEye} onClick={() => {togglePassword('confirm_password')}} className='closed_eye_img' />
                    </span>
                </div>
                {getFormErrorMessage('confirmPassword')}
                </div>
                <div id="popover-password">
                    <div className='form-row'>
                      <label>Strength</label>
                      <div className="progress">
                          <div id="password-strength" 
                              className="progress-bar" 
                              role="progressbar" 
                              aria-valuenow="40" 
                              aria-valuemin="0" 
                              aria-valuemax="100" 
                              >
                          </div>
                      </div>
                    </div>
                    <ul className="list-unstyled">
                        <li>
                            <div className="low-upper-case password_details">
                                <div className='status_svg'>
                                  <div className='green_right_svg low-upper-case-right' >
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="12" height="12" fill="white"/>
                                      <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  </div>
                                  <div className='grey_dot_svg low-upper-case-dot'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="2" fill="#808285"/>
                                      </svg>
                                  </div>
                                </div>
                                <span>
                                    &nbsp;Lowercase &amp; Uppercase
                                </span>
                            </div>
                        </li>
                        <li className="">
                            <div className="one-number password_details">
                            <div className='status_svg'>
                                  <div className='green_right_svg one-number-right'>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="12" height="12" fill="white"/>
                                      <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  </div>
                                  <div className='grey_dot_svg one-number-dot'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="2" fill="#808285"/>
                                      </svg>
                                  </div>
                                </div>
                                <span>&nbsp;Number (0-9)</span>
                            </div> 
                        </li>
                        <li className="">
                            <div className="one-special-char password_details">
                            <div className='status_svg'>
                                  <div className='green_right_svg one-special-char-right'>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="12" height="12" fill="white"/>
                                      <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  </div>
                                  <div className='grey_dot_svg one-special-char-dot'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="2" fill="#808285"/>
                                      </svg>
                                  </div>
                                </div>
                                <span>
                                &nbsp;Special Characters (!@#$%^&*)</span>
                            </div>
                        </li>
                        <li className="">
                            <div className="eight-character password_details">
                            <div className='status_svg'>
                                  <div className='green_right_svg eight-character-right'>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect width="12" height="12" fill="white"/>
                                      <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                  </div>
                                  <div className='grey_dot_svg eight-character-dot'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="6" cy="6" r="2" fill="#808285"/>
                                      </svg>
                                  </div>
                                </div>
                                <span>&nbsp;Atleast 8 Characters</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <button type='submit' className='login_btn darkred_btn' disabled={isLoading}>
                  <span>{isLoading ? 'loading...' : 'Reset Password'}</span>
                  {(isLoading === false) && (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>)}
                </button>
                  
              </form>)
                }
                </div>
            </div>
          </div>
        </div>
      </div>
      {modalValues.showModal && (
        <ModalComponent
            showModal={modalValues.showModal}
            title={modalValues.title}
            content={modalValues.content}
            redirectLink={modalValues.redirectLink}
            modalCallBack={modalCallBack} 
        />
      )}
    </>
    )
}
export default ResetPassword;