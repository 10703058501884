import React, { useState, useEffect, useRef }  from 'react';
// import {DataTableField} from '../../../components';
import {EnquiriesHead, ManageImportedStockDataTable} from '../../../components';
import customFetch from "../../../utils/axios";
import AddNewPump from '../stockPump/AddNewPump';
import closeSvg from '../../../assets/images/Close.svg';
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";
import ToastMessage from '../../../components/ToastMessage';
import { useNavigate} from 'react-router-dom';
import PopupModalTransferAvil from '../../../components/PopupModalTransferAvil';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Paginator } from 'primereact/paginator';

const ImportedStockPump = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [stocklist, setStockList] = useState([]);
  const [show, setShow] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [pagelimit, setpagelimit] = useState(10);
  const [pageNo, setpageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);

  const [totalRecord, setTotalRecord] = useState(0);
  const [totalImportedRecord, setTotalImportedRecord] = useState(0);

  const [first, setFirst] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    fetchStock();
  }, [pageNo]);

  const navigate = useNavigate();

  const changePage = (page=1)=>{
    setpageNo(page);
  }
    const fetchStock = async () => {
      setErrorMessage('');
      try {
          const response = await customFetch.get(`/imported-stock-availability-list/`+pagelimit+`?page=`+pageNo);
          const stocklist = response.data.stocklist;
          console.log(response);
          setStockList(stocklist);
          setTotalRecord(response.data.totalRecord);
          setTotalImportedRecord(response.data.totalImportedRecord);

      } catch (err) {
          // console.log(err);
          if (err.response.status === 401) {
            setIsLogout(true)
          }
          setErrorMessage('There is something wrong. Please try again');
          window.scrollTo(0,0);
      }
    };
    const callBackList = () => {
      fetchStock();
    }
    const handleShow=()=>{
      setApiSuccessMessage('')
      setShow(true);
    }

    const initialState = {
      first_name: '',
      last_name: '',
      email: '',
      role: null,
      status: '',
      company_id: '',
      id:null,
    };

    const onCloseCallBack = (message=null) => {
      setApiSuccessMessage('')
      if(message) {
        setApiSuccessMessage(message)

        callBackList();
      }
      setShow(false)
      setIsTransfer(false)
    }
    const handleTranser = async () => {
      setIsTransfer(true)
    }

    const statusBodyTemplate = (item) => {
      
        if(item.error_status == 1) {
          return <Tag value='Error' className="p_3" severity="danger" placeholder="Mouse" ></Tag>;
        } else {
          return <Tag value='Success' className="p_3" severity="success" placeholder="Mouse" ></Tag>;  
        }
      
    };

    const onPageChange = (event) => {
      setFirst(event.first);
      let page = event.page + 1;
      changePage(page);
    };

    const showCode = (item) => {
      
      return ( <div className='newUser'>{item.code}</div> )
    
    };

    return (
      <>
        <div className='container'>
            <div className='stock_pump_master'>
              <div className="table-header table_heading_head">
                <div className="table_heading_content">
                    <h1 className="p-m-0">Preview Stock Pumps</h1>
                    <h3 className='badge bg-success'>Successfully Imported Records {totalImportedRecord} out of {totalRecord}.</h3>
                    <div className="conditional_block_show">
                        <button className='darkred_btn' onClick={handleTranser} >
                            <span>Transfer</span>
                        </button>
                    </div>
                  </div>
                </div>
                
                {
                  (isTransfer) && (<PopupModalTransferAvil  onCloseCallBack={onCloseCallBack} />)
                }
                
                <div className='stock_pump_master_table'>
                    <div className="custom_dataTable">
                        <DataTable
                            ref={dt}
                            value={stocklist.data}
                           // selection={selectedProducts}
                            //onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id"
                            //paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {PageLinks} {NextPageLink} {LastPageLink} {CurrentPageReport}"
                        >
                            <Column field="code" header="Code" body={showCode} sortable></Column>
                            <Column field="location" header="location" sortable></Column>
                            <Column field="qty_avl" header="available quantity" sortable></Column> 
                            <Column field="error_status" header="Status" body={statusBodyTemplate} sortable></Column>
                            <Column field="error_log" header="Error Message" ></Column>

                        </DataTable>      
                    </div>
                    {(stocklist.total>0) && <div className="card">
                        <Paginator first={first} rows={pagelimit} totalRecords={stocklist.total}  onPageChange={onPageChange} />
                    </div>
                    }
                </div>
                
            </div>
        </div>
        {
          (isLogout) && (<HandleUnauthorization />)
        }
      </>
    )
  }
  
export default ImportedStockPump