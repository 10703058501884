import React from 'react';

const ShowError = ({ errorsModal, classValue }) => {
    return (
        <span>
            {errorsModal && ( 
                (errorsModal.message) ? 
                    <span className={'text-danger '+classValue}>
                        {errorsModal.message}
                    </span>
                : ''    
            )}
        </span>
    );
};

export default ShowError;
