import React, { useState } from "react";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const FormFileSelect = (props) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedfileSubmital, setSelectedFileSubmital] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file !== undefined) {
            if (props.id === 'technical') {
                setSelectedFile(file);
                props.selectedfile(file);
            } else {
                setSelectedFileSubmital(file);
                props.selectedfileSubmital(file);
            }
        }

    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        if (props.id === 'technical') {
            setSelectedFile(file);
            // props.selectedfile(file);
        } else {
            setSelectedFileSubmital(file);
            // props.setSelectedfileSubmital(file);  
        }

        //setSelectedFile(file);
    };



    const browserClass = (selectedFile || selectedfileSubmital || props.editFileName) ? 'hidden' : '';

    const onchangeFileSelect = (selectedFile || selectedfileSubmital) ? true : false;

    const formatFileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1000;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = parseInt(Math.floor(Math.log(size) / Math.log(k)));
        return Math.round(size / Math.pow(k, i), 2) + ' ' + sizes[i];
    };

    const tooltip = (
        <Tooltip id="tooltip">
            Uploaded file should be <strong>PDF</strong> only and size should be less then <strong>{(props.id === 'technical') ? '8MB' : '80MB'}</strong>.
        </Tooltip>
    );

    return (
        <>
            <div className="form-row">
                <label>
                    {props.labelName}&nbsp;
                    <OverlayTrigger placement="top" overlay={tooltip}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="12px" height="12px"><path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" /></svg>
                    </OverlayTrigger>
                </label>
                <div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    className="fileTypeField"
                >
                    <input
                        type="file"
                        id={props.id}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        accept=".pdf"
                    />
                    <div className={`input_file_container ${browserClass}`}>
                        <span>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 20.5C19 21.051 18.551 21.5 18 21.5H6C5.449 21.5 5 21.051 5 20.5V4.5C5 3.949 5.449 3.5 6 3.5H13.586L19 8.914V20.5Z" stroke="#F57E20" strokeWidth="2" strokeMiterlimit="10" />
                                <path d="M18.5 9.5H13V4L18.5 9.5Z" fill="#F57E20" />
                                <path d="M6 20.5L10 16.5L12 18.5L16 14.5L19 17.5V21.5H6V20.5Z" fill="#F57E20" />
                                <path d="M9 13.5C9.55228 13.5 10 13.0523 10 12.5C10 11.9477 9.55228 11.5 9 11.5C8.44772 11.5 8 11.9477 8 12.5C8 13.0523 8.44772 13.5 9 13.5Z" fill="#F57E20" />
                            </svg>
                        </span>
                        <p className="instruction_show">Click to Upload \ Drag file here, or&nbsp;</p>
                        <label htmlFor={props.id} className="browse_text">Browse</label>
                    </div>
                    {(props.editFileName && onchangeFileSelect == false) && (
                        <div className="pdf_file_uploaded ">
                            <div className="pdf_details_show">
                                <div className="pdf_icons_show">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M33.3337 37.5H6.66699V2.5H25.0003L33.3337 10.8333V37.5Z" fill="#ED1D24" />
                                        <path d="M32.0837 11.6667H24.167V3.75L32.0837 11.6667Z" fill="#EFC8B8" />
                                        <path d="M13.3334 32.5C13.0001 32.5 12.7501 32.4167 12.5001 32.3333C11.5834 31.8333 11.5001 31.0833 11.6667 30.5C12.0001 29.5 13.8334 28.25 16.2501 27.1667C17.3334 25.1667 18.1667 23.0833 18.6667 21.3333C17.8334 19.75 17.4167 18.25 17.4167 17.1667C17.4167 16.5833 17.5834 16.0833 17.8334 15.6667C18.1667 15.25 18.6667 15 19.3334 15C20.0834 15 20.6667 15.4167 20.9167 16.1667C21.3334 17.1667 21.0834 19 20.5001 21.0833C21.3334 22.5 22.3334 23.8333 23.4167 24.8333C25.0001 24.5 26.4167 24.3333 27.3334 24.5C28.9167 24.75 29.1667 25.8333 29.1667 26.25C29.1667 28 27.3334 28 26.6667 28C25.4167 28 24.1667 27.5 23.0834 26.5833C21.0834 27.0833 19.0834 27.75 17.5001 28.5C16.6667 29.9167 15.8334 31.0833 15.0834 31.75C14.3334 32.3333 13.7501 32.5 13.3334 32.5ZM14.3334 30.0833C13.9167 30.3333 13.5834 30.5833 13.4167 30.8333C13.5834 30.75 13.9167 30.5833 14.3334 30.0833ZM25.6667 26.1667C26.0001 26.25 26.3334 26.3333 26.6667 26.3333C27.1667 26.3333 27.4167 26.25 27.5001 26.25C27.4167 26.1667 26.8334 26 25.6667 26.1667ZM19.8334 23.1667C19.5001 24.1667 19.0001 25.25 18.5834 26.25C19.5834 25.9167 20.5834 25.5833 21.5834 25.3333C20.9167 24.6667 20.3334 23.9167 19.8334 23.1667ZM19.3334 16.6667C19.2501 16.6667 19.2501 16.6667 19.2501 16.6667C19.1667 16.75 19.0834 17.3333 19.4167 18.5833C19.5001 17.5833 19.5001 16.8333 19.3334 16.6667Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="pdf_name_size">
                                    <p className="file_name">{props.editFileName}</p>
                                </div>
                            </div>
                            <div className="delete_and_change">
                                <div className="uploaded_pdf_icon">
                                    {/* <div className="icons_block">
                                        <div className="icon_svg">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.5 3.33301H2.5" stroke="#808285" strokeWidth="2" strokeMiterlimit="10"/>
                                                <path d="M4.16699 3.33301L5.73699 16.7763C5.79199 17.1905 6.14533 17.4997 6.56283 17.4997H13.437C13.8545 17.4997 14.2078 17.1905 14.2628 16.7763L15.8337 3.33301" stroke="#808285" strokeWidth="2" strokeMiterlimit="10"/>
                                                <path d="M12.5 2.50033V3.33366H7.5V2.50033L8.33333 1.66699H11.6667L12.5 2.50033Z" fill="#808285"/>
                                            </svg>
                                        </div>
                                        <a href="#" className="icon_text">Delete</a>
                                    </div> */}
                                    <div className="icons_block">
                                        <div className="icon_svg">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 8.33301L17.5 3.33301V8.33301H12.5Z" fill="#808285" />
                                                <path d="M7.5 11.667L2.5 16.667V11.667H7.5Z" fill="#808285" />
                                                <path d="M10 2.5C5.86417 2.5 2.5 5.865 2.5 10H4.16667C4.16667 6.78333 6.78417 4.16667 10 4.16667C12.3208 4.16667 14.3233 5.5325 15.2625 7.5H17.0625C16.03 4.59167 13.2583 2.5 10 2.5Z" fill="#808285" />
                                                <path d="M10 15.8333C7.67917 15.8333 5.67667 14.4675 4.7375 12.5H2.9375C3.97 15.4083 6.74167 17.5 10 17.5C14.1358 17.5 17.5 14.1358 17.5 10H15.8333C15.8333 13.2158 13.2158 15.8333 10 15.8333Z" fill="#808285" />
                                            </svg>
                                        </div>
                                        <label htmlFor={props.id} className="icon_text">Change</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedFile && (
                        <div className="pdf_file_uploaded ">
                            <div className="pdf_details_show">
                                <div className="pdf_icons_show">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M33.3337 37.5H6.66699V2.5H25.0003L33.3337 10.8333V37.5Z" fill="#ED1D24" />
                                        <path d="M32.0837 11.6667H24.167V3.75L32.0837 11.6667Z" fill="#EFC8B8" />
                                        <path d="M13.3334 32.5C13.0001 32.5 12.7501 32.4167 12.5001 32.3333C11.5834 31.8333 11.5001 31.0833 11.6667 30.5C12.0001 29.5 13.8334 28.25 16.2501 27.1667C17.3334 25.1667 18.1667 23.0833 18.6667 21.3333C17.8334 19.75 17.4167 18.25 17.4167 17.1667C17.4167 16.5833 17.5834 16.0833 17.8334 15.6667C18.1667 15.25 18.6667 15 19.3334 15C20.0834 15 20.6667 15.4167 20.9167 16.1667C21.3334 17.1667 21.0834 19 20.5001 21.0833C21.3334 22.5 22.3334 23.8333 23.4167 24.8333C25.0001 24.5 26.4167 24.3333 27.3334 24.5C28.9167 24.75 29.1667 25.8333 29.1667 26.25C29.1667 28 27.3334 28 26.6667 28C25.4167 28 24.1667 27.5 23.0834 26.5833C21.0834 27.0833 19.0834 27.75 17.5001 28.5C16.6667 29.9167 15.8334 31.0833 15.0834 31.75C14.3334 32.3333 13.7501 32.5 13.3334 32.5ZM14.3334 30.0833C13.9167 30.3333 13.5834 30.5833 13.4167 30.8333C13.5834 30.75 13.9167 30.5833 14.3334 30.0833ZM25.6667 26.1667C26.0001 26.25 26.3334 26.3333 26.6667 26.3333C27.1667 26.3333 27.4167 26.25 27.5001 26.25C27.4167 26.1667 26.8334 26 25.6667 26.1667ZM19.8334 23.1667C19.5001 24.1667 19.0001 25.25 18.5834 26.25C19.5834 25.9167 20.5834 25.5833 21.5834 25.3333C20.9167 24.6667 20.3334 23.9167 19.8334 23.1667ZM19.3334 16.6667C19.2501 16.6667 19.2501 16.6667 19.2501 16.6667C19.1667 16.75 19.0834 17.3333 19.4167 18.5833C19.5001 17.5833 19.5001 16.8333 19.3334 16.6667Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="pdf_name_size">
                                    <p className="file_name">{selectedFile.name}</p>
                                    <p className="file_size">{formatFileSize(selectedFile.size)}</p>
                                </div>
                            </div>
                            <div className="delete_and_change">
                                <div className="uploaded_pdf_icon">
                                    {/* <div className="icons_block">
                                        <div className="icon_svg">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.5 3.33301H2.5" stroke="#808285" strokeWidth="2" strokeMiterlimit="10"/>
                                                <path d="M4.16699 3.33301L5.73699 16.7763C5.79199 17.1905 6.14533 17.4997 6.56283 17.4997H13.437C13.8545 17.4997 14.2078 17.1905 14.2628 16.7763L15.8337 3.33301" stroke="#808285" strokeWidth="2" strokeMiterlimit="10"/>
                                                <path d="M12.5 2.50033V3.33366H7.5V2.50033L8.33333 1.66699H11.6667L12.5 2.50033Z" fill="#808285"/>
                                            </svg>
                                        </div>
                                        <a href="#" className="icon_text">Delete</a>
                                    </div> */}
                                    <div className="icons_block">
                                        <div className="icon_svg">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 8.33301L17.5 3.33301V8.33301H12.5Z" fill="#808285" />
                                                <path d="M7.5 11.667L2.5 16.667V11.667H7.5Z" fill="#808285" />
                                                <path d="M10 2.5C5.86417 2.5 2.5 5.865 2.5 10H4.16667C4.16667 6.78333 6.78417 4.16667 10 4.16667C12.3208 4.16667 14.3233 5.5325 15.2625 7.5H17.0625C16.03 4.59167 13.2583 2.5 10 2.5Z" fill="#808285" />
                                                <path d="M10 15.8333C7.67917 15.8333 5.67667 14.4675 4.7375 12.5H2.9375C3.97 15.4083 6.74167 17.5 10 17.5C14.1358 17.5 17.5 14.1358 17.5 10H15.8333C15.8333 13.2158 13.2158 15.8333 10 15.8333Z" fill="#808285" />
                                            </svg>
                                        </div>
                                        <label htmlFor={props.id} className="icon_text">Change</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedfileSubmital && (
                        <div className="pdf_file_uploaded ">
                            <div className="pdf_details_show">
                                <div className="pdf_icons_show">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M33.3337 37.5H6.66699V2.5H25.0003L33.3337 10.8333V37.5Z" fill="#ED1D24" />
                                        <path d="M32.0837 11.6667H24.167V3.75L32.0837 11.6667Z" fill="#EFC8B8" />
                                        <path d="M13.3334 32.5C13.0001 32.5 12.7501 32.4167 12.5001 32.3333C11.5834 31.8333 11.5001 31.0833 11.6667 30.5C12.0001 29.5 13.8334 28.25 16.2501 27.1667C17.3334 25.1667 18.1667 23.0833 18.6667 21.3333C17.8334 19.75 17.4167 18.25 17.4167 17.1667C17.4167 16.5833 17.5834 16.0833 17.8334 15.6667C18.1667 15.25 18.6667 15 19.3334 15C20.0834 15 20.6667 15.4167 20.9167 16.1667C21.3334 17.1667 21.0834 19 20.5001 21.0833C21.3334 22.5 22.3334 23.8333 23.4167 24.8333C25.0001 24.5 26.4167 24.3333 27.3334 24.5C28.9167 24.75 29.1667 25.8333 29.1667 26.25C29.1667 28 27.3334 28 26.6667 28C25.4167 28 24.1667 27.5 23.0834 26.5833C21.0834 27.0833 19.0834 27.75 17.5001 28.5C16.6667 29.9167 15.8334 31.0833 15.0834 31.75C14.3334 32.3333 13.7501 32.5 13.3334 32.5ZM14.3334 30.0833C13.9167 30.3333 13.5834 30.5833 13.4167 30.8333C13.5834 30.75 13.9167 30.5833 14.3334 30.0833ZM25.6667 26.1667C26.0001 26.25 26.3334 26.3333 26.6667 26.3333C27.1667 26.3333 27.4167 26.25 27.5001 26.25C27.4167 26.1667 26.8334 26 25.6667 26.1667ZM19.8334 23.1667C19.5001 24.1667 19.0001 25.25 18.5834 26.25C19.5834 25.9167 20.5834 25.5833 21.5834 25.3333C20.9167 24.6667 20.3334 23.9167 19.8334 23.1667ZM19.3334 16.6667C19.2501 16.6667 19.2501 16.6667 19.2501 16.6667C19.1667 16.75 19.0834 17.3333 19.4167 18.5833C19.5001 17.5833 19.5001 16.8333 19.3334 16.6667Z" fill="white" />
                                    </svg>
                                </div>
                                <div className="pdf_name_size">
                                    <p className="file_name">{selectedfileSubmital.name}</p>
                                    <p className="file_size">{formatFileSize(selectedfileSubmital.size)}</p>
                                </div>
                            </div>
                            <div className="delete_and_change">
                                <div className="uploaded_pdf_icon">
                                    {/* <div className="icons_block">
                                        <div className="icon_svg">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.5 3.33301H2.5" stroke="#808285" strokeWidth="2" strokeMiterlimit="10"/>
                                                <path d="M4.16699 3.33301L5.73699 16.7763C5.79199 17.1905 6.14533 17.4997 6.56283 17.4997H13.437C13.8545 17.4997 14.2078 17.1905 14.2628 16.7763L15.8337 3.33301" stroke="#808285" strokeWidth="2" strokeMiterlimit="10"/>
                                                <path d="M12.5 2.50033V3.33366H7.5V2.50033L8.33333 1.66699H11.6667L12.5 2.50033Z" fill="#808285"/>
                                            </svg>
                                        </div>
                                        <a href="#" className="icon_text">Delete</a>
                                    </div> */}
                                    <div className="icons_block">
                                        <div className="icon_svg">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5 8.33301L17.5 3.33301V8.33301H12.5Z" fill="#808285" />
                                                <path d="M7.5 11.667L2.5 16.667V11.667H7.5Z" fill="#808285" />
                                                <path d="M10 2.5C5.86417 2.5 2.5 5.865 2.5 10H4.16667C4.16667 6.78333 6.78417 4.16667 10 4.16667C12.3208 4.16667 14.3233 5.5325 15.2625 7.5H17.0625C16.03 4.59167 13.2583 2.5 10 2.5Z" fill="#808285" />
                                                <path d="M10 15.8333C7.67917 15.8333 5.67667 14.4675 4.7375 12.5H2.9375C3.97 15.4083 6.74167 17.5 10 17.5C14.1358 17.5 17.5 14.1358 17.5 10H15.8333C15.8333 13.2158 13.2158 15.8333 10 15.8333Z" fill="#808285" />
                                            </svg>
                                        </div>
                                        <label htmlFor={props.id} className="icon_text">Change</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FormFileSelect;
