import React, { useState, useEffect }  from 'react';
// import {DataTableField} from '../../../components';
import {PumpFilterModal, ManageStockDataTableField} from '../../../components';
import customFetch from "../../../utils/axios";
import AddNewPump from '../stockPump/AddNewPump';
import closeSvg from '../../../assets/images/Close.svg';
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";
import ToastMessage from '../../../components/ToastMessage';
import { useNavigate} from 'react-router-dom';

const StockPumpMaster = () => {

  let getMessage = sessionStorage.getItem('success_msg');
  if(getMessage) {
    sessionStorage.removeItem('success_msg'); 
  }
  
  const [errorMessage, setErrorMessage] = useState('');
  const [stocklist, setStockList] = useState([]);
  const [show, setShow] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState(getMessage);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [pagelimit, setpagelimit] = useState(10);
  const [pageNo, setpageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [clear_filter, setClearFilter] = useState(false);

  // filter value
  const [pumpTypeValue, setPumpTypeValue] = useState('');
  const [dutyPointValue, setDutyPointValue] = useState('');
  const [driverTypeValue, setDriverTypeValue] = useState('');
  const [visibilityVal, setVisibilityVal] = useState('');
  const [itemCodeVal, setItemCodeVal] = useState('');
  


  useEffect(() => {
    if (isFilterOpen) {
      document.body.classList.add('overflow_hidden');
    } else {
      document.body.classList.remove('overflow_hidden');
    }
    return () => {
      document.body.classList.remove('overflow_hidden');
    };
  }, [isFilterOpen]);

  const handleToggle = () => {
    setIsFilterOpen(true);
    setClearFilter(false);
  };


  useEffect(() => {
    fetchStock();
  }, [pageNo,pumpTypeValue,dutyPointValue,driverTypeValue,visibilityVal,itemCodeVal]);

  const navigate = useNavigate();

  const changePage = (page=1)=>{
    setpageNo(page);
  }
    const fetchStock = async () => {
      setErrorMessage('');
      try {
        let pump_type = pumpTypeValue;
        if(pumpTypeValue === 'Split Case'){
          pump_type = 'Splitcase';
        }
          const response = await customFetch.get(`/stocklist/`+pagelimit+`?page=`+pageNo,
          {
            params: {
              search_pump_type    : pump_type, 
              search_duty_point   : dutyPointValue,  
              search_driver_type  : driverTypeValue,
              search_visibility   : visibilityVal,
              search_item_code    : itemCodeVal
            }
          });
          const stocklist = response.data.stocklist;
          setStockList(stocklist);
      } catch (err) {
          // console.log(err);
          if (err.response.status === 401) {
            setIsLogout(true)
          }
          setErrorMessage('There is something wrong. Please try again');
          window.scrollTo(0,0);
      }
    };
    
    const callBackList = () => {
      fetchStock();
    }
    const handleShow=()=>{
      setApiSuccessMessage('')
      setShow(true);
    }

    const initialState = {
      first_name: '',
      last_name: '',
      email: '',
      role: null,
      status: '',
      company_id: '',
      id:null,
    };

    const onCloseCallBack = (message=null) => {
      setApiSuccessMessage('')
      if(message) {
        setApiSuccessMessage(message)

        callBackList();
      }
      setShow(false)
    }
    const redirectToDetail = () => {
       navigate('/add-new-pump');
 
    }

    const closeModal = () =>{
      setIsFilterOpen(false);
    };

    const pumpTypeValueFun = (event) => {
      setPumpTypeValue(event);
    }

    const dutyPointValueFun = (event) => {
      setDutyPointValue(event);
    }

    const driverTypeValueFun = (event) => {
      setDriverTypeValue(event);
    }

    const visibilityValFun = (event) => {
      setVisibilityVal(event);
    }

    const itemCodeValFun = (event) => {
      setItemCodeVal(event);
    }

   


    

     

    return (
      <>
        <div className='container'>
          {
            (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
          }
          
          <div className='stock_pump_master'>
            <div className="table-header table_heading_head">
              <div className="table_heading_content">
                <h1 className="p-m-0">Manage Stock Pumps</h1>
                <div className="conditional_block_show">
                  <div className={`add_new d-none addNewBtnShow me-3`}>
                    <button className='darkred_btn' onClick={redirectToDetail} >
                      <span>Add New Pump</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  </div>

                  <div className="filter_block">
                    <div className={`filter_box ${isFilterOpen ? 'filterOpen' : ''}`}  >
                      <a href="javascript:;" className="filter_link" onClick={handleToggle}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.50016 1.75H15.5002C16.4168 1.75 17.1668 2.5 17.1668 3.41667V5.25C17.1668 5.91667 16.7502 6.75 16.3335 7.16667L12.7502 10.3333C12.2502 10.75 11.9168 11.5833 11.9168 12.25V15.8333C11.9168 16.3333 11.5835 17 11.1668 17.25L10.0002 18C8.91683 18.6667 7.41683 17.9167 7.41683 16.5833V12.1667C7.41683 11.5833 7.0835 10.8333 6.75016 10.4167L3.5835 7.08333C3.16683 6.66667 2.8335 5.91667 2.8335 5.41667V3.5C2.8335 2.5 3.5835 1.75 4.50016 1.75Z" stroke="#393D53" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>
                          <span>filter</span>
                      </a>
                      <div className="filter_modal">
                        <div className='overlay'></div>
                        <PumpFilterModal pumpTypeValues={pumpTypeValueFun} dutyPointValues={dutyPointValueFun} driverTypeValues={driverTypeValueFun} visibilityVals={visibilityValFun} itemCodeValues={itemCodeValFun}  closeModal={closeModal} clearFilterData={clear_filter}/>           
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>      
            
            
            
            
            {/* <EnquiriesHead heading="Manage Stock Pumps" classToSee="addNewBtnShow"  btnContent="Add New Pump" handleAddNewClick={() => {redirectToDetail()}}/> */}
            <div className='stock_pump_master_table'>
            {/* <DataTableField /> */}
            <ManageStockDataTableField addOrEditMode="Edit" dataList={stocklist} callBackList={callBackList} changePage={changePage} pagelimit={pagelimit}/>
            </div>
            {
            (show) && (<AddNewPump callFrom="User" addOrEditMode='Add'  rowdata={initialState} onCloseCallBack={onCloseCallBack} />)
            }
          </div>
        </div>
        {
          (isLogout) && (<HandleUnauthorization />)
        }
      </>
    )
  }
  
export default StockPumpMaster