import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import loaderImage from '../../assets/images/loader.gif';
import EndSuctionCategory from '../../assets/images/comupdatedEndSuctionCategory.png';
import SplitcaseCategory from '../../assets/images/comupdatedSplitcaseCategory.png';
import VerticalTurbineCategory from '../../assets/images/comupdatedVerticalTurbineCategory.png';
import { useDispatch } from 'react-redux';
import { clearValues } from '../../features/pumpSelection/pumpSelectionSlice';
import { PumpType } from '../../utils/clientMasterAPI';
import EndSuctionMobile from '../../assets/images/ES.png';
import SplitcaseMobile from '../../assets/images/HSC.png';
import VerticalTurbineMobile from '../../assets/images/VT.png';

const SelectPump = () => {

  const dispatch = useDispatch();
  const [pumpTypeData, setPumpTypeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [EndSuction, setEndSuction] = useState(EndSuctionCategory);
  const [Splitcase, setSplitcase] = useState(SplitcaseCategory);
  const [VerticalTurbine, setVerticalTurbine] = useState(VerticalTurbineCategory);
  useEffect(() => {
    //console.log('inside useeffect')
    masterApiCall();
    dispatch(clearValues());
    if(window.innerWidth <= 768){
      setEndSuction(EndSuctionMobile);
      setSplitcase(SplitcaseMobile);
      setVerticalTurbine(VerticalTurbineMobile);
    }else{
      setEndSuction(EndSuctionCategory);
      setSplitcase(SplitcaseCategory);
      setVerticalTurbine(VerticalTurbineCategory);
    }
  }, []);

  async function masterApiCall() {
    setIsLoading(true)
    const getPumpTypeData =  await PumpType();
    if(getPumpTypeData[0]) {
      setIsLoading(false)
      setPumpTypeData(getPumpTypeData[1])
    }
  }
  
  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('loader_view');
    } else {
      document.body.classList.remove('loader_view');
    }
    return () => {
      document.body.classList.remove('loader_view');
    };
  }, [isLoading]);
  return (
    <>
      {
        (isLoading) && (<div className='show_loader'>  <img src={loaderImage} /> </div>)
      }
      <div className='select_pump_main'>
        <div className='container'>
            <div className='new_update_pumps'>
          <h1 className='new-fire-pump fire-select-heading'>Fire Pump Selection
          </h1>
          <h2 className='new-fire-pump select-pump-type-heading'>Select Pump Type</h2>
            
            {(pumpTypeData.length > 0) && pumpTypeData.map((value, index) => {
              let requestParam = '';
              let imageVal = '';
              switch(value.name) {
                case 'End Suction':
                  requestParam = 'end_suction';
                  imageVal = EndSuction;
                break;
                case 'Split Case':
                  requestParam = 'split_case';
                  imageVal = Splitcase;
                break;
                case 'Vertical Turbine':
                  requestParam = 'vertical_turbine';
                  imageVal = VerticalTurbine;
                break;
              }
              return (
              <div key={index} className='col-md-4-1 mb_md_20 new-pump'>
                <NavLink to={`/select-pump-detail?n=${requestParam}`}className='pump_img'>
                  <img src={imageVal} alt={value.name}/>
                  <div className='link_pump'>
                    {/* <NavLink to={`/select-pump-detail?n=${requestParam}`}className='pump_name'>{value.name}</NavLink> */}
                    {/* <span>
                    </span> */}
                  </div>
                </NavLink>
              </div>
              )
            })}
              {/* <div className='col-md-4 mb_md_20'>
              <NavLink to="/select-pump-detail?n=horizontal_splitcase" className='pump_img'>
                  <img src={horizontalSplitcase} alt="Horizontal Splitcase"/>
                  <div className='link_pump'>
                    <NavLink to="/select-pump-detail?n=horizontal_splitcase" className='pump_name'>Horizontal Splitcase</NavLink>
                    <span>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.4302 6.43005L20.5002 12.5001L14.4302 18.5701" stroke="#15223B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                       <path d="M3.5 12.5H20.33" stroke="#15223B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </span>
                  </div>
                </NavLink>
              </div>
              <div className='col-md-4 mb_md_20'>
              <NavLink to="/select-pump-detail?n=vertical_turbine" className='pump_img'>
                  <img src={VerticalTurbine} alt="Vertical Turbine"/>
                  <div className='link_pump'>
                    <NavLink to="/select-pump-detail?n=vertical_turbine" className='pump_name'>Vertical Turbine</NavLink>
                    <span>
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.4302 6.43005L20.5002 12.5001L14.4302 18.5701" stroke="#15223B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                       <path d="M3.5 12.5H20.33" stroke="#15223B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </span>
                  </div>
                </NavLink>
              </div> */}
            </div>
        </div>
      </div>
    </>
    // <div>
    //   SelectPump Page
    //   <Link to='/select-pump-detail'>Select Pump Detail</Link>
    // </div>
  )
}

export default SelectPump
