import arrowLeft from '../assets/images/arrow-left.svg';
import endSuction from '../assets/images/horizontalSplitcase.png';

const searchResultTableCriteria = ({criteriaData}) => {
    return(
      <>
      <div className='table_criteria'> 
        <div className='table_criteria_wrapper'>
            <div className="table_criteria_block">
                <p className='table_criteria_text'>Selected Criteria</p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>Required Pump flow</p>
                <p className='table_criteria_lowertext'>{criteriaData.flow} {criteriaData.flowUOM}</p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>Required Pump pressure</p>
                <p className='table_criteria_lowertext'>{criteriaData.head} {criteriaData.headUOM}</p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>Certification</p>
                <p className='table_criteria_lowertext'>{criteriaData.certification} </p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>Min Speed</p>
                <p className='table_criteria_lowertext'>{criteriaData.minSpeed}</p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>Max Speed</p>
                <p className='table_criteria_lowertext'>{criteriaData.maxSpeed}</p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>Driver Type</p>
                <p className='table_criteria_lowertext'>{criteriaData.driverType}</p>
            </div>
            <div className="table_criteria_block">
                <p className='table_criteria_uppertext'>NFPA Rated Flow</p>
                <p className='table_criteria_lowertext'>{(criteriaData.nfpa) ? 'Checked' : 'Unchecked'}</p>
            </div>
        </div>
      </div>
      </>
    );
  };
  export default searchResultTableCriteria;