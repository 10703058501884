const FormRow = ({ type, id, name, value, handleChange, labelText, isDisable, maxlengthValue, handleKeyDown,placeholder }) => {
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {labelText || name}
      </label>
      <input
        id={id || name}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className='form-control'
        autoComplete="off"
        disabled={(isDisable) ? true : ''}
        maxLength={(maxlengthValue) ? maxlengthValue : ''}
      />
    </div>
  );
};
export default FormRow;
