import React from 'react';
import { useState, useEffect } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import customFetch from "../../../utils/axios";

const EnquiryFilterModal = (props) => {

    const [enquiryNum, setEnquiryNum] = useState(null);
    const [statusValue, setstatusValue] = useState(null);
    const [allStatus, SetAllStatus] = useState([]);

    const closeMenu = () => {
      props.closeModal();
    };

    useEffect(() => {
        fetchEnquiryStatus();
        if(props.clearFilterData===true){
            setEnquiryNum('');
            setstatusValue([]);
            props.enquiryNum(null);
            props.statusValue(null);
        }
    }, [props.clearFilterData]);

      const applyFilter = ()=>{
        props.enquiryNum(enquiryNum);
        props.statusValue(statusValue);
        closeMenu();
      }
      const handleChange = (e, name) => {
        let value = e.target.value;
        setstatusValue(value);
      }
      const fetchEnquiryStatus = async () => {
          try {
              const response = await customFetch.get(`/get_enquiry_status`);
              const { status } = response.data;
      
              if(status === true){
                const data = response.data.enquirylist;
                var statuslist = [];
                data.map((item)=>{
                  // statuslist.push(item.status_value);
                  statuslist.push({name:item.status_value,code:item.name});
  
                })
                
                SetAllStatus(statuslist);       
              }
          } catch (err) {
            if (err.response.status === 401) {
            }
              //console.log(err);
              window.scrollTo(0,0);
          }
        };
    return(
        <>            
            <div className="filter_modal_content" >              
                <div className="filter_modal_head">
                    <h2>Filters</h2>
                    <a href="javascript:;" onClick={closeMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 19L19 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19 19L5 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className="filter_modal_body">
                    <form>
                        <div className="new_label">
                            <label className='form-label'>Enquiry Number</label>
                            <div className='form-row'>
                            <input 
                                type="text" 
                                value={enquiryNum}
                                className="form-control" 
                                name="item_code"
                                onChange={ (e) => setEnquiryNum(e.target.value) }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                            <label>Enquiry Status</label>
                              <MultiSelect value={statusValue} optionLabel="name" onChange={(e) => handleChange(e,'status')} options={allStatus}  display="chip" 
                                  placeholder="Enquiry Status"  className="w-ful" />
                          </div>
                          
                        <button type='button' onClick={applyFilter} className='w-100 darkred_btn'>
                            <span>Apply Filter</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default EnquiryFilterModal;