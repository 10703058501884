import React, { useState, useEffect } from "react";
import { DataTableField, EnquiriesHead } from '../../../components';
import customFetch from "../../../utils/axios";

import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row'; 
import { SplitButton } from 'primereact/splitbutton';

const CustomerListMobile = () => {
  const [nodes, setNodes] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');


  const items =(data) => ([
    {
      label: "Edit",
      icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.0529 4.28273L4.21122 11.5244C3.95289 11.7994 3.70289 12.3411 3.65289 12.7161L3.34455 15.4161C3.23622 16.3911 3.93622 17.0577 4.90289 16.8911L7.58622 16.4327C7.96122 16.3661 8.48622 16.0911 8.74455 15.8077L15.5862 8.56607C16.7696 7.31607 17.3029 5.89107 15.4612 4.1494C13.6279 2.4244 12.2362 3.03273 11.0529 4.28273Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M9.91113 5.49072C10.2695 7.79072 12.1361 9.54906 14.4528 9.78239" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
      command: async (event) => {
        setSelectedRow(data)
        setApiSuccessMessage('')
        setIsEditOpen(true)
      },
    },
    {
      label: "Delete",
      icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.5 4.98356C14.725 4.70856 11.9333 4.56689 9.15 4.56689C7.5 4.56689 5.85 4.65023 4.2 4.81689L2.5 4.98356" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.0835 4.1415L7.26683 3.04984C7.40016 2.25817 7.50016 1.6665 8.9085 1.6665H11.0918C12.5002 1.6665 12.6085 2.2915 12.7335 3.05817L12.9168 4.1415" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.6084 13.75H11.3834" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9165 10.4165H12.0832" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
      command: () => {
        setSelectedRow(data)
        setApiSuccessMessage('')
        setIsDeleteOpen(true)
      },
    },
  ]);

  const NameHeader = (rowDataMy) =>{
    return(
      <div>
          <div className="optionToggle">
            <div className="dots_col">
              <SplitButton        
                model={items(rowDataMy)}
              />
            </div>
            <h4>Harry Walter</h4>
          </div>
      </div>
    );
  }
  const statusHeader = (rowData) =>{
    const dotColor = rowData === 'active' ? '#0BDA6C' : '#F57E20';
    return(
      <div>
          <div className="head_right_top">
            <div className="status_shows">
              <svg width="8" height="8" style={{ marginRight: '6px' }}>
                <circle cx="4" cy="4" r="4" fill={dotColor} />
              </svg>
              <div  style={{ textTransform: 'capitalize'}}> {rowData}Enquiry Received </div>
            </div>
          </div>
      </div>
    );
  }
    const companyId = () =>{
      return(
        <div>
            <div className="form-row">
              <label>Company ID</label>
              <p>NFC12345</p>
            </div>
        </div>
      );
    }
    const showMe = () =>{
      return(
        <div className="form-row">
          <label>Email</label>
          <p>abc@xyz.com</p>
        </div>
      );
    }

    const contactMe = () =>{
      return(
        <div className="form-row">
          <label>Contact</label>
          <p>+1 - 234 - 456 - 78</p>
        </div>
      );
    }
    const blankMe = () =>{
      return(
        <div className="form-row">
           <label>Test</label>
          <p>+1 - 234 - 456 - 78</p>
        </div>
      );
    }

    useEffect(() => {
        let files = [];
        for (let i = 0; i < 50; i++) {
            let node = {
                key: '1',
                data: {
                    name:  NameHeader(),
                    status: statusHeader(),
                },
                children: [
                    {
                        key: '1-1',
                        data: {
                            name: companyId(),
                            status: showMe(),
                        }
                    },
                    {
                      key: '1-2',
                      data: {
                          name: contactMe(),
                          status: blankMe(),
                      }
                  }
                ]
            };

            files.push(node);        
          }
        setNodes(files);
    }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const [userlist, setUserList] = useState('');
  const [pagelimit, setpagelimit] = useState(10);
  const [pageNo, setpageNo] = useState(0);

  const callBackList = () => {
    fetchUser();
  }
  useEffect(() => {
    fetchUser();
  }, [pageNo]);

  const changePage = (page=1)=>{
    setpageNo(page);
  }

  const fetchUser = async () => {
    setErrorMessage('');
    try {
        const response = await customFetch.get(`/userslist/customer/`+pagelimit+`?page=`+pageNo);
        const userlist = response.data.userslist;
        setUserList(userlist);

    } catch (err) {
        // console.log(err);
        setErrorMessage('There is something wrong. Please try again');
        window.scrollTo(0,0);
    }
  };

    return (
      <>
      <div className="container">
        <div className="table_box">
         <EnquiriesHead heading="Customers"/>
          <div className="customer_table">
            <DataTableField callFrom="Customer" addOrEditMode="Edit" dataList={userlist} callBackList={callBackList} changePage={changePage} pagelimit={pagelimit} />
          </div>
          <div className="custom_treeTable">
              <TreeTable value={nodes} paginator rows={5}>
                  <Column field="name" header="Name" className="mobile_td_one" ></Column>
                  <Column field="status" header="Status" className="mobile_td_two" expander="true" ></Column>
                  {/* <Row className="custom-expander-row" rowClassNameKey={(node) => node.expanded ? 'expanded' : 'collapsed'} /> */}
              </TreeTable>
          </div>
        </div>
        </div>
      </>
    )
  }
  
export default CustomerListMobile