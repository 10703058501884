import TableHead from '../../components/SearchResultTableHead';
import TableCriteria from '../../components/SearchResultTableCriteria';
import PumpModel from '../../assets/images/pump_model.svg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { GraphModal, PopupModalEdit,ToastMessage } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setListPageNumber,updateAPIStatus, updateSearchResultSelectedData, updateRedirectStatus } from '../../features/pumpSelection/pumpSelectionSlice';
import endSuction from '../../assets/images/horizontalSplitcase.png';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { RadioButton } from 'primereact/radiobutton';
        
const initialStateEnquiry = {
  userId:'',
  name: '',
  contact_number: '',
  email: '',
  message: '',
  location: '',
  location_text: '',
  location_json: '',
  is_enquiry_send: ''
};
  // mobile
  const CustomHeader = ({ radioName, selectedValue, onChange, title }) => (
    <div className='main_toggle_mobile'>
      <div className='radio_and_text'>
      <RadioButton name={radioName} value={title} onChange={onChange} checked={selectedValue === title} />
      <h4>{title}
        <a to="#" className='show_graph_mobile'>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.7334 15.125V13.4" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M10 15.125V11.675" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14.2666 15.125V9.94165" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14.2667 4.875L13.8834 5.325C11.7584 7.80833 8.9084 9.56667 5.7334 10.3583" stroke="#808285" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M11.8252 4.875H14.2669V7.30833" stroke="#808285" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.50033 18.3333H12.5003C16.667 18.3333 18.3337 16.6666 18.3337 12.5V7.49996C18.3337 3.33329 16.667 1.66663 12.5003 1.66663H7.50033C3.33366 1.66663 1.66699 3.33329 1.66699 7.49996V12.5C1.66699 16.6666 3.33366 18.3333 7.50033 18.3333Z" stroke="#808285" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </h4>
      </div>
      <div className='stage_details'>
          <span className='stage_content'>1</span>
      </div>
    </div>
  );
  // end



const SelectPumpListMobile = () => {

  const [enquiryFormPopupData, setEnquiryFormPopupData] = useState(initialStateEnquiry);

  const [isGraphModal, setGraphModal] = useState(false);
  const [isEnquiryModal, setIsEnquiryModal] = useState(false);

  const { pump_selection, selection_criteria, search_result_api, list_page_number, search_result_data_selected, country_api_data, is_row_selected } = useSelector((store) => store.pumpSelection);

  const { user } = useSelector((store) => store.user);

  const [searchListData, setSearchListData] = useState([]);
  const [isEnquiryButtonEnabled, setIsEnquiryButtonEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const dispatch = useDispatch();

  
  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Model" rowSpan={2}/>
        <Column header="Stage" rowSpan={2}/>
        <Column header="PEAK Eff (%)" rowSpan={2}/>
        <Column header="Speed" rowSpan={2}/>
        
        <Column header="Power" colSpan={2} />
        <Column rowSpan={2} />
      </Row>
      <Row>
        <Column header="Rated" />
        <Column header="150%" />
      </Row>
    </ColumnGroup>
  );
  const handleGraphModal = () => {
    setGraphModal(!isGraphModal)
  }
  
  const Model = (usernameData) =>{
    return (
      
      <div className="newUser" style={{ textTransform: 'capitalize'}}>
        <input type="radio" className='pump_radio_mob' name="selectPump" />
        <p>{usernameData.pumpModel}</p>
        <a href='javascript:;' className="model_img" onClick={handleGraphModal}>          
          <img src={PumpModel} alt='Pump Model Icon'/>
        </a>
      </div>
    );
  }

  const peakBodyTemplate = (rowData) => {
    if(rowData.peakEff)
      return `${parseFloat(rowData.peakEff).toFixed(2)}%`;
    else
      return 0;
  };
  const modelRowDataSelected = (rowData) => {
    
  }
  const radioColumnTemplate = (rowData) => {
    return <input type="radio" name="selectModelPumpRow" id={`row_${rowData.id}`} onClick={() => {modelRowDataSelected(rowData)}} value={rowData.id}/>;
  };

  const onCloseCallBack = (message=null) => {
    setIsEnquiryModal(false)
    setSuccessMessage(message)
    dispatch(updateAPIStatus())
    dispatch(updateRedirectStatus(false));
    setIsEnquiryButtonEnabled(false)

    let selectModelPumpRowValue = document.querySelector('input[name = "selectModelPumpRow"]:checked').value;
    if(selectModelPumpRowValue !== null) {
      document.getElementById("row_"+selectModelPumpRowValue).checked = false;
    }

    setErrorMessage('')
    setShowErrorMessage(false)
    window.scrollTo(0, 0);
  }
  const openEnquiryPopup = () => {
   // alert(JSON.stringify(enquiryFormPopupData))
   setIsEnquiryModal(true)
  }
  const toastMessageCallBack = () => {
    setErrorMessage('')
    setShowErrorMessage(false)
  }


  // mobile
  const [selectedRadio, setSelectedRadio] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedRadio(event.value);
  };
  // end
  return (
    <>
    {
      (searchListData.length > 0) &&  <ToastMessage showHidePanel={true} isError={0} message={`${searchListData.length} ${searchListData.length === 1 ? 'Pump' : 'Pumps'} Found`} toastMessageCallBack={toastMessageCallBack}/> 
    }
    
    {
      (successMessage) && (<ToastMessage showHidePanel={true} isError={0} message={successMessage} toastMessageCallBack={toastMessageCallBack}/>)
    }
    {
      (showErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={errorMessage} toastMessageCallBack={toastMessageCallBack}/>)
    }
    <TableHead tableHeading='End Suction' tableTitle="Selected Pump" tableHeadingImage={endSuction} tableHeadingRedirect={(pump_selection.length > 0) ? pump_selection[0].request_param : ''} />
   
    <div className="custom_dataTable pumple_list">
      <div className="container">
      <div className='pump_table_main'>
        <div className='pump_table_overflow'>
          <div className='pump_table_width'>
            <TableCriteria criteriaData={(selection_criteria.length > 0) ? (list_page_number === 'first') ? selection_criteria[0] : selection_criteria[1] : ''} />
            <DataTable value={searchListData} headerColumnGroup={headerGroup}>
                <Column field="pumpModel" body={Model} />
                <Column field="stage" />
                <Column field="peakEff"  body={peakBodyTemplate}/>
                <Column field="speed"  />
                <Column field="ratedPower"  />
                <Column field="150Power"  />
                <Column body={radioColumnTemplate} />
            </DataTable>
          </div>

          {/* mobile */}
            <div className='pump_mobile_table'>
              <div className='pump_thead'>
                <div className='pump_mobile_th'>
                  <h6>Model</h6>
                </div>
                <div className='pump_mobile_th'>
                  <h6>Stage</h6>
                </div>
              </div>
              <div className='pump_mobile_tabs'>
                <div className="">
                    <Accordion activeIndex={0}>
                        <AccordionTab header={<CustomHeader radioName="accordionRadio" selectedValue={selectedRadio} onChange={handleRadioChange} title="NF-S-125-310" />}>
                            <div className='mobile_pump_accordion_body'>
                              <div className='form-row'>
                                <label>PEAK EFF (%)</label>
                                <p className='field_td_mobile'>73.92%</p>
                              </div>
                              <div className='form-row'>
                                <label>SPEED</label>
                                <p className='field_td_mobile'>2980</p>
                              </div>
                              <div className='form-row'>
                                <label>POWER (Rated)</label>
                                <p className='field_td_mobile'>63.95%</p>
                              </div>
                              <div className='form-row'>
                                <label>POWER (150%)</label>
                                <p className='field_td_mobile'>72.06</p>
                              </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header={<CustomHeader radioName="accordionRadio" selectedValue={selectedRadio} onChange={handleRadioChange} title="NF-S-125-310" />}>
                        <div className='mobile_pump_accordion_body'>
                              <div className='form-row'>
                                <label>PEAK EFF (%)</label>
                                <p className='field_td_mobile'>73.92%</p>
                              </div>
                              <div className='form-row'>
                                <label>SPEED</label>
                                <p className='field_td_mobile'>2980</p>
                              </div>
                              <div className='form-row'>
                                <label>POWER (Rated)</label>
                                <p className='field_td_mobile'>63.95%</p>
                              </div>
                              <div className='form-row'>
                                <label>POWER (150%)</label>
                                <p className='field_td_mobile'>72.06</p>
                              </div>
                            </div>
                        </AccordionTab>
                        <AccordionTab header={<CustomHeader radioName="accordionRadio" selectedValue={selectedRadio} onChange={handleRadioChange} title="NF-S-125-310" />}>
                        <div className='mobile_pump_accordion_body'>
                              <div className='form-row'>
                                <label>PEAK EFF (%)</label>
                                <p className='field_td_mobile'>73.92%</p>
                              </div>
                              <div className='form-row'>
                                <label>SPEED</label>
                                <p className='field_td_mobile'>2980</p>
                              </div>
                              <div className='form-row'>
                                <label>POWER (Rated)</label>
                                <p className='field_td_mobile'>63.95%</p>
                              </div>
                              <div className='form-row'>
                                <label>POWER (150%)</label>
                                <p className='field_td_mobile'>72.06</p>
                              </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>    
              </div>
            </div>
          {/* end */}
        </div>   
        </div> 
        <div className="pumple_list_btn">
        {
          (selection_criteria.length > 1) && (list_page_number === 'second') && ( <button  type="button" className="outline_btn">Previous Selection
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
            </path><path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </button>)
        }
        <div className='full_hr'></div>
        {
          (selection_criteria.length > 1) && (list_page_number === 'first') && ( <button  type="button" className="outline_btn">Next Selection
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
            </path><path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </button>)
        }
         
          <button type="button" className="darkred_btn" disabled={!isEnquiryButtonEnabled} onClick={openEnquiryPopup}>Confirm Selection
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
              <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
              </path><path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            </button>
        </div>
       </div>
      </div> 
      {
        (isGraphModal) && <GraphModal />
      }
      {
        (isEnquiryModal) && <PopupModalEdit callFrom="Enquiry" addOrEditMode='Send' onCloseCallBack={onCloseCallBack} rowdata={enquiryFormPopupData} locationData={country_api_data}/>
      }
    </>
  )
}

export default SelectPumpListMobile
