import { useState, useEffect } from 'react';
import { FormRow, ModalComponent, ToastMessage,FormRowSelect } from '../components';
import { NavLink,useNavigate,useLocation, Link } from 'react-router-dom';
import logo from '../assets/images/new_logo.png';
// import login_banner from '../assets/images/real-login-bg.jpg';
import login_banner from '../assets/images/LOGIN-WEB.jpg';
// import mobile_login_banner from '../assets/images/mobile_login_bg.jpg';
import mobile_login_banner from '../assets/images/LOGIN-MOBILE.jpg';
import closedEye from '../assets/images/closed-eye.svg';
import openEye from '../assets/images/EyeGrey.svg';
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, registerUser, forgotPasswordUser, resendVerifyEmailLink, clearSuccessStatus } from '../features/user/userSlice';
import {CountryList } from '../utils/clientMasterAPI';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  companyName: '',
  contactNumber:'',
  countryValue:'',
  password: '',
  confirmPassword: '',
  isMember: true,
  isForgotPassword: false,
  location : ''
};

const modalComponentInitialState = {
  showModal: false,
  title: '',
  content: '',
  redirectLink: ''
};

function ContentHtml(props) {
  if(props.mode === 'forgot_password') {
    return <p>We have sent a password recovery instruction to your email.</p>;
  }
  return <p>We have sent an email to <br/><a href={`mailto:${props.email}`}> {props.email} </a> to verify your account.</p>;
}

function Register() {
  const location = useLocation();
  //alert(location.pathname);
  const [modalValues, setModalValues] = useState(modalComponentInitialState);
  const [passwordShowHide, setPasswordShowHide] = useState(false);
  const [confirmPasswordShowHide, setConfirmPasswordShowHide] = useState(false);

  const [locationPath, setLocationPath] = useState(location.pathname);

  const { user, isLoading, isSuccessAPI, apiErrorMessage, isResendVerifyEmailLinkEnable } = useSelector((store) => store.user);
  const [countrydata, setCountryData] = useState([]);
  // const [countryValue, setCountryValue] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (locationPath === '/register') {
      formik.setFieldValue("isForgotPassword", false)
      formik.setFieldValue("isMember", false)
    } else if (locationPath === '/login') {
      formik.setFieldValue("isForgotPassword", false)
      formik.setFieldValue("isMember", true)
    } else if (locationPath === '/forgot-password') {
      formik.setFieldValue("isForgotPassword", true)
      formik.setFieldValue("isMember", false)
    }
  }, [locationPath]);

  useEffect(() => {
    if(apiErrorMessage)
      window.scrollTo(0, 0);
  }, [apiErrorMessage]);

  useEffect(() => {
    if(user) {
      formik.resetForm();
      if(user.role === 'sales'){
        navigate('/work-in-progress')
      }else if(user.role === 'admin') {
        navigate('/customer-list')
      } else {
        navigate('/select-pump')
      }
    }
    if (isSuccessAPI) {
        setModalValues({
          showModal: true,
          title: 'Check Your Email',
          content: <ContentHtml mode={(formik.values.isForgotPassword) ? 'forgot_password': 'register'} email={formik.values.email}/>,
          redirectLink: '/login'
        })
        formik.resetForm();
    }
    
  }, [isSuccessAPI,user]);

  useEffect(()=>{
    masterApiCall();
  },[]);

  const modalCallBack = () => {
    dispatch(clearSuccessStatus());
    setPasswordShowHide(false)
    setConfirmPasswordShowHide(false)
    setModalValues(modalComponentInitialState)
  }
  async function masterApiCall() {
    const getCountryCodeData =  await CountryList();
    if(getCountryCodeData[0]) {
        setCountryData(getCountryCodeData[1])
    }

}
  const formik = useFormik({
    initialValues: initialState,
    validate: (data) => {
        let errors = {};
        let isValid = true;

        if (!data.email) {
            errors.email = 'Email address is required.';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
            errors.email = 'Invalid email address. e.g. example@email.com';
        }

        if(data.isForgotPassword === false) {
          if (data.password == null || data.password.trim() === '') {
            errors.password = 'Password is required.';
          } else {
            if(data.isMember === false) {
              const isPassValid = validatePassword(data.password);
              if(isPassValid.length > 0) {
                errors.password = 'Please enter valid password.';
              }
            }
          }
        }
       
        if(data.isMember === false && data.isForgotPassword === false) {
          if (data.firstName == null || data.firstName.trim() === '') {
            errors.firstName = 'First name is required.';
          } else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(data.firstName)) {
            errors.firstName = 'Please use only alphabetic characters';
          }

          if (data.lastName == null || data.lastName.trim() === '') {
              errors.lastName = 'Last name is required.';
          } else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(data.lastName)) {
            errors.lastName = 'Please use only alphabetic characters.';
          }
          
          if (data.contactNumber !== '') {
            if (!/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(data.contactNumber)) {
              errors.contactNumber = 'Please use only numeric characters';
            } else if(data.contactNumber.length < 9) {  
              errors.contactNumber = 'Please enter valid contact number';
            }
          }
          
          if (data.location.country == null || data.location.country.trim() === '') {
            errors.location = 'Location is required.';
          }
          
          if (data.companyName == null || data.companyName.trim() === '') {
              errors.companyName = 'Company name is required.';
          }

          if (data.confirmPassword == null || data.confirmPassword.trim() === '') {
            errors.confirmPassword = 'Confirm password is required.';
          }
          if(data.password && data.confirmPassword) {
            if(data.password != data.confirmPassword) {
              errors.confirmPassword = 'Password and confirm password must be same.';
            }
          }
        }

        if (isValid) {
            return errors;
        } else {
            return errors;
        }

    },
    onSubmit: (data) => {
      handleSubmit(data);
    }
  });
  useEffect(() => {
    if (!formik.isSubmitting) return;
    const el = document.querySelector('.p-error');
    (el?.parentElement ?? el)?.scrollIntoView();
  }, [formik]);

  const validatePassword = (pass) => {
    let errors = [];
    if (pass.length < 8) {
        errors.push("Password must be at least 8 characters");
    }
    if (pass.search(/[0-9]/) < 0) {
        errors.push("Password must contain at least one digit.");
    }

    if (pass.search(/^\S*$/) < 0) {
      errors.push("Password must not contain Whitespaces.");
    }

    if (pass.search(/^(?=.*[A-Z]).*$/) < 0) {
      errors.push("Password must have at least one Uppercase Character.");
    }

    if (pass.search(/^(?=.*[a-z]).*$/) < 0) {
      errors.push("Password must have at least one Lowercase Character.");
    }

    if (pass.search(/^(?=.*[!@#$%^&*]).*$/) < 0) {
      errors.push("Password must contain at least one Special Symbol.");
    }

    return errors.join("\n")
  }
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
      return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  
  const handleSubmit = async (data) => {
    
    const { firstName, lastName, email, companyName, contactNumber, password, confirmPassword, isMember, isForgotPassword ,countryValue, location} = data;

    if (isForgotPassword) {
      dispatch(forgotPasswordUser({ email }));
      return;
    }

    if (isMember) {
      dispatch(loginUser({ email: email, password: password }));
      return;
    }
    dispatch(registerUser({ firstName, lastName, email, companyName, contactNumber,  password, confirmPassword, countryValue, location }));
  };

  const handleResendEmailVerify = async() => {
    dispatch(resendVerifyEmailLink({ email:formik.values.email }));
  }

  const countryChange = (e) => {
    let value = e.target.value;
    console.log(value);

    formik.setFieldValue("location", value.country)
    console.log(formik.values.locaton)
}
  const toggleMember = (e) => {
    //alert(1)
    //alert(e.target.value)
    dispatch(clearSuccessStatus());
    setPasswordShowHide(false)
    setConfirmPasswordShowHide(false)
    formik.resetForm()
    formik.setFieldValue("isForgotPassword", false)
    formik.setFieldValue("isMember", !formik.values.isMember)

    if(e.target.value === 'Login') {
      navigate('/login');
    } else if(e.target.value === 'Sign up') {
      navigate('/register');
    }
   // setValues({ ...values, isMember: !formik.values.isMember });
  };
  
  const toggleForgotPassword = () => {
    dispatch(clearSuccessStatus());
    setPasswordShowHide(false)
    setConfirmPasswordShowHide(false)
    formik.resetForm()
    formik.setFieldValue("isMember", false)
    formik.setFieldValue("isForgotPassword", true)
    navigate('/forgot-password');
  };

  const togglePassword = (mode) => {
    if(mode === 'password') {
      setPasswordShowHide(!passwordShowHide)
    } else {
      setConfirmPasswordShowHide(!confirmPasswordShowHide)
    }
  }
  const goToMainMenu =()=>{
    navigate('/select-pump');
  }
  const passwordCheckStrength = (e) => {
    
    if(!formik.values.isMember && !formik.values.isForgotPassword) {
      let password = e.target.value;
      let strength = 0;

      let passwordStrength = document.getElementById("password-strength");
      
      //If password contains both lower and uppercase characters
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        strength += 1;
        document.querySelector(".low-upper-case-right").style.display = 'flex';
        document.querySelector(".low-upper-case-dot").style.display = 'none';
      } else {
        document.querySelector(".low-upper-case-right").style.display = 'none';
        document.querySelector(".low-upper-case-dot").style.display = 'flex';
      }
      //If it has numbers and characters
      if (password.match(/([0-9])/)) {
        strength += 1;
        document.querySelector(".one-number-right").style.display = 'flex';
        document.querySelector(".one-number-dot").style.display = 'none';
      } else {
        document.querySelector(".one-number-right").style.display = 'none';
        document.querySelector(".one-number-dot").style.display = 'flex';
      }
      
      //If it has one special character
      if (password.match(/([!,%,&,@,#,$,^,*])/)) {
        strength += 1;
        document.querySelector(".one-special-char-right").style.display = 'flex';
        document.querySelector(".one-special-char-dot").style.display = 'none';
      } else {
        document.querySelector(".one-special-char-right").style.display = 'none';
        document.querySelector(".one-special-char-dot").style.display = 'flex';
      }
      //If password is greater than 7
      if (password.length > 7) {
        strength += 1;
        document.querySelector(".eight-character-right").style.display = 'flex';
        document.querySelector(".eight-character-dot").style.display = 'none';
      } else {
        document.querySelector(".eight-character-right").style.display = 'none';
        document.querySelector(".eight-character-dot").style.display = 'flex';
      }

      // If value is less than 2
      if (strength < 2) {
          passwordStrength.classList.remove('progress-bar-warning');
          passwordStrength.classList.remove('progress-bar-success');
          passwordStrength.classList.add('progress-bar-danger');
          passwordStrength.style = 'width: 10%';
      } else if (strength == 3) {
          passwordStrength.classList.remove('progress-bar-success');
          passwordStrength.classList.remove('progress-bar-danger');
          passwordStrength.classList.add('progress-bar-warning');
          passwordStrength.style = 'width: 60%';
      } else if (strength == 4) {
          passwordStrength.classList.remove('progress-bar-warning');
          passwordStrength.classList.remove('progress-bar-danger');
          passwordStrength.classList.add('progress-bar-success');
          passwordStrength.style = 'width: 100%';
      }
    }
  }
  return (
    <>
      <div className='login_block'>
        <div className='row w-100 mx-auto'>
          <div className= {"col-md-7 px-0 bg_login " + (formik.values.isMember ? 'h100vh' : '') + (formik.values.isForgotPassword ? 'h100vh' : '')}>

          {/* <Link to={"/select-pump"} className='flow_select_link'>Go to Flow Select</Link> */}
          <img src={login_banner} alt="logo" className='login_banner desktop_login_bg' />
          <img src={mobile_login_banner} alt="logo" className='login_banner mobile_login_bg' />
          </div>
          <div className='col-md-5 px-0 login_form_col'>
            <div className="form-inner-col">
              <div className='login_logo'>
              <NavLink to="/select-pump"><img src={logo} alt="logo" className='login_logo_img' /></NavLink>
              </div>
              <form className='form' onSubmit={formik.handleSubmit}>

                <h1>{formik.values.isForgotPassword ? 'Forgot Password' : formik.values.isMember ? 'Login' : 'Sign Up'}</h1>
                <p className='sub_name_para'>{formik.values.isForgotPassword ? 'Enter your Email ID here' : formik.values.isMember ? 'Enter your credentials here' : 'Enter your details to create your Account'}</p>
                
                {/* first name field */}
                
                {
                  (!isSuccessAPI) && (apiErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={apiErrorMessage} />)
                }
                {
                  (isResendVerifyEmailLinkEnable === 1) && (<div className="text-center"><NavLink onClick={handleResendEmailVerify} className="signup resend-verify-link"><span>Resend Email Verify Link</span>{isLoading && (<span>Loading...</span>)}</NavLink></div>)
                }
                <div className="fields-group">
                {!formik.values.isMember && !formik.values.isForgotPassword && (
                  <FormRow
                    type='text'
                    labelText='first name'
                    name='firstName'
                    value={formik.values.firstName}
                    handleChange={formik.handleChange}
                    maxlengthValue={30}
                  />
                  
                )}
                {getFormErrorMessage('firstName')}
                </div>
                {/* last name field */}
                <div className="fields-group">
                  {!formik.values.isMember && !formik.values.isForgotPassword && (
                    
                    <FormRow
                      type='text'
                      labelText='last name'
                      name='lastName'
                      value={formik.values.lastName}
                      handleChange={formik.handleChange}
                      maxlengthValue={30}
                    />
                  )}
                  {getFormErrorMessage('lastName')}
                </div>
                {/* email field */}
                <div className="fields-group">
                  <FormRow
                  type='text'
                  name='email'
                  value={formik.values.email}
                  handleChange={formik.handleChange}
                  maxlengthValue={100}
                  />
                  {getFormErrorMessage('email')}
                </div>
                {/* company name field */}
                <div className="fields-group">
                {!formik.values.isMember && !formik.values.isForgotPassword && (
                  <FormRow
                    type='text'
                    labelText='company name'
                    name='companyName'
                    value={formik.values.companyName}
                    handleChange={formik.handleChange}
                    maxlengthValue={70}
                  />
                )}
                {getFormErrorMessage('companyName')}
              </div>
              {/* company name field */}
              <div className="fields-group">
                {!formik.values.isMember && !formik.values.isForgotPassword && (
                  <FormRow
                    type='text'
                    labelText='contact number'
                    name='contactNumber'
                    value={formik.values.contactNumber}
                    handleChange={formik.handleChange}
                    maxlengthValue={15}
                  />
                )}
                {getFormErrorMessage('contactNumber')}
              </div>
              <div className="prime_dropdown fields-group">
                {!formik.values.isMember && !formik.values.isForgotPassword && (
                   <FormRowSelect
                   name='location'
                   list={countrydata}
                   value={formik.values.location}
                   placeholder="Select Location" 
                   defaultValue={"placeholder"}
                   handleChange={formik.handleChange}
                   required
                   />
                )}
                {getFormErrorMessage('location')}
              </div>
                {/* password field */}
                <div className="fields-group">
                {!formik.values.isForgotPassword && (
                <div className='password_field_box'>
                  <FormRow
                    type={!passwordShowHide ? 'password' : 'text'}
                    name='password'
                    value={formik.values.password}
                    handleChange={(e) => {formik.handleChange(e); passwordCheckStrength(e)}}
                    maxlengthValue={30}
                  />
                  <span className='eye_close_box'>
                   <img src={!passwordShowHide ? closedEye : openEye} alt={!passwordShowHide ? closedEye : openEye} onClick={() => {togglePassword('password')}} className='closed_eye_img' />
                  </span>
                </div>)
                }
                {getFormErrorMessage('password')}
                </div>
                {/* confirm password field */}
                <div className="fields-group">
                {!formik.values.isMember && !formik.values.isForgotPassword && (
                <div className='password_field_box'>
                  <FormRow
                    type={!confirmPasswordShowHide ? 'password' : 'text'}
                    labelText='confirm password'
                    name='confirmPassword'
                    value={formik.values.confirmPassword}
                    handleChange={formik.handleChange}
                    maxlengthValue={30}
                  />
                 <span className='eye_close_box'>
                   <img src={!confirmPasswordShowHide ? closedEye : openEye} alt={!confirmPasswordShowHide ? closedEye : openEye} onClick={() => {togglePassword('confirm_password')}} className='closed_eye_img' />
                  
                  </span>
                </div>
                )}
                {getFormErrorMessage('confirmPassword')}
                </div>
                {!formik.values.isMember && !formik.values.isForgotPassword && (
                  <div id="popover-password">
                      <div className='form-row'>
                        <label>Strength</label>
                        <div className="progress">
                            <div id="password-strength" 
                                className="progress-bar" 
                                role="progressbar" 
                                aria-valuenow="40" 
                                aria-valuemin="0" 
                                aria-valuemax="100" 
                                >
                            </div>
                        </div>
                      </div>
                      <ul className="list-unstyled">
                          <li>
                              <div className="low-upper-case password_details">
                                  <div className='status_svg'>
                                    <div className='green_right_svg low-upper-case-right' >
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="12" height="12" fill="white"/>
                                        <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    </div>
                                    <div className='grey_dot_svg low-upper-case-dot'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="6" cy="6" r="2" fill="#808285"/>
                                        </svg>
                                    </div>
                                  </div>
                                  <span>
                                      &nbsp;Lowercase &amp; Uppercase
                                  </span>
                              </div>
                          </li>
                          <li className="">
                              <div className="one-number password_details">
                              <div className='status_svg'>
                                    <div className='green_right_svg one-number-right'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="12" height="12" fill="white"/>
                                        <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    </div>
                                    <div className='grey_dot_svg one-number-dot'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="6" cy="6" r="2" fill="#808285"/>
                                        </svg>
                                    </div>
                                  </div>
                                  <span>&nbsp;Number (0-9)</span>
                              </div> 
                          </li>
                          <li className="">
                              <div className="one-special-char password_details">
                              <div className='status_svg'>
                                    <div className='green_right_svg one-special-char-right'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="12" height="12" fill="white"/>
                                        <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    </div>
                                    <div className='grey_dot_svg one-special-char-dot'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="6" cy="6" r="2" fill="#808285"/>
                                        </svg>
                                    </div>
                                  </div>
                                  <span>
                                  &nbsp;Special Characters (!@#$%^&*)</span>
                              </div>
                          </li>
                          <li className="">
                              <div className="eight-character password_details">
                              <div className='status_svg'>
                                    <div className='green_right_svg eight-character-right'>
                                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="12" height="12" fill="white"/>
                                        <path d="M1.48486 6.23454L4.33455 9.08423L10.5089 2.90991" stroke="#21BA31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                      </svg>
                                    </div>
                                    <div className='grey_dot_svg eight-character-dot'>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <circle cx="6" cy="6" r="2" fill="#808285"/>
                                        </svg>
                                    </div>
                                  </div>
                                  <span>&nbsp;Atleast 8 Characters</span>
                              </div>
                          </li>
                      </ul>
                  </div>)
                }

                {/* Remember me field */}
                {formik.values.isMember && !formik.values.isForgotPassword && (
                  <div className='remember_check d-flex justify-content-between align-items-center'>
                    {/* <FormRow
                      type='checkbox'
                      name='remember me'
                    /> */}
                    <div className="checkbox_block">
                      <input type="checkbox" name="remember" id="remember"/>
                      <label></label>
                      <span for="remember" className='remember_text'>Remember Me</span>
                      </div>
                    <div className='forgot_link'>
                      <a href='javascript:;' onClick={toggleForgotPassword}>Forgot Password</a>
                    </div>
                  </div>)}
                  
                <button type='submit' className='login_btn darkred_btn' disabled={isLoading}>
                  <span>{isLoading ? 'loading...' : formik.values.isForgotPassword ? 'Reset Password' : formik.values.isMember ? 'Login ' : 'Sign up'}</span>
                  {(isLoading === false) && (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                    <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>)}
                </button>
                  <p className='switch_login'>
                    {formik.values.isMember ? 'Don’t have any Account ' : 'Back to'}
                    &nbsp;
                    <button type='button' onClick={toggleMember} disabled={isLoading} className='member-btn' value={formik.values.isMember ? 'Sign up' : 'Login'}>
                      {formik.values.isMember ? 'Sign up' : 'Login'}
                    </button>
                  </p>
               
                  <button onClick={goToMainMenu} className='login_btn darkred_btn' >
                  Return Back to Main Menu
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {modalValues.showModal && (
        <ModalComponent
            showModal={modalValues.showModal}
            title={modalValues.title}
            content={modalValues.content}
            redirectLink={modalValues.redirectLink}
            modalCallBack={modalCallBack} 
        />
      )}
    </>
  );
}
export default Register;
