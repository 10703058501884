import React from 'react';
import { useState, useEffect } from 'react';
import { FormRowSelect } from '../../components';

const EnquiryFilterModal = (props) => {

    const [statusValue, setStatusValue] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const statuslist = ["Active","Inactive"]

    const closeMenu = () => {
      props.closeModal();
    };
    
    useEffect(() => {
        if(props.clearFilterData===true){
            setUserName('');
            setStatusValue('');
            setUserRole('');
            setUserEmail('');
            setCompanyName('');

            props.setUserName(null);
            props.setUserEmail(null);
            
            if(props.FromPage === "Users"){
              props.setUserRole(null);
            }else{
              props.statusValue(null);
              props.setCompanyName(null);
            }
        }
    }, [props.clearFilterData]);

      const applyFilter = ()=>{
        props.setUserName(userName);
        props.setUserEmail(userEmail);
        if(props.FromPage === "Users"){
          props.setUserRole(userRole);
        }else{
          props.statusValue(statusValue);
          props.setCompanyName(companyName);
        }
        closeMenu();
      }
      const handleChange = (e, name) => {
        let value = e.target.value;
        setStatusValue(value);
      }
    
    return(
        <>            
            <div className="filter_modal_content" >              
                <div className="filter_modal_head">
                    <h2>Filters</h2>
                    <a href="javascript:;" onClick={closeMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 19L19 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19 19L5 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className="filter_modal_body">
                    <form>
                        <div className="new_label">
                            <label className='form-label'>Name</label>
                            <div className='form-row'>
                            <input 
                                type="text" 
                                value={userName}
                                className="form-control" 
                                name="item_code"
                                onChange={ (e) => setUserName(e.target.value) }
                            />
                          </div>
                        </div>
                        <div className="new_label">
                            <label className='form-label'>Email</label>
                            <div className='form-row'>
                            <input 
                                type="text" 
                                value={userEmail}
                                className="form-control" 
                                name="item_code"
                                onChange={ (e) => setUserEmail(e.target.value) }
                            />
                          </div>
                        </div>
                        {props.FromPage === 'Users' && <div className="new_label">
                            <label className='form-label'>Role</label>
                            <div className='form-row'>
                            <input 
                                type="text" 
                                value={userRole}
                                className="form-control" 
                                name="item_code"
                                onChange={ (e) => setUserRole(e.target.value) }
                            />
                          </div>
                        </div>}
                        {props.FromPage === 'Customers' && <div className="new_label">
                            <label className='form-label'>Company Name</label>
                            <div className='form-row'>
                            <input 
                                type="text" 
                                value={companyName}
                                className="form-control" 
                                name="item_code"
                                onChange={ (e) => setCompanyName(e.target.value) }
                            />
                          </div>
                        </div>}
                        {props.FromPage === 'Customers' && <div className='new_label'>
                          <label>Status</label>
                            <FormRowSelect
                                name='select_status'
                                list={statuslist}
                                value={statusValue}
                                placeholder="Select Status" 
                                defaultValue={"placeholder"}
                                handleChange={(e)=>{ handleChange(e,'enquiry_send')}}
                                callFrom='enquiry_filter'
                              />
                          </div>}
                          
                        <button type='button' onClick={applyFilter} className='w-100 darkred_btn'>
                            <span>Apply Filter</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default EnquiryFilterModal;