import React from "react"; 
import overViewImg from '../assets/images/nps_series_pwr.png'

// import endSuction from '../assets/images/endSuction.png';
// import horizontalPump from '../assets/images/horizontalSplitcase.png';
// import verticalPump from '../assets/images/verticalTurbine.png';
import endSuction from '../assets/images/endSuctionSelected.jpg';
import horizontalPump from '../assets/images/horizontal-pump.png';
import verticalPump from '../assets/images/vertical-pump.png';

const OverViewPump = (props) => {
    
    function getCapital(str) {
        if(str) {
          return str.toUpperCase();
        }  
    }

    let pumpImage; 

    if(props.pumpRecord.pump_type === 'End Suction') {
      pumpImage = endSuction;
    } else if(props.pumpRecord.pump_type === 'Splitcase') {
      pumpImage = horizontalPump;
    } else if(props.pumpRecord.pump_type === 'Vertical Turbine') {
      pumpImage = verticalPump;
    }  

    return(
        <>  
            <div className="row">
              <div className="col-md-5 px_10 overview_left">
                <div className="details_block">
                  <div className="row">
                    <div className="col-md-8 iPad_col_100">
                      <div className="overview_pump_img">

                      <img src={pumpImage}  className={pumpImage == verticalPump ? 'overview_img vt_mgr_img' : 'overview_img'} alt="OverView image"/>
                          {/* { props.pumpRecord.pump_type === 'Splitcase' && <img src={horizontalPump} className="overview_img" alt="OverView image"/>}
                          { props.pumpRecord.pump_type === 'End Suction' && <img src={endSuction} className="overview_img" alt="OverView image"/>}
                          { props.pumpRecord.pump_type === 'Vertical Turbine' &&   
                          // <div className='pump_field_img'>
                              <img src={verticalPump} alt="OverView image" />
                          // </div>
                          } */}
                          
                      </div>
                    </div>
                    <div className="col-md-4 iPad_col_100">
                        <div className="row row_center">
                           {props.location && <div className="col-6 col-md-12 py-0 ipad_col_50">
                              <div className="form-row location_row">
                                <label>Location</label>
                                <p className="details_data">{props.location}</p>
                              </div>
                            </div>}
                            <div className="col-6 col-md-12 py-0 ipad_col_50">
                              <div className="form-row">
                                <label>DRIVER TYPE</label>
                                <p className="details_data">{props.pumpRecord.primemover_type}</p>
                              </div>
                            </div>
                            <div className="col-6 col-md-12 py-0 ipad_col_50">
                              <div className="form-row">
                                <label>TYPE</label>
                                <p className="details_data">{props.pumpRecord.pump_type}</p>
                              </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="row laptop_w_50">
                    <div className="col-md-4 ipad_col_50">
                      <div className="form-row">
                        <label>PRESSURE</label> 
                        <p className="details_data">{props.pumpRecord.pressure} <span className="custom_unit">{getCapital(props.pumpRecord.pressure_uom)}</span></p>
                      </div>
                    </div>
                    <div className="col-md-4 ipad_col_50">
                      <div className="form-row">
                          <label>FLOW</label>
                          <p className="details_data">{props.pumpRecord.flow} <span className="custom_unit">{props.pumpRecord.flow_uom}</span></p>
                      </div>
                    </div>
                    <div className="col-md-4 ipad_col_50 cust_min_170">
                      <div className="form-row">
                          <label>MODEL</label>
                          <p className="details_data">{props.pumpRecord.model}</p>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
              <div className="col-md-7 px_10 overview_right">
                <div className="details_block">
                  <h3 className="sub_details_head">PERFORMANCE</h3>
                  <div className="inner_details">
                      <div className="row">
                        <div className="col-md-3 ipad_col_50 custom_width">
                          <div className="form-row">
                            <label>SPEED</label>
                            <p className="details_data">@{props.pumpRecord.speed} <span className="custom_unit">RPM</span></p>
                          </div>
                        </div>
                        <div className="col-md-3 ipad_col_50 custom_width">
                          <div className="form-row">
                            <label>EFFICIENCY</label>
                            <p className="details_data">80.5 <span className="custom_unit">%</span></p>
                          </div>
                        </div>
                        <div className="col-md-3 ipad_col_50 custom_width">
                          <div className="form-row">
                            <label>STAGE</label>
                            <p className="details_data">01</p>
                          </div>
                        </div>
                          <div className="col-md-2 ipad_col_50 right_align_box custom_min_w">
                            <div className="form-row">
                              <label>IMPELLER DIA.</label>
                              <p className="details_data">289 <span className="custom_unit">MM</span></p>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div className="details_block">
                  <h3 className="sub_details_head">LIQUID CONDITIONS</h3>
                  <div className="inner_details">
                  <div className="row">
                        <div className="col-md-5 ipad_col_50">
                          <div className="form-row">
                            <label>LIQUID TYPE</label>
                            <p className="details_data">Fresh Water</p>
                          </div>
                        </div>
                        <div className="col-md-4 ipad_col_50">
                          <div className="form-row">
                            <label>SPECIFIC GRAVITY</label>
                            <p className="details_data">0.99823</p>
                          </div>
                        </div>
                          <div className="col-md-3 ipad_col_50 right_align_box">
                            <div className="form-row">
                              <label>VISCOSITY</label>
                              <p className="details_data">1.0034 <span className="custom_unit">mm²/s</span></p>
                            </div>
                          </div>
                      </div>
                  </div>                               
                </div>
                {props.pumpRecord.driver_details && <div className="details_block">
                  <h3 className="sub_details_head">DRIVER DETAILS</h3>
                  <div className="inner_details">
                  <div className="row">
                        {/* <div className="col-md-5 ipad_col_50"> */}
                          <div className="form-row">
                            <label>Driver Details</label>
                            <p className="details_data">{props.pumpRecord.driver_details}</p>
                          </div>
                        {/* </div> */}
                      </div>
                  </div>                               
                </div>}
              </div>
            </div>

        </>
    );
}
export default OverViewPump