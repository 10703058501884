import React from "react";
import { Link } from 'react-router-dom';
import endSuctionPump from '../../../assets/images/end_suction_no_bg.png';
import { BreadcrumbAndHeading, EnquiriesHead } from "../../../components";
const OrderDetail = () => {

    return (
        <>
            <div className='Add_new_pump_block order_detail_page'>
                <div className='container'>
                    <div className="order_details_head">
                        <div className='mobile_back_btn'>
                            <BreadcrumbAndHeading name="#NF5436798" preUrl="My Orders" />
                        </div>
                        <div className="cancel_order">
                            <button className="order_status_btn" style={{ backgroundColor: "#FF7D1E33" }}>
                                <span style={{ backgroundColor: "#FF7D1E" }} className="status_dot_show"></span>
                                <span className="current_status">Pending</span>
                            </button>
                            <button type='button' className='w-100 darkred_btn'>
                                <span>Cancel Order</span>
                            </button>
                        </div>
                    </div>
                    <div className="order_detail_main">
                        <h4 className="order_heading">Order Detail</h4>
                        <div className="product_delivered">
                            <div className="order_body">
                                <div className="product_compare order">
                                    <img src={endSuctionPump} />
                                    <div className='product_details_col'>
                                        <p className='pro_order'>
                                            <span className="product_name">Product Number</span>
                                            <span className="product_id">9288866</span>
                                        </p>
                                        <p className='gpm'><span>450 GPM</span> <span>2950 GPM</span></p>
                                    </div>
                                </div>
                                <div className="my_order_status">
                                    <div className="quantity">
                                        <span>Quantity</span>
                                        <p>2</p>
                                    </div>
                                </div>
                            </div>
                            <div className="delivered_to">
                                <h4>Delivered To</h4>
                                <h5><strong>Joyal Bennison</strong></h5>
                                <p className="address">2118 Thornridge Cir. Syracuse, Connecticut 35624</p>
                                <p>Phone: <span> (702) 555-0122</span></p>
                                <p>Email: <span>david.johnson@example.com</span></p>
                            </div>
                        </div>
                    </div>
                    <div>
                    <div className="sales_track_status order_status_progress">
                                <div className="">
                                    <h3 className="sub_details_head">Order Status</h3>
                                    <div className="steps_flow track_flow">
                                        <ul className="progress_bar">
                                        <li className="active">
                                            <div className="step_data">
                                            <p className="step_info">Ordered</p>
                                            <p className="step_date">Feb 23, 2024</p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        <li  className="upcoming">
                                            <div className="step_data">
                                                <p className="step_info">Shipped</p>
                                                <p className="step_date">Feb 23, 2024</p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        <li  className="">
                                            <div className="step_data">
                                                <p className="step_info">Out for Delivery</p>
                                                <p className="step_date">Feb 23, 2024</p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        <li  className="">
                                            <div className="step_data">
                                                <p className="step_info">Delivered</p>
                                                <p className="step_date">Feb 23, 2024</p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OrderDetail