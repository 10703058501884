import React from 'react';
import { DataTableField, EnquiriesHead } from '../../../components';

const SalesOrderList = () => {
    return (
      <>
      <div className='container'>
        <div className='sales_main'>
          <EnquiriesHead heading="Sales Order list view" classSHows="itemsShows"/>
          <DataTableField />
        </div>
      </div>
      </>
    )
  }
  
export default SalesOrderList