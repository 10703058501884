// import ReactDOM from "react-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import React, { useState, useEffect, useRef } from "react";
// import { classNames } from "primereact/utils";
import customFetch from "../utils/axios";
import { Paginator } from 'primereact/paginator';
import { useNavigate, Link} from 'react-router-dom';
import StockAvailability from "../pages/admin/stockPump/StockAvailability";
import ToastMessage from '../components/ToastMessage';

export default function ManageStockDataTableField({addOrEditMode, dataList, callBackList, changePage, pagelimit}) {
  let emptyProduct = {
    id: null,
    name: "",
    status: null,
    companyid: "",
    email: null,
    contact: 0,
    action: 0,
  };

  /**hooks */
  const toast = useRef(null);
  const dt = useRef(null);

  /**states */
  const [products, setProducts] = useState(dataList.data);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);  
  const [first, setFirst] = useState(0);

  const [show, setShow] = useState(false);
  const [pumpID, setPumpID] = useState();
  const [isLogout, setIsLogout] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [is_visibility_Checked, setVisibilityChecked] = useState(false);
  const navigate = useNavigate();

    const model = (Data) =>{
      let pumpID = Data.id; 
      return (
        <div className="newUser" style={{ textTransform: 'capitalize'}}>
          <Link to={'/view-pump-results/'+pumpID} >{Data.model}</Link>

          </div> 
      );
    } 
    const stockavail = (Data) =>{
      
      let qty_avl = 0;
      Data.stockavail.forEach(function(item, index) {
          qty_avl = parseInt(qty_avl) + parseInt(item.qty_avl);
      });

      
      return (
        <div className={`code_${Data.code}`}>{qty_avl} </div>
      );
    } 
    const dutyPoint = (Data) =>{
      return (
        <div style={{ textTransform: 'capitalize'}}>{Data.flow} {Data.flow_uom} @ {Data.pressure} {Data.pressure_uom}</div>
      );
    } 
    const statusUpdate = (rowData) => {
      const dotColor = rowData.status === 'active' ? '#0BDA6C' : '#F57E20';

      return (
        <div className="status_shows">
          <svg width="8" height="8" style={{ marginRight: '6px' }}>
            <circle cx="4" cy="4" r="4" fill={dotColor} />
          </svg>
          <div  style={{ textTransform: 'capitalize'}}> {rowData.status}</div>
        </div>
      );
    };

   
    const handleTdClick = (rowDataMy) => {
      setSelectedItem(rowDataMy);
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownClick = (option) => {
      setIsDropdownOpen(false);
    };
    const items =(data) => ([
      {
        label: "View",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
        command: async (event) => {
          let pumpID = data.id; 
          navigate('/view-pump-results/'+pumpID);  
        },
      },
      {
        label: "Stock Availability",
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.6416 6.2002L9.99992 10.4585L17.3082 6.22517" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M10 18.0085V10.4502" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.27501 2.06699L3.82502 4.54202C2.81668 5.10035 1.9917 6.50034 1.9917 7.65034V12.3587C1.9917 13.5087 2.81668 14.9087 3.82502 15.467L8.27501 17.942C9.22501 18.467 10.7833 18.467 11.7333 17.942L16.1834 15.467C17.1917 14.9087 18.0167 13.5087 18.0167 12.3587V7.65034C18.0167 6.50034 17.1917 5.10035 16.1834 4.54202L11.7333 2.06699C10.775 1.53366 9.22501 1.53366 8.27501 2.06699Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M14.1666 11.0337V7.98369L6.2583 3.41699" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg>,
        command: () => {
          //alert('Stock Availability')
          setShow(true)
          //alert(showStockAvailability);
          setPumpID(data.code)
        },
      },
      {
        label: "Edit",
        icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.0529 4.28273L4.21122 11.5244C3.95289 11.7994 3.70289 12.3411 3.65289 12.7161L3.34455 15.4161C3.23622 16.3911 3.93622 17.0577 4.90289 16.8911L7.58622 16.4327C7.96122 16.3661 8.48622 16.0911 8.74455 15.8077L15.5862 8.56607C16.7696 7.31607 17.3029 5.89107 15.4612 4.1494C13.6279 2.4244 12.2362 3.03273 11.0529 4.28273Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> <path d="M9.91113 5.49072C10.2695 7.79072 12.1361 9.54906 14.4528 9.78239" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
        command: async (event) => {
          
          let pumpID = data.id; 

          navigate('/edit-stock-pump/'+pumpID);  
        },
      },
      // {
      //   label: "Delete",
      //   icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M17.5 4.98356C14.725 4.70856 11.9333 4.56689 9.15 4.56689C7.5 4.56689 5.85 4.65023 4.2 4.81689L2.5 4.98356" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.0835 4.1415L7.26683 3.04984C7.40016 2.25817 7.50016 1.6665 8.9085 1.6665H11.0918C12.5002 1.6665 12.6085 2.2915 12.7335 3.05817L12.9168 4.1415" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M15.7082 7.6167L15.1665 16.0084C15.0748 17.3167 14.9998 18.3334 12.6748 18.3334H7.32484C4.99984 18.3334 4.92484 17.3167 4.83317 16.0084L4.2915 7.6167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M8.6084 13.75H11.3834" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> <path d="M7.9165 10.4165H12.0832" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/> </svg> ,
      //   command: () => {
      //     //alert('Delete')
      //   },
      // },
    ]);
    const dotsShows = (rowDataMy) => 
    {
      return (
        <SplitButton
          label="Edit"
          icon="pi pi-pencil"
          model={items(rowDataMy)}
        />

      )
    }   
    const visibleBtn = (Data) => {
      let isChecked = is_visibility_Checked;
      setVisibilityChecked(false);
      
      if( Data.is_visible === 1){
        isChecked = true;
      }
      else{
        isChecked = false;
      }

      return (
          <div className="visible_toggle">
            <div className="toggle_checkbox">
              {
              <input
                  onChange={(e) => setInputValue(Data)}
                  type="checkbox"
                  // value="1"
                  // id="check-2"
                  checked={isChecked}
              />
              }
              
              <label htmlFor="check-2"></label>
            </div>
          </div>
      );
    };

    const onPageChange = (event) => {
      setFirst(event.first);
      let page = event.page + 1;
      changePage(page);
    };

    const onCloseCallBack = (message=null) => {
      setShow(false)
    }
    
    const setInputValue = async(data)=>{
      try{
        setApiSuccessMessage('');
        const response = await customFetch.get('update-visibility/'+data.id);
        if(response.data.status === true){
          setApiSuccessMessage(response.data.message);
          if(data.is_visible == 1){
            setVisibilityChecked(true);
          }
          else{
            setVisibilityChecked(false);
          }
          callBackList();
        }
        window.scrollTo(0,0);
      }catch(err){
        if (err.response.status === 401) {
          setIsLogout(true)
        }
      }
      
    }
   
  return (
    <>
      
        <div className="custom_dataTable">
          {
            (show) && (<StockAvailability pumpID={pumpID} onCloseCallBack={onCloseCallBack} />
            )
          }
          {
            (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
          }

          
          <DataTable
            ref={dt}
            value={dataList.data}
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            dataKey="id"
            //paginator rows={pagelimit}
            //paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {PageLinks} {NextPageLink} {LastPageLink} {CurrentPageReport}"
          >
            <Column field="model" header="model" body={model}  sortable></Column>
            <Column field="code" header="Code"   sortable></Column>
            <Column field="item_code" header="Item Code"   sortable></Column>
            <Column field="is_visible" header="visibility" body={visibleBtn} ></Column>
            <Column header="duty point" field="dutyPoint"  body={dutyPoint}  />
            <Column field="pump_type" header="type" sortable></Column> 
            <Column field="stockAvailability" header="stock availability" body={stockavail} sortable></Column>
            <Column field="primemover_type" header="Driver Type" sortable  style={{ textAlign: 'left', width: '100px', minWidth: '100px'}}></Column>
            {/* <Column body={dotsShows} field="action" header="Action" sortable className="dots_col" style={{ textAlign: 'left', width: '100px', minWidth: '100px'}}></Column> */}
            
          </DataTable>      
        </div>

        {
          (dataList.total>0) && <div className="card">
          <Paginator first={first} rows={pagelimit} totalRecords={dataList.total}  onPageChange={onPageChange} />
          </div>
        }
      
    </>
  );
}