import React, { useState, useEffect, useRef} from 'react';
import { Modal, Button } from 'react-bootstrap';
import customFetch from "../../../utils/axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useForm } from "react-hook-form";
import ShowError from '../../../components/ShowError';
import ToastMessage from '../../../components/ToastMessage';

const StockAvailability = ({pumpID, onCloseCallBack}) => {

    const { register, handleSubmit, formState: { errors }, setError, clearErrors, resetField } = useForm();

    //const handleClose = () => setShow(false);
    const [show, setShow] = useState(true);
    const [model, setModel] = useState('');
    const [dutyPoint, setDutyPoint] = useState('');
    const [pumpType, setPumpType] = useState('');
    const [avilList, setAvilList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [showForm, setShowForm] = useState('d-none');
    const [showButton, setShowButton] = useState('');

    const [stock, setStock] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [location, setLocation] = useState('Afghanistan');
    const [apiSuccessMessage, setApiSuccessMessage] = useState('');
    

    const dt = useRef(null);
    
    


    
    const handleClose = () => 
    {
        onCloseCallBack();
       // setShow(false);
    }

    useEffect(() => {
        showStockAvailability() 

    },[pumpID]);

    const showStockAvailability = async () => {
        
        // Create FormData object
        const formData = new FormData();

        formData.append('pump_code', pumpID);
        

        try {
            const response = await customFetch.post('/get-stock-availability',formData);
            const { status, message, error, result } = response.data;
            if (status === true) {
                let result = response.data.data;
                setModel(result.model);
                setDutyPoint(result.flow+' '+result.flow_uom+' @ '+result.pressure+' '+result.pressure_uom);
                setPumpType(result.pump_type);
                setAvilList(result.stockavail)

                setLocationData(response.data.location_list); 

                document.getElementsByClassName('code_'+result.code)[0].innerHTML = response.data.total_qty;

            } 
        } catch (err) {
            
        }
    }

    const handleForm = () => {
        setShowForm('');
        setShowButton('d-none'); 
    }

    const onSubmit = async (data) => {
        
        
        
        const formData = new FormData();
        
        if(data.location == '') {
            data.location = 'Afghanistan';
        }

        formData.append("pump_code", pumpID);
        formData.append("location", data.location);
        formData.append("stock", data.stock);

        try {
            const response = await customFetch.post('/add-stock-availability',formData);
            const { status, message, error, result } = response.data;
            if (status === true) {
                showStockAvailability();
                setApiSuccessMessage(message);
                resetField('location');
                resetField('stock');
                setShowForm('d-none');
                setShowButton(''); 
            } else {
                //setIsLoading(false);
            }
        } catch (err) {
            // if (err.response.status === 401) {
            //   setIsLogout(true)
            // }
            //setIsLoading(false);
            //setErrorMessage('There is something wrong. Please try again');
            
        }

    }

    return (
      <>
        <Modal show={show} onHide={handleClose} className='user_popup_modal'>
            <Modal.Header closeButton>
            <Modal.Title>Stock Availability</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} /> ) 
                }
                <br/>
                <div className="row">
                    <div className="col-md-4 w_991_full px_10">
                        <div className="form-row">
                            <label>Model</label>
                            <div className="stock_label_value">{model}</div> 
                        </div>
                    </div>

                    <div className="col-md-4 w_991_full px_10">
                        <div className="form-row">
                            <label>Duty Point</label>
                            <div className="stock_label_value">{dutyPoint}</div> 
                        </div>
                    </div>

                    <div className="col-md-4 w_991_full px_10">
                        <div className="form-row">
                            <label>Type</label>
                            <div className="stock_label_value">{pumpType}</div>
                            
                        </div>
                    </div>
                </div>
                <div className="custom_dataTable stock_table">
                    <DataTable
                        ref={dt}
                        value={avilList}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                    >
                        <Column field="location" header="Location" sortable></Column>
                        <Column field="qty_avl" header="Available Stock" sortable></Column>
                        
                    </DataTable>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className={`darkred_btn ${showButton}`} onClick={handleForm} variant="primary" >
                    Add Stock 
                </Button>
                <div className={showForm}>
                    <form onSubmit={handleSubmit(onSubmit)} >
                        <div className="row">

                            <div className="col-md-4 w_991_full px_10">
                                <div className="form-row">
                                    <label>Location <span className="error_text">*</span></label>

                                    <select 
                                        name="location"
                                        onChange={ (e) => setLocation(e.target.value) }
                                        {...register("location",{ required: false } ) } 
                                        className="form-select"
                                    >
                                    { locationData && locationData.map((item, index) => (
                                        <option value={item.countryname}>{item.countryname}</option>
                                    ))}       
                                    </select>
                                    <ShowError errorsModal={errors.location} />    

                                </div>
                            </div>

                            <div className="col-md-4 w_991_full px_10">
                                <div className="form-row">
                                    <label>Stock <span className="error_text">*</span></label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="stock"

                                        onChange={ (e) => setStock(e.target.value) }
                                        {...register("stock",{ 
                                            required: 'Please enter stock.',
                                            pattern: {
                                                value: /^[0-9]+$/i,
                                                message: 'Please enter valid stock.'
                                            }
                                        } ) }
                                    />
                                    <ShowError errorsModal={errors.stock} />
                                </div>
                            </div>

                            <div className="col-md-4 w_991_full px_10">
                                <label style={{marginBottom: "4px"}}>&nbsp;</label>
                                <button className='transparent_btn' type="submit">
                                        <span>Save</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                </button>
                            </div>

                        </div>

                    </form>
                </div>

            </Modal.Footer>
        </Modal>
      </>
    )
  }
  
export default StockAvailability