import customFetch, { clientCustomFetch } from '../../utils/axios';
import clearValues from './pumpSelectionSlice';

export const createSearchCriteriaThunk = async (selectPump, thunkAPI) => {
  try {
    const resp = await clientCustomFetch.post('/getSelection.html', selectPump.data);
    //thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
};
export const getSubmittalThunk = async (selectPump, thunkAPI) => {
  try {
    const resp = await clientCustomFetch.post('/getSubmittal.html', selectPump.data);
    //thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
};


export const postEnquiryThunk = async (selectPump, thunkAPI) => {

  try {
    const resp = await customFetch.post('/post-enquiry', selectPump.data);
    //thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
};