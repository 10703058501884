import React from "react";
import { Dropdown } from 'primereact/dropdown';

const FormRowSelect = ({ labelText, name, value, handleChange, list, callFrom }) => {
  //alert(name)
  //console.log(value)
  const selectedCountryTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.country}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    var element = document.querySelector(".p-dropdown-panel");
    if(element !== null) {
      if(callFrom === 'search_enquiry')
        element.classList.add("country_select_dropdown");
      else if(callFrom === 'enquiry_modal')
        element.classList.add("contact_select_dropdown");
      else if(callFrom === 'enquiry_filter')
        element.classList.add("filter_country_select_dropdown");
    }
    return (
        <div className="flex align-items-center">
            <div>{option.country}</div>
        </div>
    );
  };

  const selectedCountryPhoneCodeTemplate = (option, props) => {
    if (option) {
        return (
            <div className="flex align-items-center">
                <div>{option.phonecode}</div>
            </div>
        );
    }
    return <span>{props.placeholder}</span>;
  };
  const countryOptionPhoneCodeTemplate = (option) => {
    var element = document.querySelector(".p-dropdown-panel");
    if(element !== null) {
      element.classList.add("contact_select_dropdown");
    }
    return (
        <div className="flex align-items-center">
            <div>{option.phonecode}</div>
        </div>
    );
  };

  const placeholder=(name)=>{
    if(name === 'filter_countryCode' || name === 'countryCode'){
      return 'Select Country';
    }else if(name === 'location'){
      return 'Select Location';
    }else{
      return 'Select Code';
    }

  }
  return (
    <div className='form-row'>
      <label htmlFor={name} className='form-label'>
        {(name === 'country_phone_code') ? '' : labelText || name}
      </label>
      {
          (name === 'countryCode' || name === 'location' || name === 'country_phone_code' || name === 'filter_countryCode') && (
          <Dropdown 
            name={name}
            value={value}
            onChange={handleChange}
            options={list} 
            optionLabel="country" 
            // placeholder={((name === 'filter_countryCode') ? 'Select Country' : 'Select Code') || ((name === 'location') ? 'Select Location' : 'Select Code')}
            placeholder={placeholder(name)}
            filter
            filterBy={(name === 'country_phone_code') ? 'phonecode' : 'country'}
            valueTemplate={(name === 'country_phone_code') ? selectedCountryPhoneCodeTemplate : selectedCountryTemplate} 
            itemTemplate={(name === 'country_phone_code') ? countryOptionPhoneCodeTemplate : countryOptionTemplate} 
            className="w-full md:w-14rem" />)
          }

          {
            (name !== 'countryCode'  && name !== 'location' && name !== 'country_phone_code' && name !== 'filter_countryCode') && (<select
              name={name}
              id={name}
              value={value}
              onChange={handleChange}
              className='form-select'
            >
              {
                (name === 'enquiry_send') && (<option value="">
                  Select Enquiry Send
                </option>)
              }
               {
                (name === 'select_status') && (<option value="">
                  Select Status
                </option>)
              }
              {
                (name === 'visibility'|| name === 'dutyPoint' || name== 'PumpType') && (<option value="">
                  All
                </option>)
              }
              {
                (name === 'driverType' ) && (<option value="">
                  Both
                </option>)
              }

              {
                (name === 'location1') && (<option value="">
                  Select Location
                </option>)
              }
              {
                 (list.map((itemValue, index) => {
                 
                return (
                  
                  <option key={index} value={itemValue}>
                    {(name === 'Driver Type' || name === 'enquiry_send' || name === 'dutyPoint') ? itemValue : itemValue.toUpperCase()}
                  </option>
                );
              }))
              }
            </select>)
          }
      
    </div>
  );
};
export default FormRowSelect;
