import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import pumpImg from '../assets/images/pumpImg.png';
import EndSuction_FirePump from '../assets/images/EndSuction_FirePump.jpg';
import VerticalTurbine_FirePump from '../assets/images/Vertical-Turbine_Fire-Pump.jpg';
import Splitcase_FirePump from '../assets/images/Splitcase_Fire-Pump.jpg';
import desktopPump from '../assets/images/desktopPump.png';
import pumpEnqImg from '../assets/images/nps_series.png';
import loaderImage from '../../src/assets/images/loader.gif';
import GraphComponent from './GraphComponent';

function GraphModal({graphAPIData, callbackFromGraph, selectionCriteria}) {
    // console.log(graphAPIData.selected_pump_type)
    //alert(JSON.stringify(graphAPIData.graph.points))
    const [show, setShow] = useState(true);

    const handleClose = () => {
        callbackFromGraph();
        setShow(true);
    }
    
    const contextMenu = (e) => {
        e.preventDefault();
        console.log(e);
        return false;
        //return true;
    };
    const [loading, setLoading] = useState(false);
    const imageLoaded = () => {
        //alert(1)
        setLoading(false);
    }
    useEffect(() => {
        if (loading) {
          document.body.classList.add('loader_view_listing');
        } else {
          document.body.classList.remove('loader_view_listing');
        }
        return () => {
          document.body.classList.remove('loader_view_listing');
        };
      }, [loading]);
    return (
        <>
            <div className="edit_customer_modal" >

                <Modal show={show} onHide={handleClose} className="graph_modal" backdrop="static">
                    {
                        (loading) && (<div  onContextMenu={contextMenu}>
                            <div className='show_loader'>  
                                <img src={loaderImage} /> 
                            </div>
                        </div>)
                    }
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>{(graphAPIData.pumpModel) ? graphAPIData.pumpModel : ''} : Performance Curve</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* send enquiry head details start */}
                        <div className="send_enquiry_head d-none">
                            <div className="send_enquiry_row">
                                <div className="enquiries_img_box">
                                    
                                    <img src={pumpEnqImg} className="enquiry_img" alt="pump"/>
                                </div>
                                <div className="send_enquiry_details">
                                    <div className="send_enquiry_head_box">
                                        <h3 className="send_enquiry_heading">NF-S-120-260</h3>
                                    </div>
                                    <div className="enq_data_main">
                                        <div className="enq_data_block">
                                            <span className="enq_label">PRESSURE</span>
                                            <span className="enq_data">10 BAR</span>
                                        </div>
                                        <div className="enq_data_block">
                                            <span className="enq_label">FLOW</span>
                                            <span className="enq_data">1000 USGPM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* send enquiry head details end style={{ width: 790 }} style={{ width: 850  }}*/}

                        
                        <div className="graph_img" >
                            <div onContextMenu={contextMenu}>
                                <img 
                                className="enquiry_img" alt="pump"
                                src={graphAPIData.graphURl}
                                onLoad={imageLoaded}/>
                            </div>

                           
                            {/* {
                                (graphAPIData.graph.points.length > 0) && (<GraphComponent mode="power" graphData={graphAPIData.graphNew}/>)
                            }
                            {
                                (graphAPIData.graph.points.length > 0) && (<GraphComponent mode="head" graphData={graphAPIData.graphNew}/>)
                            } */}
                        </div>
                            
                            
                        <div className="selection_bar">
                            <h2 className="selection_bar_heading">Selection Duty: {selectionCriteria.flow} {selectionCriteria.flowUOM} | {selectionCriteria.head} {selectionCriteria.headUOM}</h2>
                        </div>
                        <div className="operator_graph">
                            <div className="details_block">
                                <div className="inner_details">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="pump_img">
                                                {(graphAPIData.selected_pump_type == 'End Suction') &&  <img src={EndSuction_FirePump} className="pumpImg d-block mx-auto" alt="Pump image"/>}
                                                {(graphAPIData.selected_pump_type == 'Split case') &&  <img src={Splitcase_FirePump} className="pumpImg d-block mx-auto" alt="Pump image"/>}
                                                {(graphAPIData.selected_pump_type == 'Vertical Turbine') &&  <img src={VerticalTurbine_FirePump} className="pumpImg d-block mx-auto" alt="Pump image"/>}
                                           
                                            {/* <img src={EndSuction_FirePump} className="Desktop_pumpImg d-none d-md-block" alt="Pump image"/> */}
                                            </div>
                                        </div>
                                        <div className="col-md-9 operator_box">
                                            <h3 className="sub_details_head">Operating Conditions</h3>
                                            <div className="row">
                                                <div className="col-6 col-md-4 sm-px-5">
                                                    <div className="form-row">
                                                        <label>FLUID</label>
                                                        <p className="details_data">{(graphAPIData.dp_fluid_name) ? graphAPIData.dp_fluid_name : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4 sm-px-5">
                                                    <div className="form-row">
                                                        <label>SPECIFIC GRAVITY</label>
                                                        <p className="details_data">{(graphAPIData.dp_spgr) ? graphAPIData.dp_spgr : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4 sm-px-5">
                                                    <div className="form-row">
                                                        <label>SPEED</label>
                                                        <p className="details_data">{(graphAPIData.speed) ? graphAPIData.speed : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4 sm-px-5">
                                                    <div className="form-row">
                                                        <label>PUMP TEST STD</label>
                                                        <p className="details_data">{(graphAPIData.dp_testing_standard) ? graphAPIData.dp_testing_standard : 'N/A'} </p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4 sm-px-5">
                                                    <div className="form-row">
                                                        <label>SUCTION PRESSURE</label>
                                                        <p className="details_data">{(graphAPIData.suctionPressure) ? graphAPIData.suctionPressure : 'N/A'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="details_block">
                                <div className="inner_details">
                                    <h3 className="sub_details_head">Pump Performance Data</h3>
                                            <div className="row">
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>SHUT-OFF PRESSURE</label>
                                                        <p className="details_data">{(graphAPIData.shutOfPressure) ? graphAPIData.shutOfPressure : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>STAGE</label>
                                                        <p className="details_data">{(graphAPIData.stage) ? graphAPIData.stage : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>IMPELLER DIA (APPROX)</label>
                                                        <p className="details_data">{(graphAPIData.trimDia) ? graphAPIData.trimDia : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>DUTY EFFICIENCY</label>
                                                        <p className="details_data">{(graphAPIData.efficiencyDp) ? graphAPIData.efficiencyDp : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>DUTY POWER</label>
                                                        <p className="details_data">{(graphAPIData.ratedPower) ? graphAPIData.ratedPower : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>PEAK EFFICIENCY</label>
                                                        <p className="details_data">{(graphAPIData.peakEff) ? parseFloat(graphAPIData.peakEff).toFixed(2) : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>POWER AT 150%</label>
                                                        <p className="details_data">{(graphAPIData.power150) ? graphAPIData.power150 : 'N/A'}</p>
                                                        
                                                    </div>
                                                </div>                                             
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>PRESSURE AT 150%</label>
                                                        <p className="details_data">{(graphAPIData.pressureAt150) ? graphAPIData.pressureAt150 : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>POLE</label>
                                                        <p className="details_data">{(graphAPIData.pole) ? graphAPIData.pole : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>DUTY Flow</label>
                                                        <p className="details_data">{(graphAPIData.dpFlowPerPump) ? graphAPIData.dpFlowPerPump : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>DUTY HEAD</label>
                                                        <p className="details_data">{(graphAPIData.dpHeadPerPump) ? graphAPIData.dpHeadPerPump : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>CERT. FLOW</label>
                                                        <p className="details_data">{(graphAPIData.pumpcertifiedFlow) ? graphAPIData.pumpcertifiedFlow : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>CERT. HEAD</label>
                                                        <p className="details_data">{(graphAPIData.pumpcertifiedHead) ? graphAPIData.pumpcertifiedHead : 'N/A'}</p>
                                                    </div>
                                                </div>
                                                {/* <div className="col-6 col-md-3 sm-px-5">
                                                    <div className="form-row">
                                                        <label>SOH</label>
                                                        <p className="details_data">11.96</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default GraphModal;
