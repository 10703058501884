import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import emailicon from '../assets/images/emailicon.gif';

const ModalComponent = ({showModal, title, content, redirectLink, modalCallBack}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(showModal);
  useEffect(() => {
    if (showModal) {
      showModalCall();
    }
   
  }, []);

  const showModalCall = () => {
    setIsOpen(true);
  };

  const hideModalCall = () => {
    setIsOpen(false);
  };
  const handleRedirect = () => {
    
    navigate(redirectLink);
  };
  
  return (
    <>
      <Modal className="check-email-m" show={isOpen} onHide={hideModalCall} backdrop="static">
        <Modal.Body className="p-0">
          <div className="email-popup-modal">
            <div className="modal-content">
              
              {/* <div className="modal-header email-popup-logo"></div> */}
              <div className="modal-body email-popup-body">
              <img width="100" src={emailicon} alt="img" />
                <h2 className="email-popup-title" >{title}</h2>
                <div className="email-content">
                  {content}
                </div>
                <button onClick={() => {hideModalCall();modalCallBack();handleRedirect()}} type="button" className="btn btn-primary darkred_btn" data-bs-dismiss="modal">OK</button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalComponent;