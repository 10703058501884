import React from 'react';
import {BreadcrumbAndHeading, ShowOrderDetail} from '../../../components';

const SalesOrderListDetail = () => {
    return (
      <>
      <div className='container'>
        <div className='sales_main'>
          <BreadcrumbAndHeading name="NF-S-150-280" classShows="UploadOfferShow" preUrl="Fire Pump Selection"/>
          <div className='order_track'>
            <ShowOrderDetail />
          </div>
        </div>
      </div>
      </>
    )
  }
  
export default SalesOrderListDetail