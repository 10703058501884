import React, {useEffect,useState} from "react";
import {BreadcrumbAndHeading,ShowDetailsBlock, ShowDetailsActivity} from '../../../components';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { useNavigate, useLocation } from "react-router-dom";
import customFetch from "../../../utils/axios";
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";


const EnquiriesDetail = () => {
  const secretKey = 'NAFFCO2023@GALAXY';
  const search = useLocation().search;
  const rowId = new URLSearchParams(search).get('r');
  const navigate = useNavigate();
  // let requestRow = [];

  const [enquirydata, setEnquiryList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [enquiryId, setEnquiryId] = useState('');
  const [isLogout, setIsLogout] = useState(false);
  const [enquiryName, setEnquiryName] = useState('');


  useEffect(() => {
    if (rowId === null) {
      navigate('/enquires-list');
    } else {
        let rowIdDecrypt = AES.decrypt(rowId, secretKey);
        if (rowIdDecrypt == "") {
            navigate('/enquires-list');
        }
        else {
            const requestRowId = JSON.parse(rowIdDecrypt.toString(Utf8));
            setEnquiryId(requestRowId)
            fetchEnquiry(requestRowId);
            
        }
      }
    }, [rowId]);

    const fetchEnquiry = async (requestRowId) => {
      setErrorMessage('');
      
      try {
          const response = await customFetch.get(`/enquiry_details/`+requestRowId);
          // setEnquiryList(response.data.enquirylist);
          const enquirydata = response.data.enquirylist;
          const enquirylist = response.data.enquirylist;
          let enquiryFor = 'N/A';
          let enquiry_count = 0;
          let status = 'N/A';
          let enquiryToolTip = [];

          let enquiryNames = (enquirydata[0]['enquiry_number']) ? enquirydata[0]['enquiry_number'] : enquirydata[0]['name'];

          setEnquiryName(enquiryNames)


          if(enquirydata[0]['enquiry_type'] == 'cart') {
            enquirydata[0]['enquiry_title'] = 'Stock pump Enquiry';
          } else {
            enquirydata[0]['enquiry_title'] = 'Fire Pump Enquiry';
          }

          enquirydata.map((item,index)=>{
            if(item.enquiry_details.length>0){
              enquiryFor = item.enquiry_details[0].pump_model;
              enquiry_count = item.enquiry_details.length;
                item.enquiry_details.map((row,index)=>{
                  if(index>0){
                    enquiryToolTip.push(row.pump_model);
                  }
                })

              
            }
            if(item.status_master){
              status = item.status_master.name;
              enquirylist[index]['status'] = status;
            }
            enquirylist[index]['enquiryFor'] = enquiryFor;
            enquirylist[index]['enquiry_count'] = enquiry_count;
            enquirylist[index]['enquiryToolTip'] = enquiryToolTip;
            enquiryToolTip = [];
          })
          setEnquiryList(enquirylist[0]);

      } catch (err) {
        if (err.response.status === 401) {
          setIsLogout(true)
        }
          
          setErrorMessage('There is something wrong. Please try again');
          window.scrollTo(0,0);
      }
    };

    return (
      <>
      <div className="container">
        <div className="enquiries_details">
            <BreadcrumbAndHeading name={enquiryName} preUrl={enquirydata.enquiry_title} url="/enquires-list" enquiryType={enquirydata.enquiry_type} />
            <div className="row enquiries_main_row">
              <div className="col-md-9 enquiries_left_box">
                <ShowDetailsBlock enquirydata={enquirydata} />
              </div>
              {
                (enquirydata.enquiry_status_master_id) && (<div className="col-md-3 enquiries_right_box">
                <ShowDetailsActivity enquirydata={enquirydata}  /></div>)
              }
              
            </div>
        </div>   
      </div>
      {
          (isLogout) && (<HandleUnauthorization />)
        }
    </>
  );
  }
  
export default EnquiriesDetail