import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { FormRow } from '.';
import customFetch from "../utils/axios";

function PopupModalStockAvaiDelete({rowdata,onCloseCallBack}) {
    const [show, setShow] = useState(true);
    const handleClose = () => 
    {
        onCloseCallBack();
        setShow(false);
    }
    const handleShow = () => {
        onCloseCallBack();
        setShow(true);
    }
    
    const deleteAction = async () => {
        try {
            const response = await customFetch.get(`/delete-stock-availability/`+rowdata);
            const { status, message} = response.data;
            onCloseCallBack(message);
            setShow(false);
        } catch (err) {
            // console.log(err);
            console.log('There is something wrong. Please try again');
        }
    };
    return (
        <>
            <div className="edit_customer_modal">

                <Modal show={show} onHide={handleClose} centered className="delete_popup_modal" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Delete Stock Availability</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="confirm_msg">Are you sure you want to delete this?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="outline_btn" onClick={deleteAction}>
                        Yes, Delete
                        </Button>
                        <Button variant="primary" className="darkred_btn" onClick={handleClose}>
                        No
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default PopupModalStockAvaiDelete;
