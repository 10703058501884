import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { FormRow } from '.';
import customFetch from "../utils/axios";


function PopupModalTransfer({onCloseCallBack}) {
    
    const navigate = useNavigate();
    
    const [show, setShow] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = () => {
        onCloseCallBack();
        setShow(false);
    }
    
    const transferRecord = async () => {
        try {
            const response = await customFetch.get(`/transer-stock`);
            if(response.data.status === true) {
                navigate('/stock-pump-master');
            }
        } catch (err) {
            setErrorMessage('There is something wrong. Please try again');
            window.scrollTo(0,0);
        }
    };
    return (
        <>
            <div className="edit_customer_modal">

                <Modal show={show} onHide={handleClose} centered className="delete_popup_modal" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Transfer Stock Pumps</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="confirm_msg">Are you sure you want to transfer record?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="outline_btn" onClick={transferRecord}>Yes</Button>
                        <Button variant="primary" className="darkred_btn" onClick={handleClose}>No</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default PopupModalTransfer;
