// import { useState } from "react";
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import annotationPlugin from "chartjs-plugin-annotation";
import triangleImage from '../assets/images/png-transparent-shape-equilateral-triangle-green-equilateral-polygon-baby-card-angle-leaf-triangle.png';
import verticalLine from '../assets/images/Vertical-line.png';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  // annotationPlugin
);

const GraphComponent = ({ graphData, mode }) => {
  // console.log(JSON.parse(JSON.stringify(graphData.points[0]['p'])));
  console.log(graphData);

  const labels= graphData.points[0].q
    const img = new Image();
    img.src = verticalLine;
    
    const img1 = new Image();
    img1.src = triangleImage;

    const Chart1Data = {
      labels,
      datasets: [
        {
          label: null, //Using these null labels to balance the scale width as below graph
          data: [{x: graphData.minRangeQ, y: graphData.minRangeH}],
          hidden:true,
        },
        {
          label: null,
          data: [{x: graphData.maxRangeQ, y: graphData.maxRangeH}],   
          pointRadius:0,  
          pointHoverRadius:0,   
        },
        {
          label: null,
          data: [{x: graphData.dpQ, y: graphData.dpH}],         
          hidden:true,
        },
        {
          label: graphData.qAxis,
          data:graphData.points[0].q,
       
          borderColor: 'rgb(255, 255, 0)',
          backgroundColor: 'rgb(255, 255, 0)',
          yAxisID: 'x',
          pointRadius:0,  
          pointHoverRadius:0, 
        },
        {
          label: graphData.pAxis,
          data:graphData.points[2].p,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          yAxisID: 'y',
          pointRadius:0,  
          pointHoverRadius:0, 
        },

      ],
    };

  const Chart2Data = {
    labels,
    datasets: [
      {
        label: 'line1',
        data: [{ x: graphData.minRangeQ, y: graphData.minRangeH}],
        pointStyle: img, // Set marker shape ('circle', 'triangle', 'rect', etc.)
      },
      {
        label: 'line2',
        data: [{ x: graphData.maxRangeQ, y: graphData.maxRangeH }],
        pointStyle: img, // Set marker shape ('circle', 'triangle', 'rect', etc.)
        
      },
      {
        label: 'triangle',
        data: [{ x: graphData.dpQ, y: graphData.dpH }],
        // pointStyle: img1, // Set marker shape ('circle', 'triangle', 'rect', etc.)
        pointStyle: 'triangle',
        pointHoverRadius: 20,
        pointRadius: 20,
        // pointHoverBackgroundColor: 'rgb(255, 99, 132)',
        pointHoverBorderWidth: 2,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',       
      },
      {
        label: graphData.qAxis,
        data:graphData.points[0].q,
        borderColor: 'rgb(255, 255, 0)',
        backgroundColor: 'rgb(255, 255, 0)',
        yAxisID: 'x',
        pointRadius:0,  
        pointHoverRadius:0, 
      },
      {
        label: graphData.hAxis,
        data:graphData.points[1].h,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132)',
        yAxisID: 'y',
        pointRadius:0,  
        pointHoverRadius:0, 
      },
      {
        label: graphData.eAxis,
        data:graphData.points[3].e,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgb(53, 162, 235)',
        yAxisID: 'y1',
        pointRadius:0,  
        pointHoverRadius:0, 
      },
    ],
  };

const options1 = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,

  scales: {
    x: {
      type: 'linear',
      display: true,
      position: 'bottom',
      title: {
        display: false,
        text: graphData.qAxis
      },
      ticks: {
        display: false //this will remove only the label
    }
    },

    y: {
      type: 'linear',
      display: true,
      position: 'left',
      beginAtZero: true,
      min: 0,
      title: {
        display: true,
        text: graphData.pAxis
      },
      afterFit(scale) {
        scale.width = 90;
      }
    },
    y1: {
      type: 'linear',
      display: false,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
      title: {
        display: true,
        text: graphData.eAxis
      },
      afterFit(scale) {
        scale.width = 90;
      }
    },
  },
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Line Chart - Multi Axis',
    },
    legend: {
      display: false,
    },
    tooltip: {
      filter:function(tooltipItem){
        // console.log(tooltipItem)
        return (tooltipItem.datasetIndex === 3 || tooltipItem.datasetIndex === 4);
      },
    },
  },
};


const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,

  scales: {
    x: {
      type: 'linear',
      display: true,
      position: 'bottom',
      beginAtZero: true,
      min: 0,
      title: {
        display: true,
        text: graphData.qAxis
      },
    },

    y: {
      type: 'linear',
      display: true,
      position: 'left',
      beginAtZero: true,
      min: 0,
      title: {
        display: true,
        text: graphData.hAxis
      },
      afterFit(scale) {
        scale.width = 90;
      }
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      beginAtZero: true,
      min: 0,
      grid: {
        drawOnChartArea: false,
      },
      title: {
        display: true,
        text: graphData.eAxis
      },
      afterFit(scale) {
        scale.width = 90;
      }
    },
  },


  plugins: {
    title: {
      display: false,
      text: 'Chart.js Line Chart - Multi Axis',
    },


    legend: {
      display: true,
      position: 'bottom',
      labels: {
        filter: function (legendItem, chartData) {
          
          if(legendItem.text !== 'line1' && legendItem.text !== 'line2' && legendItem.text !== 'triangle') {
            return (chartData.datasets[legendItem.datasetIndex].label)
          }
        },
      }
    },
  
    tooltip: {
      filter:function(tooltipItem){
        // console.log(tooltipItem)
        return (tooltipItem.datasetIndex === 3 || tooltipItem.datasetIndex === 4);
      },
            // callbacks: {

      //   title: (tooltipItem) => {
      //     console.log('tooltipItem')
      //     console.log(tooltipItem)
      //       // console.log(tooltipItem)
      //       let finalHtml = '';
      //     // if(tooltipItem.length>1){
      //       if(tooltipItem[0].dataset.label === 'line2'  ) {
      //         //   let finalHtml = '';
      //         // finalHtml = `Flow2: ${tooltipItem[0].parsed.x} Head: ${tooltipItem[0].parsed.y} 
      //         //          `;
      //         tooltipItem[1].parsed.x = tooltipItem[0].parsed.x;
      //         tooltipItem[2].parsed.y = tooltipItem[0].parsed.y;
      //         // tooltipItem[0].dataset.label = null;
      //         console.log('tooltipItem[3]')
      //         console.log(tooltipItem)

      //         } 
      //   }    
      // }
    }
    
  },
};

  if(mode === 'power') {
    return  (<div style={{ width: '100%', height: '220px' }}><Line data={Chart1Data} options={options1} /> </div>);
  } else {
    return (<div style={{ width: '100%', height: '300px' }}> <Line data={Chart2Data} options={options2}  /></div>);
  }
  
};

export default GraphComponent;