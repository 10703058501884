export const initProducts = [
    {
        id: "1",
        name: "Harry Walter",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "2",
        name: "Marry weather",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "3",
        name: "Ludwing Gorronson",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "4",
        name: "Alex Halley",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "5",
        name: "Peter Linch",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "6",
        name: "Anan Ivanovich",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "7",
        name: "Marry weather",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "8",
        name: "Ludwing Gorronson",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },


    {
        id: "9",
        name: "Alex Halley",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "10",
        name: "Peter Linch",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "11",
        name: "Anan Ivanovich",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "12",
        name: "Marry weather",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "13",
        name: "Ludwing Gorronson",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "14",
        name: "Harry Walter",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "15",
        name: "Marry weather",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "16",
        name: "Ludwing Gorronson",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "17",
        name: "Alex Halley",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "18",
        name: "Peter Linch",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "19",
        name: "Anan Ivanovich",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "20",
        name: "Marry weather",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "21",
        name: "Ludwing Gorronson",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },


    {
        id: "22",
        name: "Alex Halley",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "23",
        name: "Peter Linch",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "24",
        name: "Anan Ivanovich",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "25",
        name: "Marry weather",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "26",
        name: "Ludwing Gorronson",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },



    {
        id: "27",
        name: "Harry Walter27",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "28",
        name: "Marry weather28",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "29",
        name: "Ludwing Gorronson29",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "30",
        name: "Alex Halley30",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "31",
        name: "Peter Linch31",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "32",
        name: "Anan Ivanovich32",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "33",
        name: "Marry weather33",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "34",
        name: "Ludwing Gorronson34",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },


    {
        id: "35",
        name: "Alex Halley35",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "36",
        name: "Peter Linch36",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "37",
        name: "Anan Ivanovich37",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "38",
        name: "Marry weather38",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "39",
        name: "Ludwing Gorronson39",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "40",
        name: "Harry Walter40",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "41",
        name: "Marry weather41",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "42",
        name: "Ludwing Gorronson42",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "43",
        name: "Alex Halley43",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "44",
        name: "Peter Linch44",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "45",
        name: "Anan Ivanovich45",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "46",
        name: "Marry weather46",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "47",
        name: "Ludwing Gorronson47",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },


    {
        id: "48",
        name: "Alex Halley48",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "49",
        name: "Peter Linch49",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },

    {
        id: "50",
        name: "Anan Ivanovich50",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "51",
        name: "Marry weather51",
        status: 'inactive',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    },
    {
        id: "52",
        name: "Ludwing Gorronson52",
        status: 'active',
        companyid: "NFC12345",
        email: 'abc@xyz.com',
        contact: '+1 - 234 - 456 - 78',
        action: '0',
    }

];
export const availableProducts = [
    {
        id: "1",
        flow: "300 GPM",
        flowTwo: "450 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "2",
        flow: "450 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "3",
        flow: "300 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "4",
        flow: "450 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "5",
        flow: "300 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "6",
        flow: "450 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "7",
        flow: "300 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
    {
        id: "8",
        flow: "450 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
];
export const compareProduct = [
    {
        id: "1",
        flow: "300 GPM",
        flowTwo: "450 GPM",
        pressure: '8 Bar',
        speed: "2950 RPM",
        pumpType: "End Suction",
        driver: "Motor",
        controller: "Available",
        location: "Dubai",
        qty: "2",
        compare: "",
        addToCart: "",
        action: 0,
    },
];



export const CustomerService = {
    getData() {
        return [
            {
                id: 1000,     
                flow: "300 GPM",
                flowTwo: "450 GPM",
                representative: {
                    name: 'Flow'
                }
            },
            {
                id: 1001,
                flow: "8 Bar",
                flowTwo: "8 Bar",
                representative: {
                    name: 'Pressure',
                }
            },
            {
                id: 1002,      
                flow: "2950 RPM",
                flowTwo: "2950 RPM",  
                representative: {
                    name: 'Speed',
                }
            }
        ];
    },

    getCustomersSmall() {
        return Promise.resolve(this.getData().slice(0, 10));
    },

    getCustomersMedium() {
        return Promise.resolve(this.getData().slice(0, 50));
    },

    getCustomersLarge() {
        return Promise.resolve(this.getData().slice(0, 200));
    },

    getCustomersXLarge() {
        return Promise.resolve(this.getData());
    },

    getCustomers(params) {
        const queryParams = params
            ? Object.keys(params)
                  .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                  .join('&')
            : '';

        return fetch('https://www.primefaces.org/data/customers?' + queryParams).then((res) => res.json());
    }
};
