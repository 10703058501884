import nav_logo from '../assets/images/navbar_logo.svg';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const Logo = () => {
  const [isShowCustomer, setIsShowCustomer] = useState(true);
  const { user } = useSelector((store) => store.user);
  useEffect(() => {
    if(user) {
      if(user.role) {
        if(user.role === 'admin' || user.role === 'sales')
          setIsShowCustomer(false)
      }
    }
  }, [user]);

  return <NavLink to={`${!isShowCustomer ? "/customer-list" : "/select-pump"}`}> <img src={nav_logo} alt='navbar logo' className='nav_logo' /></NavLink>
};
export default Logo;
