import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import pumpSelectionSlice from './features/pumpSelection/pumpSelectionSlice';
import cartSlice from './features/cart/cartSlice';


export const store = configureStore({
  reducer: {
    user: userSlice,
    pumpSelection: pumpSelectionSlice,
    cartBooking:cartSlice
  },
});
