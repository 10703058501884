import arrowLeft from '../assets/images/arrow-left.svg';
import endSuction from '../assets/images/horizontalSplitcase.png';
import { NavLink,useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateRedirectStatus } from '../features/pumpSelection/pumpSelectionSlice';
import naff_logo from '../assets/images/remove-bg-logo.png';
const SearchResultTableHead = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectCall = () => {
    dispatch(updateRedirectStatus(true));
    navigate(`/select-pump-detail?n=${props.tableHeadingRedirect}`)
  }
    return(
      <>
      <section className='search_result_head_section'>
        <div className="container table_head">
          <div className='table_head_wrapper'>
            <div className='table_header_bg'>
              <img src={naff_logo} alt="naffco table header logo"/>
            </div>
          <div className="table_head_left">
            <div className='arrow_left_img'>
              <a href="javascript:;" onClick={redirectCall}><img src={arrowLeft} alt="Arrow Left"/></a>
            </div>
            <div className="table_head_text">
              <h2 className="table_head_heading">{props.tableHeading}</h2>
              <p className="table_head_title">{props.tableTitle}</p>
            </div>
          </div>
          <div className="table_head_right">
            <img src={props.tableHeadingImage} alt="Naffco"/>
          </div>
          </div>
         
        </div>
      </section>
      </>
    );
  };
  export default SearchResultTableHead;