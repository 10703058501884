import React, { useState, useEffect }  from 'react';
// import {DataTableField} from '../../../components';
import {EnquiriesHead, ManageImportedStockDataTable} from '../../../components';
import customFetch from "../../../utils/axios";
import AddNewPump from '../stockPump/AddNewPump';
import closeSvg from '../../../assets/images/Close.svg';
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";
import ToastMessage from '../../../components/ToastMessage';
import { useNavigate} from 'react-router-dom';
import PopupModalTransfer from '../../../components/PopupModalTransfer';

const ImportedStockPump = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [stocklist, setStockList] = useState([]);
  const [show, setShow] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [pagelimit, setpagelimit] = useState(10);
  const [pageNo, setpageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);

  const [totalRecord, setTotalRecord] = useState(0);
  const [totalImportedRecord, setTotalImportedRecord] = useState(0);

  useEffect(() => {
    fetchStock();
}, [pageNo]);

  const navigate = useNavigate();

  const changePage = (page=1)=>{
    setpageNo(page);
  }
    const fetchStock = async () => {
      setErrorMessage('');
      try {
          const response = await customFetch.get(`/imported-stocklist/`+pagelimit+`?page=`+pageNo);
          const stocklist = response.data.stocklist;
          console.log(response);
          setStockList(stocklist);
          setTotalRecord(response.data.totalRecord);
          setTotalImportedRecord(response.data.totalImportedRecord);

      } catch (err) {
          // console.log(err);
          if (err.response.status === 401) {
            setIsLogout(true)
          }
          setErrorMessage('There is something wrong. Please try again');
          window.scrollTo(0,0);
      }
    };
    const callBackList = () => {
      fetchStock();
    }
    const handleShow=()=>{
      setApiSuccessMessage('')
      setShow(true);
    }

    const initialState = {
      first_name: '',
      last_name: '',
      email: '',
      role: null,
      status: '',
      company_id: '',
      id:null,
    };

    const onCloseCallBack = (message=null) => {
      setApiSuccessMessage('')
      if(message) {
        setApiSuccessMessage(message)

        callBackList();
      }
      setShow(false)
      setIsTransfer(false)
    }
    const handleTranser = async () => {
      setIsTransfer(true)
    }

    return (
      <>
        <div className='container'>
            <div className='stock_pump_master'>
              <div className="table-header table_heading_head">
                <div className="table_heading_content">
                    <h1 className="p-m-0">Preview Stock Pumps</h1>
                    <h3 className='badge bg-success'>Successfully Imported Records {totalImportedRecord} out of {totalRecord}.</h3>
                    <div className="conditional_block_show">
                        <button className='darkred_btn' onClick={handleTranser} >
                            <span>Transfer</span>
                        </button>
                    </div>
                  </div>
                </div>
                
                {
                  (isTransfer) && (<PopupModalTransfer  onCloseCallBack={onCloseCallBack} />)
                }
                
                <div className='stock_pump_master_table'>
                    <ManageImportedStockDataTable addOrEditMode="Edit" dataList={stocklist} callBackList={callBackList} changePage={changePage} pagelimit={pagelimit}/>
                </div>
                
            </div>
        </div>
        {
          (isLogout) && (<HandleUnauthorization />)
        }
      </>
    )
  }
  
export default ImportedStockPump