// import React from "react"; 
import React, { useState, useEffect ,useRef} from "react";
import { Dropdown } from 'primereact/dropdown';
import customFetch from "../utils/axios";
import { HandleUnauthorization } from "../utils/HandleUnauthorization";
import ToastMessage from './ToastMessage';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import Editable from 'react-x-editable';

const ShowDetailsActivity = ({enquirydata}) => {

  const [selectActivityStatus, SetSelectActivityStatus] = useState(null);
  const [activityStatus, SetActivityStatus] = useState([]);
  
  const [allStatus, SetAllStatus] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isLogout, setIsLogout] = useState(false);
  const [current_status, setcurrentStatus] = useState(false);
  // const [confirm_status_change, setVisible] = useState(false);
  const [accept_status_change, setAccept] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [logDate, setLogDate] = useState('')

  const [isCancelled, setIsCancelled] = useState('')

  // const [visible, setVisible] = useState(false);
  //   const toast = useRef(null);

    // const accept = () => {
    //   setAccept(true);
    //     toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    // }

    // const reject = () => {
    //     toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    // }
  useEffect(() => {
    currentStatus();
    
  }, [enquirydata]);

  const fetchEnquiryStatus = async () => {
   
    setErrorMessage('');
    try {
      let enquiry_id = enquirydata.id;
      if(enquiry_id) {
        const response = await customFetch.get(`/enquiry_status_list/`+enquiry_id);
        const { status, message, error, result } = response.data;

        if(status === true){
          // alert('true')
          

          SetAllStatus(response.data.enquiry_status_list);
          SetActivityStatus(response.data.pending_status);

          hideShowStatus()
          
        }
      }
        

    } catch (err) {
      if (err.response.status === 401) {
        setIsLogout(true)
      }
        console.log(err);
        setErrorMessage('There is something wrong. Please try again');
        window.scrollTo(0,0);
    }
  };

  const currentStatus = () => {
    fetchEnquiryStatus();

    

    if(enquirydata){
      let status = ''
      if(enquirydata.status_master){
        status = enquirydata.status_master.status_value;
        setcurrentStatus(enquirydata.status_master.name);
        if(status)
          SetSelectActivityStatus(status);
        
      }

    }

  }
  const addStatusLog = async (data) => {
    // setVisible(true);
    // if(accept_status_change==true){
      setErrorMessage('');
      const statusData = {
        'status_id': data.id,
        'enquiry_id': enquirydata.id,
    }
      try {
        const response = await customFetch.post('/add_status_log', statusData);
        const { status, message, error, result } = response.data;
        if (status === true) {
          setApiSuccessMessage(message);
          SetAllStatus(response.data.enquiry_status_list);
          SetActivityStatus(response.data.pending_status);
          //SetSelectActivityStatus(response.data.pending_status[0])

          hideShowStatus()
        }
      } catch (err) {
        if (err.response.status === 401) {
          setIsLogout(true)
        }
          // console.log(err);
          setErrorMessage('There is something wrong. Please try again');
          window.scrollTo(0,0);
      }
    // }
    // setAccept(false);
  };

  const SetStatus =(data) => {
    SetSelectActivityStatus(data);
    addStatusLog(data);
  }

  const updateLogState =  async (value,log_id) => {
    
    const statusData = {
      'log_id': log_id,
      'log_date': value,
    }

    try {
      const response = await customFetch.post('/update-status-log', statusData);
      const { status, message, error, result } = response.data;
      if (status === false) {
        setApiErrorMessage(message)  
        //console.log(apiErrorMessage)
        setIsEditing(false)
      } else {
        setIsEditing(true)
      }
    } catch (err) {
    
    }
  
  }

  const hideShowStatus = () => {
    setIsCancelled('')
    setTimeout(() => {
      allStatus.map((value,index) => {
        if(value.name === 'cancelled' && value.log_date !== null) {
          setIsCancelled('d-none')  
        } else {
          setIsCancelled('') 
        }
      })
    }, 1000);
  
  } 
  
  setTimeout(() => {
    allStatus.map((value,index) => {
      if(value.name === 'cancelled' && value.log_date !== null) {
        setIsCancelled('d-none')  
      } else {
        setIsCancelled('') 
      }
    })
  }, 1000);

  //console.log(isCancelled)    

    return(
        <>  
         {
          (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
        }
         {
          (apiErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={apiErrorMessage} />)
        }
              
            <div className="activity_block">
              
              {
                (activityStatus.length > 0) && (<div className="status_change">
                <div className="form-row">
                  <label>Change Status</label>
                <div className={`${current_status == 'converted_to_client' ? 'disable_div' : ''}`}>
                    <Dropdown 
                    value={selectActivityStatus} 
                    onChange={(e) => SetStatus(e.target.value)} 
                    options={activityStatus} 
                    optionLabel="status_value" 
                    placeholder="Change Status"
                    className="w-full" />
                </div>
                </div>
              </div>)
              }
                
                <h3 className="sub_details_head">ACTIVITY</h3>
                <div className="steps_flow">
                <ul className="progress_bar">
                { 
                allStatus.length > 0 ? (allStatus.map((item) => (
                  <>
                  {(item.name !== 'cancelled') && 
                  <li className={`${item.log_date != null ? 'active' : isCancelled }`}>
                  <div className="step_data">
                    <p className="step_info">{item.status_value}</p>
                    {
                      (item.log_date !== null) && <p className="step_date">
                      {
                        (item.name === 'enquiry_received' || item.name === 'cancelled') ?
                         (moment(item.log_date).format('MM/DD/YYYY'))
                         : (<Editable
                          name="log_date"
                          dataType="date"
                          mode={"inline"}
                          title="Enter Date"
                          value={moment(item.log_date).format('MM/DD/YYYY')}
                          //display={ () => updateLogState()}
                          display={(value) => {
                            updateLogState(value, item.log_id)
                            if(isEditing) {
                              return (moment(value).format('MM/DD/YYYY'));
                            }
                          }}
                        />)

                      }
                      </p> 
                    }
                  </div>
                  </li>
                  } 
                  {(item.name === 'cancelled' && item.log_date != null ) && 
                  <li className={`${item.log_date != null ? 'active' : '' }`}>
                  <div className="step_data">
                    <p className="step_info">{item.status_value}</p>
                    {
                      (item.log_date !== null) && <p className="step_date">
                      {
                        (item.name === 'enquiry_received' || item.name === 'cancelled') ?
                         (moment(item.log_date).format('MM/DD/YYYY'))
                         : (<Editable
                          name="log_date"
                          dataType="date"
                          mode={"inline"}
                          title="Enter Date"
                          value={moment(item.log_date).format('MM/DD/YYYY')}
                          //display={ () => updateLogState()}
                          display={(value) => {
                            updateLogState(value, item.log_id)
                            if(isEditing) {
                              return (moment(value).format('MM/DD/YYYY'));
                            }
                          }}
                        />)

                      }
                      </p> 
                    }
                  </div>
                  </li>
                  } 
                  
                  
                  </>
                  ))): (
                    <div className="inner_details">
                      <br></br>
                  {/* <tr> */}

                    <td
                        className='text-center'
                        colSpan='5'
                    >
                        {'No records found'}
                    </td>
                    {/* </tr> */}
                    </div>
                
                )}
                  </ul>

                    {/* <ul className="progress_bar">
                      <li className="active">
                        <div className="step_data">
                          <p className="step_info">Enquiry Received</p>
                          <p className="step_date">04/03/2023</p>
                        </div>
                       </li>
                       <li className="active">
                        <div className="step_data">
                          <p className="step_info">Contacted</p>
                          <p className="step_date">05/03/2023</p>
                        </div>
                      </li>
                      <li className="active"> 
                        <div className="step_data">
                          <p className="step_info">Working on requirements</p>
                          <p className="step_date">07/03/2023</p>
                        </div>
                      </li>
                      <li>
                           <div className="step_data">
                            <p className="step_info">Qualified</p>
                            <p className="step_date">10/03/2023</p>
                          </div>
                      </li>
                      <li>
                           <div className="step_data">
                            <p className="step_info">Quotation Sent</p>
                            <p className="step_date"></p>
                          </div>
                      </li>
                      <li>
                           <div className="step_data">
                            <p className="step_info">Converted to Client</p>
                            <p className="step_date"></p>
                          </div>
                      </li>
                    </ul> */}
                </div>
            </div>
            {
          (isLogout) && (<HandleUnauthorization />)
        }

          {/* <Toast ref={toast} />
          <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to change status?" 
              header="Confirmation" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} /> */}
        </>
    );
}
export default ShowDetailsActivity