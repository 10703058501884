import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import customFetch from "../utils/axios";
import logo from '../assets/images/new_logo.png';
// import login_banner from '../assets/images/real-login-bg.jpg';
import login_banner from '../assets/images/LOGIN-WEB.jpg';
// import mobile_login_banner from '../assets/images/mobile_login_bg.jpg';
import mobile_login_banner from '../assets/images/LOGIN-MOBILE.jpg';

const VerifyEmail = () => {

const navigate = useNavigate();
const [loading, setLoading] = useState(false);
const [errorMessage, setErrorMessage] = useState('');
const search = useLocation().search;
const verificationCode = new URLSearchParams(search).get('c');
const [isLinkExpired, setIsLinkExpired] = useState(false);

const redirectToLogin = () => {
//alert(1)
navigate('/login');
}
useEffect(() => {
const verifyUser = async () => {
setErrorMessage('');
// alert(verificationCode)
try {
const response = await customFetch.post(`/user_email_verify/${verificationCode}`);
const { status, message, error, result } = response.data;
let finalApiError = "";

if (status === false) {
if (error.length > 0) {
error.map(
(errorMessage) =>
//finalApiError += '<li>'+errorMessage+'</li>'
(finalApiError = errorMessage)
);
} else {
finalApiError = message;
}
setErrorMessage(finalApiError);
// alert(Result.is_expired)
if (result.is_expired) {
setIsLinkExpired(true);
}
else {
setIsLinkExpired(false);
}
window.scrollTo(0, 0);
}
} catch (err) {
// console.log(err);
setErrorMessage('There is something wrong. Please try again');
window.scrollTo(0, 0);
}
};

verifyUser();
}, []);

return (
<>
    <div className='login_block'>
        <div className='row w-100 mx-auto'>
            <div className="col-md-7 px-0 bg_login h100vh">
                <img src={login_banner} alt="logo" className='login_banner desktop_login_bg' />
                <img src={mobile_login_banner} alt="logo" className='login_banner mobile_login_bg' />
            </div>
            <div className='col-md-5 px-0 login_form_col'>
                <div className="form-inner-col">
                    <div className='login_logo'>
                        <NavLink to="/select-pump"><img src={logo} alt="logo" className='login_logo_img' /></NavLink>
                        
                    </div>
                    <div className="form-wrapper verification-container">
                        {errorMessage && (
                        <div className="simple-text">
                            {errorMessage}
                        </div>
                        )}
                        {
                        (isLinkExpired) && (
                        <>

                            <p className="simple-text">Your link is expired. Please again make a request for reset
                                password.</p>
                            <br />
                            <div className="form-group">
                                <NavLink to="/forgot-password" className="btn darkred_btn">Forgot Password</NavLink>
                            </div>
                        </>
                        )}
                        {
                        (isLinkExpired === false) && (<>

                            <h1 className="verify-head my-2">Success</h1>

                            <p className="simple-text">

                                You have successfully registered with <span>NAFFCO</span>.
                            </p>

                            <div className="form-group">
                                <button className="btn darkred_btn" type="button" onClick={redirectToLogin}>Continue to
                                    Login</button>
                            </div>
                        </>)}
                    </div>
                </div>
            </div>
        </div>
    </div>


</>
)
}
export default VerifyEmail;