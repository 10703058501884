import React, { useState, useEffect } from "react"; 
import moment from 'moment';
import customFetch from "../utils/axios";
import ToastMessage from './ToastMessage';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';

const ShowDetailsBlock = ({enquirydata}) => { 

  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [enquirySubmitalChecked, setEnquirySubmitalChecked ]  = useState('');
  const [assignTo, setAssignTo ]  = useState('');
  const [remark, setRemark ]  = useState('');
  
  useEffect(() => {
    updateSubmitalStatus();
    updateEnquiryRemark();
  }, [enquirydata]);

  const updateSubmitalStatus = () => {
    let enquirySubmital = (enquirydata.show_submital) ?  'checked' : '';
    setEnquirySubmitalChecked(enquirySubmital);
  }
  const updateEnquiryRemark = () => {
    let remark = (enquirydata.remark) ?  enquirydata.remark : '';
    setRemark(remark);
  }
  
  function getCapital(str) {
      return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
      });
    }

    const enquiryTypeYes = (enquirydata.enquiry_type == 'cart') ?  1 : '';

    const updateSubmital = async (data) => {
      const submitalStatus = (data.target.checked) ? 1 : 0; 

      const statusData = {
        'enquiry_id': enquirydata.id,
        'show_submital': submitalStatus,
        'type_action' : 'show_submital'
      }
      
      try {
        const response = await customFetch.post('/update-enquiry-status', statusData);
        const { status, message, error, result } = response.data;
        if (status === true) {
          setApiSuccessMessage(message);

          let enquirySubmital = (response.data.show_submital_status) ?  'checked' : '';

          setEnquirySubmitalChecked(enquirySubmital)
        }
      } catch (err) {
        // console.log(err);
      }

    }

    const UpdateAssignTo = async () => {
      
      const updateData = {
        'enquiry_id': enquirydata.id,
        'assign_to': assignTo,
        'type_action' : 'assign_to'
      }
      
      try {
        const response = await customFetch.post('/update-enquiry-status', updateData);
        const { status, message, error, result } = response.data;
        if (status === true) {
          setApiSuccessMessage(message);
        }
      } catch (err) {
        // console.log(err);
      }

    }

    const updateRemark = async () => {
      const updateData = {
        'enquiry_id': enquirydata.id,
        'remark': remark,
        'type_action' : 'remark'
      }
      
      try {
        const response = await customFetch.post('/update-enquiry-status', updateData);
        const { status, message, error, result } = response.data;
        if (status === true) {
          setApiSuccessMessage(message);
        }
      } catch (err) {
        // console.log(err);
      }

    }

    

    return(
        <>  
          {
            (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
          }  
          <div className="details_block">
            <h3 className="sub_details_head">USER DETAILS</h3>
            <div className="inner_details">
                <div className="row">
                  <div className="col-md-4 ipad_col_50_100">
                    <div className="form-row">
                      <label>NAME</label>
                      <p className="details_data">{getCapital(enquirydata.name)}</p>
                    </div>
                  </div>
                  <div className="col-md-3 ipad_col_50_100">
                    <div className="form-row">
                      <label>PHONE</label>
                      <p className="details_data">{enquirydata.contact_number ? enquirydata.contact_number : 'N/A'}</p>
                    </div>
                  </div>
                  <div className="col-md-5 iPad_col_100">
                    <div className="form-row">
                      <label>EMAIL</label>
                      <p className="details_data">{enquirydata.email}</p>
                    </div>
                  </div>
                    <div className="col-md-4 ipad_col_50_100">
                      <div className="form-row">
                        <label>USER LOCATION</label>
                        <p className="details_data">{enquirydata.user_location ? enquirydata.user_location : 'N/A' }</p>
                      </div>
                    </div>
                    <div className="col-md-3 ipad_col_50_100">
                      <div className="form-row">
                        <label>ENQUIRY DATE</label>
                        <p className="details_data">{moment(enquirydata.enquiry_date ).format('MM/DD/YYYY')}</p>
                      </div>
                    </div>
                    {
                      (enquiryTypeYes) && 
                      (
                      <div className="col-md-5 ipad_col_50_100">
                        <div className="form-row">
                          <label for="showSubmital">SHOW SUBMITAL</label>
                          <div className="checkbox_block">
                            <input 
                              type="checkbox" 
                              name="show_submital" 
                              id="showSubmital"  
                              value="1" 
                              onChange={updateSubmital} 
                              checked={enquirySubmitalChecked} 
                            /><label></label>
                          </div>

                        </div>
                      </div>
                      )
                    }
                    <div className="col-lg-7 col_1200_w_100">
                        <div className="form-row">
                            <label>MESSAGE</label>
                            <p className="details_data">{enquirydata.message ? enquirydata.message : 'N/A' }</p>
                          </div>
                    </div>
                    <div className="col-md-5 ipad_col_50_100">
                      <div className="form-row">
                        <label>ASSIGNED TO</label>
                        <div className="p-inputgroup flex-1">
                          <InputText 
                            name="assign_to"
                            value={enquirydata.assign_to}
                            onChange={ (e) => setAssignTo(e.target.value) }
                          />
                          <Button icon="pi pi-check" onClick={UpdateAssignTo} className="p-button-green" />
                        </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
            {/* {
              enquirydata.enquiry_count>0 ? (    */}
          <div className="details_block">
            <h3 className="sub_details_head">ENQUIRY PUMP DETAILS</h3>
            { 
              enquirydata.enquiry_count > 0 ? (enquirydata.enquiry_details.map((item) => (
                <div className="inner_details">
                  <div className="row">
                    <div className="col-md-5 ipad_col_50_100">
                      <div className="form-row">
                        <label>MODEL</label>
                        <p className="details_data">{item.pump_model}</p>
                      </div>
                    </div>
                    <div className="col-md-4 ipad_col_50_100">
                      <div className="form-row">
                        <label>DUTY POINT</label>
                        <p className="details_data">{item.flow} {item.flow_UOM} @ {item.head} {item.head_UOM}</p>
                      </div>
                    </div>
                    <div className="col-md-3 right_align_box ipad_col_50_100">
                      <div className="form-row">
                        <label>DRIVER TYPE</label>
                        <p className="details_data">{item.driver_type}</p>
                      </div>
                    </div>
                      <div className="col-md-5 ipad_col_50_100">
                        <div className="form-row">
                          <label>TYPE</label>
                          <p className="details_data">{item.pump_name}</p>
                        </div>
                      </div>
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>LOCATION</label>
                          <p className="details_data">{item.location}</p>
                        </div>
                      </div>
                      {
                      (enquiryTypeYes) && 
                      (
                      <div className="col-md-3 right_align_box ipad_col_50_100">
                        <div className="form-row">
                          <label>Quantity</label>
                          <p className="details_data">{item.quantity}</p>
                        </div>
                      </div>)}
                  </div>  
                            </div> 
                ))): (
                  <div className="inner_details">
                    <br></br>
                {/* <tr> */}

                  <td
                      className='text-center'
                      colSpan='5'
                  >
                      {'No records found'}
                  </td>
                  {/* </tr> */}
                  </div>
              
              )
              }  
          </div>
          
          <div className="details_block">
            <h3 className="sub_details_head">Remark</h3>
            <div className="inner_details">
              <div className="row">
                <div className="col-md-12">
                  <div class="form-row">
                    <InputTextarea autoResize value={remark} onChange={(e) => setRemark(e.target.value)} rows={5} cols={120} className="form-control" />
                  </div>
                  <br/>
                  <Button className='transparent_btn' onClick={updateRemark} label="Update" />
                </div>
              </div>
            </div>
          </div>
          
          
         
        </>
    );
}
export default ShowDetailsBlock