import React,  { useState, useEffect, useRef } from 'react';
import {BreadcrumbAndHeading, OverViewPump, TechnicalData, EnquiriesShockPump, PopupModalStockAvaiDelete, ToastMessage} from '../../../components';
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigate, useParams} from 'react-router-dom';
import customFetch from "../../../utils/axios";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import ShowError from '../../../components/ShowError';


const ViewPumpResults = () => { 

    const { register, handleSubmit, formState: { errors }, setError, clearErrors, resetField } = useForm();

    const { string } = useParams();

    const [pumpModel, setPumpModel] = useState('');
    const [pumpRecord, setPumpRecord] = useState([]);
    const [avilList, setAvilList] = useState([]);
    const [isVisible, setIsVisible] = useState('');
    const [pCode, setPCode] = useState('');

    const [technicalFileStatus, setTechnicalFileStatus] = useState(0);
    const [submitalFileStatus, setSubmitalFileStatus] = useState(0);

    const [technicalFileURL, setTechnicalFileURL] = useState('');
    const [submitalFileURL, setSubmitalFileURL] = useState('');

    const [showForm, setShowForm] = useState('d-none');
    const [showButton, setShowButton] = useState('');
    const [stock, setStock] = useState('');
    const [locationData, setLocationData] = useState([]);
    const [location, setLocation] = useState('Afghanistan');
    const [apiSuccessMessage, setApiSuccessMessage] = useState('');

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [stockAvailabilityId, setStockAvailabilityId] = useState('');

    const navigate = useNavigate();
    
    const dt = useRef(null);

    useEffect(() => {
      pumpDetails()
      showStockAvailability()
    },[string]);

    const pumpDetails = async () => {

      // Create FormData object
      const formData = new FormData();

      formData.append('pump_id', string);
      
      try {
          const response = await customFetch.post('/pump-details',formData);
          const { status, message, error, result } = response.data;
          if (status === true) {
              
              let result = response.data.data;
              setPumpRecord(result);
              setPumpModel(result.model);
              setIsVisible(result.is_visible)

              showStockAvailability(result.code)
              setPCode(result.code)
              
              setTechnicalFileStatus(response.data.techinal_data_sheet_status);
              setSubmitalFileStatus(response.data.submital_data_sheet_status);

              setTechnicalFileURL(response.data.techinal_data_sheet_url)
              setSubmitalFileURL(response.data.submital_data_sheet_url)
          } 
      } catch (err) {
         
      }
      
    };

    const showStockAvailability = async (pCode) => {
        
      // Create FormData object
      const formData = new FormData();

      formData.append('pump_code', pCode);
      

      try {
          const response = await customFetch.post('/get-stock-availability',formData);
          const { status, message, error, result } = response.data;
          if (status === true) {
              let result = response.data.data;
              setAvilList(result.stockavail)
              setLocationData(response.data.location_list); 
              
          } 
      } catch (err) {
          
      }
    }

    const handleForm = () => {
      setShowForm('');
      setShowButton('d-none'); 
    }

    const onSubmit = async (data) => {
        
      console.log(data)  
        
      const formData = new FormData();
      
      if(data.location == '') {
          data.location = 'Afghanistan';
      }

      formData.append("pump_code", pCode);
      formData.append("location", data.location);
      formData.append("stock", data.stock);

      try {
          const response = await customFetch.post('/add-stock-availability',formData);
          const { status, message, error, result } = response.data;
          if (status === true) {
              showStockAvailability(pCode);
              setApiSuccessMessage(message);
              resetField('location');
              resetField('stock');
              setShowForm('d-none');
              setShowButton(''); 
          } else {
              //setIsLoading(false);
          }
      } catch (err) {
      }

    }

    const actionBtn = (data) => 
    {
      setStockAvailabilityId(data.id)
      return (
          <>
          <Button onClick={openConfirmBox} icon="pi pi-times" label="Delete">Delete</Button>
        </>
      )
    }  

    const onCloseCallBack = (message=null) => {
      setApiSuccessMessage('')
      if(message) {
        setApiSuccessMessage(message)
        showStockAvailability(pCode);
      }
      
      setIsDeleteOpen(false)
    }

    const openConfirmBox = () => {
      setIsDeleteOpen(true)
    }
 
    return (
      <>
         {
          (apiSuccessMessage) && (<ToastMessage showHidePanel={true} isError={0} message={apiSuccessMessage} />)
        }
        <div className='stock_pump_master'>
          <div className='container'>
            <BreadcrumbAndHeading name={pumpModel} preUrl="Manage Stock Pumps" classSHows="modificationShow" pumpID={string} isVisible={isVisible} url={'/stock-pump-master'} />
            <div className="pumps_tabs">
                <TabView>
                    <TabPanel header="Overview">
                      <OverViewPump pumpRecord={pumpRecord} />
                    </TabPanel>
                    <TabPanel header="Technical Data Sheet">
                      <TechnicalData pdfImg={technicalFileURL} fileStatus={technicalFileStatus} title="Technical Data Sheet" />
                    </TabPanel>
                    <TabPanel header="Technical Submittals">
                    <TechnicalData pdfImg={submitalFileURL} fileStatus={submitalFileStatus} title="Technical Submittals"/>
                    </TabPanel>
                    <TabPanel header="Enquiries">
                      <EnquiriesShockPump pumpCode={pumpRecord.code} />
                    </TabPanel>
                    <TabPanel header="Stock Availability">
                      <div className="custom_dataTable stock_table">
                        <DataTable
                            ref={dt}
                            value={avilList}
                            dataKey="id"
                        >
                            <Column field="location" header="Location" sortable></Column>
                            <Column field="qty_avl" header="Available Stock" sortable></Column>
                            <Column body={actionBtn} field="qty_avl" header="Action" sortable></Column>
                            
                        </DataTable>
                      </div>

                      <Button className={`darkred_btn ${showButton}`} onClick={handleForm} variant="primary" >
                        Add Stock 
                      </Button>
                      <br/>
                      <div className={showForm}>
                          <form onSubmit={handleSubmit(onSubmit)} >
                              <div className="row">

                                  <div className="col-md-4 w_991_full px_10">
                                      <div className="form-row">
                                          <label><b>Location</b> <span className="error_text">*</span></label>

                                          <select 
                                              name="location"
                                              onChange={ (e) => setLocation(e.target.value) }
                                              {...register("location",{ required: false } ) } 
                                              className="form-select"
                                          >
                                          { locationData && locationData.map((item, index) => (
                                              <option value={item.countryname}>{item.countryname}</option>
                                          ))}       
                                          </select>
                                          <ShowError errorsModal={errors.location} />    

                                      </div>
                                  </div>

                                  <div className="col-md-4 w_991_full px_10">
                                      <div className="form-row">
                                          <label><b>Stock</b> <span className="error_text">*</span></label>
                                          <input 
                                              type="text" 
                                              className="form-control" 
                                              name="stock"

                                              onChange={ (e) => setStock(e.target.value) }
                                              {...register("stock",{ 
                                                  required: 'Please enter stock.',
                                                  pattern: {
                                                      value: /^[0-9]+$/i,
                                                      message: 'Please enter valid stock.'
                                                  }
                                              } ) }
                                          />
                                          <ShowError errorsModal={errors.stock} />
                                      </div>
                                  </div>

                                  <div className="col-md-4 w_991_full px_10">
                                      <label style={{marginBottom: "4px"}}>&nbsp;</label>
                                      <button className='transparent_btn' type="submit">
                                              <span>Save</span>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                  <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                                  <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                              </svg>
                                      </button>
                                  </div>

                              </div>

                          </form>
                      </div>



                    </TabPanel>
                </TabView>
            </div>            
          </div>
        </div>
        {
        (isDeleteOpen) && (<PopupModalStockAvaiDelete  rowdata={stockAvailabilityId} onCloseCallBack={onCloseCallBack} />)
        }
      </>
    )
  }
  
export default ViewPumpResults