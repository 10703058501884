import TableHead from '../../components/SearchResultTableHead';
import TableCriteria from '../../components/SearchResultTableCriteria';
import PumpModel from '../../assets/images/pump_model.svg';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { GraphModal, PopupModalEdit,ToastMessage, ModalComponent } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setListPageNumber,updateAPIStatus, updateSearchResultSelectedData, updateRedirectStatus } from '../../features/pumpSelection/pumpSelectionSlice';
import { Accordion, AccordionTab } from 'primereact/accordion';
import PumpListAccordionHead from '../../components/PumpListAccordionHead';
import { RadioButton } from 'primereact/radiobutton';

const modalComponentInitialState = {
  showModal: false,
  title: '',
  content: '',
  redirectLink: ''
};

function ContentHtml(props) {

  if(props.mode === false || props.mode === '')
  {
      return <p>We are pleased to inform you that the download link for your software selection submittal has been successfully sent to the email address you provided. Please check your email inbox, including the spam or junk folder, to access the download link.</p>;
  } else {
      return <p>Thank you for reaching out to us. We have successfully registered your enquiry, and our team will review it promptly. Meanwhile, we have also sent the download link for your selection submittal to the email address you provided.</p>;
  }
}

const initialStateEnquiry = {
  userId:'',
  name: '',
  phone_code:'',
  phone_code_json:'',
  contact_number: '',
  email: '',
  message: '',
  location: '',
  location_text: '',
  location_json: '',
  is_enquiry_send: ''
};

const SelectPumpList = () => {
  // mobile
  const [selectedMobileRadio, setSelectedMobileRadio] = useState(null);
  const [rowSelectedValue, setRowSelectedValue] = useState(null);
  
  const [modalValues, setModalValues] = useState(modalComponentInitialState);
  
  // end
  const [enquiryFormPopupData, setEnquiryFormPopupData] = useState(initialStateEnquiry);

  const [isGraphModal, setGraphModal] = useState(false);
  const [isEnquiryModal, setIsEnquiryModal] = useState(false);

  const { pump_selection, selection_criteria, search_result_api, list_page_number, search_result_data_selected, country_api_data, is_row_selected } = useSelector((store) => store.pumpSelection);

  const { user } = useSelector((store) => store.user);
  const [showPumpRowCount, setShowPumpRowCount] = useState(false);
  const [searchListData, setSearchListData] = useState([]);
  const [isEnquiryButtonEnabled, setIsEnquiryButtonEnabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [graphAPIData, setGraphAPIData] = useState([]);
  const [dutyPumpMessage, setDutyPumpMessage] = useState('');
  const [showDutyPumpMessage, setShowDutyPumpMessage] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  
  
  
  useEffect(() => {
    if(user) {
      setEnquiryFormPopupData(
        {
          ...enquiryFormPopupData,
          userId:user.id,
          name: user.name,
          contact_number: user.contact_number,
          email: user.email,
        }
      )
    }
  }, [user]);

  useEffect(() => {
    dispatch(updateAPIStatus());
    if(pump_selection.length === 0 ) {
      navigate('/select-pump')
    } 
    if(selection_criteria.length === 0 ) {
      navigate('/select-pump')
    }
    window.scrollTo(0, 0);
    setShowPumpRowCount(true)
    setShowDutyPumpMessage(false)
  }, [pump_selection]);
  useEffect(() => {
    const preventUnload = (event) => {
      // NOTE: This message isn't used in modern browsers, but is required
      const message = 'Sure you want to leave?';
      event.preventDefault();
      event.returnValue = message;
    };
  
    window.addEventListener('beforeunload', preventUnload);
  
    return () => {
      window.removeEventListener('beforeunload', preventUnload);
    };
    
  }, []);
  
  useEffect(() => {
    if(list_page_number === 'first') {
      setSearchListData(search_result_api[0]);
    } else {
      setSearchListData(search_result_api[1]);
    }

    

    window.scrollTo(0, 0);
    setDutyPumpMessage('')
    setShowPumpRowCount(true)

    if(selection_criteria.length === 2)
    {
        if(pump_selection.length === 1){
          
          if(search_result_api[1].length === 0) {
            setShowPumpRowCount(false)
            setShowDutyPumpMessage(true)
            if(search_result_api[0].length === 1) {
              
              setDutyPumpMessage('1 pump was found for Duty 1, and no record was found for Duty 2.')
            } else {
              setDutyPumpMessage(search_result_api[0].length+' pumps was found for Duty 1, and no record was found for Duty 2.')
            }
          }
          if(search_result_api[0].length === 0) {
            setShowPumpRowCount(false)
            setShowDutyPumpMessage(true)
            if(search_result_api[1].length === 1) {
              setDutyPumpMessage('1 pump was found for Duty 2, and no record was found for Duty 2')
            } else {
              setDutyPumpMessage(search_result_api[1].length+' pumps was found for Duty 2, and no record was found for Duty 1.')
            }
          }   
        }
      }
  }, [list_page_number]);

  useEffect(() => {
    if(is_row_selected)
      enableEnquiryButtonCall();
  }, [is_row_selected]);

  useEffect(() => {
    if(searchListData.length > 0) {
      let selectedId = '';
      if(list_page_number === 'first') {
        if(search_result_data_selected.hasOwnProperty('first')) {
          selectedId = search_result_data_selected.first.id;
        }
      } else {
        if(search_result_data_selected.hasOwnProperty('second')) {
          selectedId = search_result_data_selected.second.id;
        }
      }
      if(selectedId) {
        setSelectedMobileRadio(selectedId);
        setRowSelectedValue(selectedId)
      }
    }
  }, [searchListData]);

  const handleMobileRadioChange = (value) => {
    
    setSelectedMobileRadio(value.id);
    setErrorMessage('')
    setShowErrorMessage(false)
    dispatch(updateSearchResultSelectedData({ 'data' : value }))
  };
  
  const modelRowDataSelected = (rowData) => {
    setErrorMessage('')
    setRowSelectedValue(rowData.id);
    setShowErrorMessage(false)
    dispatch(updateSearchResultSelectedData({ 'data' : rowData }))
  }
  const pageCall = (mode) => {

    let selectModelPumpRow = document.querySelector('input[name = "selectModelPumpRow"]:checked');
    
    if(selectModelPumpRow === null && searchListData.length > 0) {  
      setErrorMessage('Please select atleast 1 data.')
      setShowPumpRowCount(false)
      setShowDutyPumpMessage(false)
      setShowErrorMessage(true)
      window.scrollTo(0, 0);
      return;
    } else if(selectModelPumpRow !== null) {

      setSelectedMobileRadio('');
      setRowSelectedValue('');
      /*
      let selectModelPumpRowValue = document.querySelector('input[name = "selectModelPumpRow"]:checked').value;
      if(selectModelPumpRowValue === 'on') {
        setSelectedMobileRadio('');
      } else {
        setRowSelectedValue('')
        document.getElementById("row_"+selectModelPumpRowValue).checked = false;
      } */
    }

    setErrorMessage('')
    setShowErrorMessage(false)
    dispatch(setListPageNumber(mode));
    setShowPumpRowCount(false)
    setShowDutyPumpMessage(false)
    if(mode === 'second')
      navigate('/select-pump-list-next')
    else
      navigate('/select-pump-list')
  }
  const enableEnquiryButtonCall = () => {
    if(selection_criteria.length === 2) {
      
      if(search_result_api[0].length > 0 && search_result_api[1].length > 0) {
        if((search_result_data_selected.hasOwnProperty('first')) && (search_result_data_selected.hasOwnProperty('second'))) {
          setIsEnquiryButtonEnabled(true)
        }
      } else if(search_result_api[0].length > 0) {
          if(search_result_data_selected.hasOwnProperty('first')) {
            setIsEnquiryButtonEnabled(true)
          }
      } else if(search_result_api[1].length > 0) {
        if(search_result_data_selected.hasOwnProperty('second')) {
          setIsEnquiryButtonEnabled(true)
        }
      }
    } else if(selection_criteria.length === 1) {
      if(search_result_data_selected.hasOwnProperty('first')) {
        setIsEnquiryButtonEnabled(true)
      }
    }
  }
  
  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Model" field="pumpModel"  sortable rowSpan={2}/>
        <Column header="Stage" field="stage"  sortable rowSpan={2}/>
        <Column header="PEAK Eff (%)" field="peakEff"  sortable rowSpan={2}/>
        <Column header="Speed" field="speed"  sortable rowSpan={2}/>
        <Column header="Power"  colSpan={2} />
        <Column rowSpan={2} />
      </Row>
      <Row>
        <Column header="Rated" field="ratedPower"  sortable />
        <Column header="150%" field="power150"  sortable />
      </Row>
    </ColumnGroup>
  );
  const handleGraphModal = (rowData) => {
    setGraphModal(true)
    const newObj = Object.assign({selected_pump_type: pump_selection[0].name}, rowData);
    setGraphAPIData(newObj)
  }
  
  const Model = (rowData) =>{
    return (
      <div className="newUser" style={{ textTransform: 'capitalize'}}>
        <input type="radio" className='pump_radio_mob' name="selectPump" />
        <p>{rowData.pumpModel}</p>
        <a href='javascript:;' className="model_img" onClick={() => {handleGraphModal(rowData)}}>          
          <img src={PumpModel} alt='Pump Model Icon'/>
        </a>
      </div>
    );
  }
  const callbackFromGraph = () => {
    setGraphAPIData([])
    setGraphModal(false)
  }
  const peakBodyTemplate = (rowData) => {
    if(rowData.peakEff)
      return `${parseFloat(rowData.peakEff).toFixed(2)+' %'}`;
    else
      return 0;
  };

  const radioColumnTemplate = (rowData) => {
    return<div className='table_checkbox'>
    <RadioButton type="radio" name="selectModelPumpRow" id={`row_${rowData.id}`} onClick={() => {modelRowDataSelected(rowData)}} value={rowData.id}
    checked={rowSelectedValue === rowData.id}/>  
    {/* <input type="radio" name="selectModelPumpRow" id={`row_${rowData.id}`} onClick={() => {modelRowDataSelected(rowData)}} value={rowData.id}
    checked={rowSelectedValue === rowData.id}/> */}
    </div>;
  };

  const onCloseCallBack = (message=null,isEnquirySend) => {
    setIsEnquiryModal(false)

    if(message) {
      setSuccessMessage(message)
      dispatch(updateAPIStatus())
      dispatch(updateRedirectStatus(false));
      setIsEnquiryButtonEnabled(false)

      /*let selectModelPumpRowValue = document.querySelector('input[name = "selectModelPumpRow"]:checked').value;
      if(selectModelPumpRowValue !== null) {
        setSelectedMobileRadio('');
        document.getElementById("row_"+selectModelPumpRowValue).checked = false;
      }*/

      setErrorMessage('')
      setShowErrorMessage(false)
      setModalValues({
        showModal: true,
        title: 'Check Your Email',
        content: <ContentHtml mode={isEnquirySend} />,
        redirectLink: '/select-pump'
      })
    }
    
  }
  const modalCallBack = () => {
    setModalValues(modalComponentInitialState)
  }
  const openEnquiryPopup = () => {
   setIsEnquiryModal(true)
  }
  const toastMessageCallBack = () => {
    setErrorMessage('')
    setDutyPumpMessage('')
    setShowErrorMessage(false)
  }
  function getSortedEff(obj) {
    const list = JSON.parse(JSON.stringify(obj));
    let sorter = function(a, b) {
      parseFloat(a.peakEff).toFixed(2)
      if(parseFloat(b.peakEff).toFixed(2) && !parseFloat(a.peakEff).toFixed(2)) {return -1;}
      if(!parseFloat(b.peakEff).toFixed(2) && parseFloat(a.peakEff).toFixed(2)) {return 1;}
      return parseFloat(b.peakEff).toFixed(2) - parseFloat(a.peakEff).toFixed(2);
    };
    list.sort(sorter);
    return list;
  }
  return (
    <>
    {
      (!showDutyPumpMessage) && (showPumpRowCount) && (searchListData) && (searchListData.length > 0) &&  <ToastMessage showHidePanel={true} isError={0} message={`${searchListData.length} ${searchListData.length === 1 ? 'Pump' : 'Pumps'} Found`} toastMessageCallBack={toastMessageCallBack}/> 
    }
    
    {
      (successMessage) && (<ToastMessage showHidePanel={true} isError={0} message={successMessage} toastMessageCallBack={toastMessageCallBack}/>)
    }
    {
      (showDutyPumpMessage) && (!showPumpRowCount) && (dutyPumpMessage) && (<ToastMessage showHidePanel={true} isError={1} message={dutyPumpMessage} toastMessageCallBack={toastMessageCallBack} multipleToastMessage={true}/>)
    }
    {
      (showErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={errorMessage} toastMessageCallBack={toastMessageCallBack}/>)
    }
    <TableHead tableHeading={(pump_selection.length > 0) ? pump_selection[0].name : ''} tableTitle="Selected Pump" tableHeadingImage={(pump_selection.length > 0) ? pump_selection[0].list_image : ''} tableHeadingRedirect={(pump_selection.length > 0) ? pump_selection[0].request_param : ''} />
   
    <div className="custom_dataTable pumple_list">
      <div className="container">
        <div className='pump_table_main'>
        <div className='pump_table_overflow'>
          <div className='pump_table_width'>
            <TableCriteria criteriaData={(selection_criteria.length > 0) ? (list_page_number === 'first') ? selection_criteria[0] : selection_criteria[1] : ''} />
            {/* sortField="peakEff" sortOrder={3} sortMode="multiple" */}
            {/* headerColumnGroup={headerGroup}   */}
            {/* for scroll-> scrollable scrollHeight=" 559px"  */}
            <DataTable  value={searchListData} headerColumnGroup={headerGroup} virtualScrollerOptions={{ itemSize: 68 }}   sortField="peakEff" sortOrder={-3} >
                <Column field="pumpModel" sortable body={Model} />
                <Column field="stage" sortable />
                <Column field="peakEff"  sortable body={peakBodyTemplate}  />
                <Column field="speed"  sortable/>
                <Column field="ratedPower"  sortable/>
                <Column field="power150"  sortable/>
                <Column body={radioColumnTemplate} />
            </DataTable>
          </div>

          {/* mobile */}
          <div className='pump_mobile_table'>
              <div className='pump_thead'>
                <div className='pump_mobile_th'>
                  <h6>Model</h6>
                </div>
                <div className='pump_mobile_th'>
                  <h6>Stage</h6>
                </div>
              </div>
              <div className='pump_mobile_tabs'>
                <div className="">
                  {
                    (searchListData.length === 0) && (<div>No Records Found</div>)
                  }
                <Accordion activeIndex={0} >
                      {(searchListData.length > 0) && getSortedEff(searchListData).map((value, index) => {
              
                  return (
                    <AccordionTab key={index} header={<PumpListAccordionHead radioName="accordionRadio" selectedValue={selectedMobileRadio} onChange={() => { handleMobileRadioChange(value) }}  handleGraphModal={handleGraphModal} rowData={value}/>} setAccordianRowId={index} id={`accordian_row_id_${value.id}`} >
                      <div className='mobile_pump_accordion_body'>
                        <div className='form-row'>
                          <label>PEAK EFF (%)</label>
                          <p className='field_td_mobile'>{(value.peakEff) ? parseFloat(value.peakEff).toFixed(2)+' %' : 'N/A'}</p>
                        </div>
                        <div className='form-row'>
                          <label>SPEED</label>
                          <p className='field_td_mobile'>{(value.speed) ? value.speed : 'N/A'}</p>
                        </div>
                        <div className='form-row'>
                          <label>POWER (Rated)</label>
                          <p className='field_td_mobile'>{(value.ratedPower) ? value.ratedPower : 'N/A'}</p>
                        </div>
                        <div className='form-row'>
                          <label>POWER (150%)</label>
                          <p className='field_td_mobile'>{(value.power150) ? value.power150 : 'N/A'}</p>
                        </div>
                      </div>
                  </AccordionTab>
                )
              })}
                    </Accordion>
                </div>    
              </div>
            </div>
          {/* end */}

        </div>   
        </div>
      </div>
      <div className='pump_btn_fix'>
        <div className='container'>
          <div className={`pumple_list_btn ${list_page_number === 'second' ? 'spread_btns' : ''}`}>
            {/* "spread_btns" please add class when prev section btn show */}
            {
              (selection_criteria.length > 1) && (list_page_number === 'second') && ( <button onClick={() => {pageCall('first')}} type="button" className="outline_btn left_button">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.57031 18.0699L3.50031 11.9999L9.57031 5.92995" stroke="#ED1D24" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M20.5 12L3.67 12" stroke="#ED1D24" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Prev Selection
            </button>)
            }
            <div className='full_hr'></div>
            {
              (selection_criteria.length > 1) && (list_page_number === 'first') && ( <button onClick={() => {pageCall('second')}} type="button" className="outline_btn">Next Selection
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                </path><path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg>
            </button>)
            }
              <button type="button" className="darkred_btn" disabled={!isEnquiryButtonEnabled} onClick={openEnquiryPopup}> Confirm Selection
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round">
                  </path><path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                </button>
          </div>
        </div>
      </div>
       {/* </div> */}
      </div> 
      {
        (isGraphModal) && <GraphModal graphAPIData={graphAPIData} callbackFromGraph={callbackFromGraph} selectionCriteria={(selection_criteria.length > 0) ? (list_page_number === 'first') ? selection_criteria[0] : selection_criteria[1] : ''}/>
      }
      {
        (isEnquiryModal) && <PopupModalEdit callFrom="Enquiry" addOrEditMode='Send' onCloseCallBack={onCloseCallBack} rowdata={enquiryFormPopupData} locationData={country_api_data} enquiryType="" />
      }
      {modalValues.showModal && (
        <ModalComponent
            showModal={modalValues.showModal}
            title={modalValues.title}
            content={modalValues.content}
            redirectLink={modalValues.redirectLink}
            modalCallBack={modalCallBack} 
        />
      )}
    </>
  )
}

export default SelectPumpList
