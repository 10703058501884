import React from 'react';
// import { Link } from 'react-router-dom';
import { FormRow,  FormRowSelect,PopupModalEdit ,ModalComponent} from '../../components';
import loaderImage from '../../assets/images/loader.gif';
import endSuctionImage from '../../assets/images/endSuctionSelected.jpg';
import verticalTurbineImage from '../../assets/images/vertical-pump.png';
import horizontalSplitcaseSelected from '../../assets/images/horizontal-pump.png';
import endSuctionListImage from '../../assets/images/endSuction.png';
import verticalTurbineListImage from '../../assets/images/verticalTurbine.png';
import horizontalSplitcaseListImage from '../../assets/images/horizontalSplitcase.png';

import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlowUnits, HeadUnits, Certification, DriverType, CountryList } from '../../utils/clientMasterAPI';
import { useFormik } from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { searchCriteria, setPumpSearchValues, clearValues, updateAPIStatus } from '../../features/pumpSelection/pumpSelectionSlice';
import { Modal, Button } from 'react-bootstrap';

const initialStateEnquiry = {
  userId:'',
  name: '',
  phone_code:'',
  phone_code_json:'',
  contact_number: '',
  email: '',
  message: '',
  location: '',
  location_text: '',
  location_json: '',
  is_enquiry_send: ''
};

const initialSelectPump = {
  request_param: '',
  name: '',
  image: '',
  list_image:''
};

const initialState = [{
  id: 0,
  flow: '',
  flowUOM: '',
  head: '',
  headUOM: '',
  certification: '',
  nfpa: true,
  minSpeed: '2500',
  maxSpeed: '3000',
  driverType: ''
}];

const initialStateError = [{
  flow_error: '',
  head_error: '',
  certification_error: '',
  nfpa_error: '',
  minSpeed_error: '',
  maxSpeed_error: '',
  driverType_error: '',
  country_error:'',
}];
const modalComponentInitialState = {
  showModal: false,
  title: '',
  content: '',
  redirectLink: ''
};
const SelectPumpDetail =  () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const selectPumpName = new URLSearchParams(search).get('n');

  const dispatch = useDispatch();
  const { isLoading, isRedirectfromListing, flow_api_data, head_api_data, certification_api_data, driver_type_api_data, country_api_data, selection_criteria, selected_country, status_api, message_api ,records_not_found} = useSelector((store) => store.pumpSelection);

  const [values, setValues] = useState(initialState);
  const [errorText, setErrorText] = useState(initialStateError);

  const [flowUnitsData, setflowUnitsData] = useState([]);
  const [headUnitsData, setHeadUnitsData] = useState([]);
  const [certificationData, setCertificationData] = useState([]);
  const [driverTypeData, setDriverTypeData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [countryValue, setCountryValue] = useState('');
  const [countryText, setCountryText] = useState('');
  const [countryValueJson, setCountryValueJson] = useState('');
  const [selectPumpValues, setSelectPumpValues] = useState(initialSelectPump);
  const [showModal, setShowModal] = useState(false);
  const [enquiryType, setEnquiryType] = useState('');
  const [isEnquiryModal, setIsEnquiryModal] = useState(false);
  const [enquiryFormPopupData, setEnquiryFormPopupData] = useState(initialStateEnquiry);
  const { user } = useSelector((store) => store.user);
  // const [successMessage, setSuccessMessage] = useState('');
  // const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [modalValues, setModalValues] = useState(modalComponentInitialState);

  useEffect(() => {
    
    if (status_api) {
      if(enquiryType !== 'dutypoint'){
        navigate('/select-pump-list')
      }
      setEnquiryType('')
    } 
    if(message_api) {
      setAPIData();
      window.scrollTo(0, 0);
    }

    if (records_not_found) {
      setShowModal(true);
      setEnquiryType('dutypoint')
    } 
    window.scrollTo(0, 0);
  }, [status_api,message_api,records_not_found]);

  useEffect(() => {
    
    if (selectPumpName) {
      if(selectPumpName === 'end_suction' || selectPumpName === 'split_case' || selectPumpName === 'vertical_turbine') {
        switch(selectPumpName) {
          case 'end_suction' :
            
            setSelectPumpValues({
              request_param: 'end_suction',
              name: 'End Suction',
              image: endSuctionImage,
              list_image:endSuctionListImage
            })
          break;
          case 'split_case' :
            setSelectPumpValues({
              request_param: 'split_case',
              name: 'Split case',
              image: horizontalSplitcaseSelected,
              list_image:horizontalSplitcaseListImage
            })
          break;
          case 'vertical_turbine' :
            setSelectPumpValues({
              request_param: 'vertical_turbine',
              name: 'Vertical Turbine',
              image: verticalTurbineImage,
              list_image:verticalTurbineListImage
            })
          break;
        }
        if(isRedirectfromListing) {
          setAPIData();
        } else {
          clearSelectPumpDetailsData();
          masterApiCall();
        }
      } else {
        
        navigate('/select-pump')
      }
    } else {
      navigate('/select-pump')
    }
  }, [selectPumpName, isRedirectfromListing]);

  const onCloseCallBack = (message=null,isEnquirySend) => {
    setIsEnquiryModal(false)
    setShowModal(false)
    if (message) {
      setModalValues({
        showModal: true,
        title: 'Check Your Email',
        content: 'Thank you for reaching out to us. We have successfully registered your enquiry, and our team will review it promptly.',
        redirectLink: '/select-pump'
      })
    }
  }
  const handleClose = () => {
    setShowModal(false);
  }
  const modalCallBack = () => {
    setModalValues(modalComponentInitialState)
    navigate('/select-pump')
  }
  const openEnquiryPopup = () => {
    let message = '';
    if(selection_criteria && selection_criteria.length >1){
      message = `An enquiry has been issued through the Flow-Select system for a Duty Point of ${selection_criteria[0].flow} USGPM at ${selection_criteria[0].head} Bar and ${selection_criteria[1].flow} USGPM at ${selection_criteria[1].head} Bar. We would like to know if there is a pump in your portfolio that can match the specified requirements in order to fulfill our project need. Any further information required you may contact the enclosed email.`;
    }else{
      message = `An enquiry has been issued through the Flow-Select system for a Duty Point of ${selection_criteria[0].flow} USGPM at ${selection_criteria[0].head} Bar. We would like to know if there is a pump in your portfolio that can match the specified requirements in order to fulfill our project need. Any further information required you may contact the enclosed email.`;
    }
    if(user){
      setEnquiryFormPopupData(
        {
          ...enquiryFormPopupData,
          userId:user.id,
          name: user.name,
          contact_number: user.contact_number,
          email: user.email,
          message:message,
          is_enquiry_send:true,
        }
      )
    }else{
      setEnquiryFormPopupData(
        {
          ...enquiryFormPopupData,
          userId:null,
          name: null,
          contact_number: null,
          email: null,
          message:message,
          is_enquiry_send:true,

        }
      )
    }
 
    setShowModal(false);
    setIsEnquiryModal(true)
   }
  async function masterApiCall() {

    const getFlowUnitData =  await FlowUnits();
    if(getFlowUnitData[0]) {
      setflowUnitsData(getFlowUnitData[1])
      setValues(values => {
        return values.map(value => {
          return value.id === 0 ? {...value, 'flowUOM': getFlowUnitData[1][0]} : value
        })
      }); 
    }
    const getHeadUnitData =  await HeadUnits();
    if(getHeadUnitData[0]) {
      setHeadUnitsData(getHeadUnitData[1])
      
      setValues(values => {
        return values.map(value => {
          return value.id === 0 ? {...value, 'headUOM': getHeadUnitData[1][0]} : value
        })
      });
    }

    const getCertificationData =  await Certification();
    if(getCertificationData[0]) {
      setCertificationData(getCertificationData[1])

      setValues(values => {
        return values.map(value => {
          return value.id === 0 ? {...value, 'certification': getCertificationData[1][0]} : value
        })
      });
    }

    const getDriverTypeData =  await DriverType();
    if(getDriverTypeData[0]) {
      setDriverTypeData(getDriverTypeData[1])

      setValues(values => {
        return values.map(value => {
          return value.id === 0 ? {...value, 'driverType': getDriverTypeData[1][0]} : value
        })
      });

    }
    const getCountryListData =  await CountryList();
    if(getCountryListData[0]) {
      setCountryData(getCountryListData[1])

      setCountryValue(getCountryListData[1][0].code)

      const newItem = getCountryListData[1].filter((cData) => {
        return cData.code === getCountryListData[1][0].code;
      });
      setCountryText(newItem[0].country)
      // setCountryValueJson({'country':newItem[0].country, 'code':getCountryListData[1][0].code})
    }
  }
  const setAPIData = () => {
    setflowUnitsData(flow_api_data)
    setHeadUnitsData(head_api_data)
    setCertificationData(certification_api_data)
    setDriverTypeData(driver_type_api_data)
    setCountryData(country_api_data)
    setValues(JSON.parse(JSON.stringify(selection_criteria)))
    setCountryValue(selected_country)
    const newItem = country_api_data.filter((cData) => {
      return cData.code === selected_country;
    });
    
    setCountryText(newItem[0].country)
    setCountryValueJson({'country':newItem[0].country, 'code':selected_country})

    formik.setFieldValue("criteria", JSON.parse(JSON.stringify(selection_criteria)));
    
    if(selection_criteria.length > 1) {
      setErrorText([
        ...errorText,
        {
          flow_error: '',
          head_error: '',
          certification_error: '',
          nfpa_error: '',
          minSpeed_error: '',
          maxSpeed_error: '',
          driverType_error: '',
          country_error:''
        },
      ]);
    }
  }

  

  const formik = useFormik({
    validateOnChange: false, // this one
    validateOnBlur: false, // and this one
    initialValues: {
        criteria: "",
        //criteria_error: "",
    },
    validate: (data) => {
        let errors = {};
        let isValid = true;
        
        const list = [...errorText];
       
        values.map((value, index) => {
            if (value.flow === NaN || value.flow === '' || value.flow === null) {
              
              list[index]["flow_error"] = "Required Pump Flow is required.";
              isValid = false;
            } 

            if (value.head === NaN || value.head === '' || value.head === null) {
              list[index]["head_error"] = "Required Pump Pressure is required.";
              isValid = false;
            } 
            if(countryValueJson === '' || countryValueJson === null){
              list[index]["country_error"] = "Country is required.";
              isValid = false;
            }
            
            // if (value.nfpa === 0) {
            //   list[index]["nfpa_error"] = "NFPA Rated flow is required.";
            //   isValid = false;
            // }

            if (value.minSpeed === NaN || value.minSpeed === '' || value.minSpeed === null) {
              list[index]["minSpeed_error"] = "Min speed is required.";
              isValid = false;
            }

            if (value.maxSpeed === NaN || value.maxSpeed === '' || value.maxSpeed === null) {
              list[index]["maxSpeed_error"] = "Max speed is required.";
              isValid = false;
            }
        });
        setErrorText(list);
        if (isValid) {
            return errors;
        } else {
            errors.multi_fields = "error";
            return errors;
        }
    },
    onSubmit: (data) => {
        const uid = String(
                      Date.now().toString(32) +
                        Math.random().toString(16)
                    ).replace(/\./g, '');

        dispatch(updateAPIStatus());
        let dataForAPI = {
          pumpType:selectPumpValues.name,
          countryCode:countryValue,
          sessionId:uid,
          criteria: data['criteria']
        }
        const criteriaData = data['criteria'];
        const selectPumpData = selectPumpValues;

        dispatch(setPumpSearchValues({criteria: criteriaData, select_pump:selectPumpData, selected_country:countryValue, selected_country_text:countryText, flow_api_data:flowUnitsData, head_api_data:headUnitsData, certification_api_data:certificationData, driver_type_api_data:driverTypeData, country_api_data:countryData}));

        dispatch(searchCriteria({data: dataForAPI}));

    },
  });
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  useEffect(() => {
    if (!formik.isSubmitting) return;
    const el = document.querySelector('.p-error');
    (el?.parentElement ?? el)?.scrollIntoView();
  }, [formik]);

  const handleAddClick = () => {
    setValues([
        ...values,
        {
          id:1,
          flow: '',
          flowUOM: flowUnitsData[0],
          head: '',
          headUOM: headUnitsData[0],
          certification: certificationData[0],
          nfpa: true,
          minSpeed: '2500',
          maxSpeed: '3000',
          driverType: driverTypeData[0]
        },
    ]);

    setErrorText([
        ...errorText,
        {
          flow_error: '',
          head_error: '',
          certification_error: '',
          nfpa_error: '',
          minSpeed_error: '',
          maxSpeed_error: '',
          driverType_error: '',
          country_error:''
        },
    ]);
  };
  const handleRemoveClick = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setValues(list);

    formik.setFieldValue("criteria", list);

    const errorList = [...errorText];
    errorList.splice(index, 1);
    setErrorText(errorList);
  };
  const handleChange = (e, index, name) => {
    let value = e.target.value;
    
    if(name === 'countryCode') {
      setCountryText(value.country)
      setCountryValueJson(value)
      setCountryValue(value.code)
    } else {
      // const list = [...values];
      const list = values.map(obj => ({ ...obj }));

      if(name === 'minSpeed' || name === 'maxSpeed' || name === 'flow' || name === 'head') {
        const regex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
        if (value === '' || regex.test(value)) {
          list[index][name] = value;
        }
        if(value.length === 8) 
          return false;
      } else {
        if(name === 'nfpa') {
          if(e.target.checked) {
            list[index][name] = true;
          } else {
            list[index][name] = false;
          }
        } else {
          list[index][name] = value;
        }
      }
      
      setValues(list);
      formik.setFieldValue("criteria", list);
    }
    
  };

  
  const changeTypeCall = () => {
    clearSelectPumpDetailsData();
    dispatch(clearValues());
    navigate("/select-pump")
  }

  const clearSelectPumpDetailsData = () => {
    setErrorText([
      {
        flow_error: '',
        head_error: '',
        certification_error: '',
        nfpa_error: '',
        minSpeed_error: '',
        maxSpeed_error: '',
        driverType_error: '',
        country_error:''
      }
    ]);
    setValues([
      {
        id: 0,
        flow: '',
        flowUOM: '',
        head: '',
        headUOM: '',
        certification:'',
        nfpa: true,
        minSpeed: '2500',
        maxSpeed: '3000',
        driverType: ''
      }
    ]);
    
    setflowUnitsData([])
    setHeadUnitsData([])
    setCertificationData([])
    setDriverTypeData([])
    setCountryData([])
  }
  useEffect(() => {
    if (isLoading) {
      document.body.classList.add('loader_view_listing');
    } else {
      document.body.classList.remove('loader_view_listing');
    }
    return () => {
      document.body.classList.remove('loader_view_listing');
    };
  }, [isLoading]);
  return (
    <>
        {
          (isLoading) && (<div className='show_loader_listing'>  <img src={loaderImage} /> </div>)
        }
      <div className='selected_pump_details'>
        <div className='container'>

          <form onSubmit={formik.handleSubmit} >
          
          <div className='selected_top_details'>
            <h1>Fire Pump Selection</h1>
                {/* {
                  (message_api) && (<ToastMessage showHidePanel={true} isError={1} message={message_api} />)
                } */}
              <div className='row'>
                  <div className='col-md-4 px_10 mb_24 mb_md_20 w_991_full'>
                    <div className='selected_pump_shows'>
                      <div className='selected_pump_img_name'>
                      <input type="radio" name="selectPump" className='d-none me-2'/>
                        <div className='pump_field_img'>
                          <img src={selectPumpValues.image} alt="pump selected"/>
                        </div>
                        <span className='pump_name_show'>{selectPumpValues.name}</span>
                      </div>
                      <div className='change_type'>
                        <a onClick={changeTypeCall}  href="javascript:;">Change Type</a>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4 px_10 mb_24 w_991_full prime_dropdown'>
                    <FormRowSelect
                      labelText='Select Country'
                      name='countryCode'
                      defaultValue={"placeholder"}
                      list={countryData}
                      value={countryValueJson}
                      handleChange={(e) =>
                        handleChange(
                            e,
                            0,
                            'countryCode'
                        )
                      }
                      callFrom='search_enquiry'
                    />
                     {(countryValueJson === '' || countryValueJson === null) && (
                              <small className="p-error">
                                  {
                                      errorText[0]
                                        .country_error
                                  }
                              </small>
                          )}
                  </div>
                 
              </div>
          </div>
          
            <div  className='selected_below_details_main'>
            {values.map((value, index) => {
            return (
              <div key={index}>
                <div className='selected_below_details'>
                  <div className='criteria_block'>
                    <h3 className='selected_heading'>SELECTION CRITERIA</h3>
                    {index === 1 && (
                    <a href='javascript:;' onClick={() => {handleRemoveClick(1)}} className='close_added'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.16699 15.8333L15.8337 4.16663" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15.8337 15.8333L4.16699 4.16663" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </a>)
                    }
                  </div>
                    <div className='row'>
                        <div className='col-md-4 px_10 mb_24 w_991_full'>
                          <div className='combine_fields'>
                            <FormRow
                              type='text'
                              id={`flow_${index}`}
                              name='Required Pump flow'
                              value={value.flow}
                              handleChange={(e) =>
                                handleChange(
                                    e,
                                    index,
                                    'flow'
                                )
                              }
                              
                            />
                            {
                              (flowUnitsData.length > 0) && (<FormRowSelect
                                name=''
                                list={flowUnitsData}
                                value={value.flowUOM}
                                handleChange={(e) =>
                                  handleChange(
                                      e,
                                      index,
                                      'flowUOM'
                                  )
                                }

                              />)
                            }
                            
                          </div>
                          {(errorText[index]
                              .flow_error) && (value.flow === NaN || value.flow === '' || value.flow === null) && (
                              <small className="p-error">
                                  {
                                      errorText[index]
                                        .flow_error
                                  }
                              </small>
                          )}
                        </div>

                        <div className='col-md-4 px_10 mb_24 w_991_full'>
                          <div className='combine_fields'>
                            <FormRow
                              type='text'
                              id={`head_${index}`}
                              name='Required Pump pressure'
                              value={value.head}
                              handleChange={(e) =>
                                handleChange(
                                    e,
                                    index,
                                    'head'
                                )
                              }
                              
                            />
                            
                            {
                              (headUnitsData.length > 0) && (<FormRowSelect
                                name=''
                                list={headUnitsData}
                                value={value.headUOM}
                                handleChange={(e) =>
                                  handleChange(
                                      e,
                                      index,
                                      'headUOM'
                                  )
                                }
                              />)
                            }
                          </div>
                          {(errorText[index]
                              .head_error) && (value.head === NaN || value.head === '' || value.head === null) && (
                              <small className="p-error">
                                  {
                                      errorText[index]
                                        .head_error
                                  }
                              </small>
                          )}
                        </div>
                        <div className='col-md-4 px_10 mb_24 w_991_full w-i_Pad_full'>
                          <div className='combine_field_checkbox'>
                            <div className='checkbox_inner_field'>
                              <FormRowSelect
                                  name='certification'
                                  list={certificationData}
                                  value={value.certification}
                                  handleChange={(e) =>
                                    handleChange(
                                        e,
                                        index,
                                        'certification'
                                    )
                                  }
                              />
                              
                              {/* <div className='mobile_certification_select'>
                                <FormRowSelect
                                  name='certification'
                                  list={certificationData}
                                  value={value.certification}
                                  handleChange={(e) =>
                                    handleChange(
                                        e,
                                        index,
                                        'certification'
                                    )
                                  }
                                />
                              </div> */}
                              {errorText[index]
                              .certification_error && (
                              <small className="p-error">
                                  {
                                      errorText[index]
                                        .certification_error
                                  }
                              </small>
                              )}
                              </div>
                              {/* <div className='checkbox_field'> */}
                                  {/* <FormRow
                                  type='checkbox'
                                  name='NFPA Rated flow'
                                  value='true'
                                  handleChange={handleChange}
                                /> */}
                                {/* <label for="checkbox_rate">NFPA Rated flow</label>
                                <input type="checkbox" name="checkbox_rate" id="checkbox_rate"/>
                              </div> */}
                                <div className="checkbox_block ">
                                  <input type="checkbox" name="nfpa" id="nfpa"
                                  value={value.nfpa}
                                  checked={(value.nfpa) ? 'checked' : ''}
                                  onClick={(e) =>
                                    handleChange(
                                        e,
                                        index,
                                        'nfpa'
                                    )
                                  }
                                  />
                                  <label></label>
                                  <span for="nfpa">NFPA Rated flow</span>
                                </div>
                                {errorText[index]
                                  .nfpa_error && (
                                  <small className="p-error">
                                      {
                                          errorText[index]
                                            .nfpa_error
                                      }
                                  </small>
                                )}
                            </div>
                        </div>
                        <div className='col-md-4 px_10 mb_24 w_991_full'>
                          <FormRow
                              type='text'
                              id={`minSpeed_${index}`}
                              name='Min Speed'
                              value={value.minSpeed}
                              handleChange={(e) =>
                                handleChange(
                                    e,
                                    index,
                                    'minSpeed'
                                )
                              }
                              
                            />
                            {(errorText[index]
                                .minSpeed_error) && (value.minSpeed === NaN || value.minSpeed === '' || value.minSpeed === null) && (
                                <small className="p-error">
                                    {
                                        errorText[index]
                                          .minSpeed_error
                                    }
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 px_10 mb_24 w_991_full'>
                        <FormRow
                              type='text'
                              id={`maxSpeed_${index}`}
                              name='Max Speed'
                              value={value.maxSpeed}
                              handleChange={(e) =>
                                handleChange(
                                    e,
                                    index,
                                    'maxSpeed'
                                )
                              }
                              
                            />
                            {(errorText[index]
                                .maxSpeed_error) && (value.maxSpeed === NaN || value.maxSpeed === '' || value.maxSpeed === null) && (
                                <small className="p-error">
                                    {
                                        errorText[index]
                                          .maxSpeed_error
                                    }
                                </small>
                            )}
                        </div>
                        <div className='col-md-4 px_10 mb_24 w_991_full'>
                        <FormRowSelect
                            name='Driver Type'
                            list={driverTypeData}
                            value={value.driverType}
                            handleChange={(e) =>
                              handleChange(
                                  e,
                                  index,
                                  'driverType'
                              )
                            }
                          />
                        </div>
                    </div>
                </div>
                {values.length === 1 && (
                    <div className='extra_pumpset'>
                        <a href='javascript:;' onClick={handleAddClick} className='add_extra'>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Add">
                            <g id="add">
                            <path id="Vector" d="M2.66699 8H13.3337" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Vector_2" d="M8 13.3333V2.66663" stroke="#ED1D24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            </g>
                          </svg>
                          <span>Add Extra Pumpset</span>           
                        </a>
                    </div>)
              }
            </div>)
          })}
          </div>
          <div className='bottom_action_btns'>
            <div className='full_hr'></div>
              <button className='darkred_btn' disabled={isLoading}>
                    <span>{isLoading ? 'loading...' : 'Search Pump'} </span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                </button>
                {
                  (isEnquiryModal) && <PopupModalEdit callFrom="Enquiry" addOrEditMode='Send' onCloseCallBack={onCloseCallBack} rowdata={enquiryFormPopupData} locationData={country_api_data} enquiryType="dutypoint" />
                }  
                 {/* {modalValues.showModal && (
                    <ModalComponent
                        showModal={modalValues.showModal}
                        title={modalValues.title}
                        content={modalValues.content}
                        redirectLink={modalValues.redirectLink}
                        modalCallBack={modalCallBack} 
                    />
                    
                  )}    */}
                  <Modal show={modalValues.showModal} centered className="delete_popup_modal" backdrop="static">
                    {/* <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Stock Pumps</h2></Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <p className="confirm_msg">{modalValues.content}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="darkred_btn" onClick={modalCallBack}>ok</Button>
                    </Modal.Footer>
                </Modal>

                  <Modal show={showModal} centered className="delete_popup_modal" backdrop="static">
                    {/* <Modal.Header closeButton>
                        <Modal.Title className=""><h2>Stock Pumps</h2></Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <p style={{textAlign: 'justify'}} className="confirm_msg">We apologize, but we are unable to display the search results for the requested duty point specification. However, I am confident our engineering team can assist you with this requirement. Would you like me to help you submit the Enquiry? That way, our team can further evaluate your specific requirements and get you the solution you need.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="outline_btn" variant="secondary"  onClick={handleClose}>No</Button>
                        <Button variant="primary" className="darkred_btn" onClick={openEnquiryPopup}>Yes</Button>
                    </Modal.Footer>
                </Modal>
          </div>
          </form>
        </div>
      </div>
    </>
    // <div>
    //   SelectPumpDetail Page
    //   <Link to='/select-pump-list'>Select Pump List</Link>
    // </div>
  )
}

export default SelectPumpDetail
