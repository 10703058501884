import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
  removeUserFromLocalStorage,
} from '../../utils/localStorage';
import {
  loginUserThunk,
  registerUserThunk,
  updateUserThunk,
  clearStoreThunk,
  forgotPasswordThunk,
  resendVerifyEmailLinkThunk,
  resetPasswordUserThunk
} from './userThunk';

const initialState = {
  isLoading: false,
  isSidebarOpen: false,
  isSuccessAPI: false,
  apiErrorMessage: '',
  isResendVerifyEmailLinkEnable:0,
  user: getUserFromLocalStorage(),
};

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async (user, thunkAPI) => {
    return registerUserThunk('/register', user, thunkAPI);
  }
);

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (user, thunkAPI) => {
    return loginUserThunk('/login', user, thunkAPI);
  }
);

export const forgotPasswordUser = createAsyncThunk(
  'user/forgotPasswordUser',
  async (user, thunkAPI) => {
    return forgotPasswordThunk('/forgot-password', user, thunkAPI);
  }
);

export const resendVerifyEmailLink = createAsyncThunk(
  'user/resendVerifyEmailLink',
  async (user, thunkAPI) => {
    return resendVerifyEmailLinkThunk('/resend_verify_email', user, thunkAPI);
  }
);

export const resetPasswordUser = createAsyncThunk(
  'user/resetPasswordUser',
  async (user, thunkAPI) => {
    return resetPasswordUserThunk('/reset-password', user, thunkAPI);
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user, thunkAPI) => {
    return updateUserThunk('/updateUser', user, thunkAPI);
  }
);

export const clearStore = createAsyncThunk('user/clearStore', clearStoreThunk);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearSuccessStatus: (state) => {
      state.isResendVerifyEmailLinkEnable = 0;
      state.isSuccessAPI = false;
      state.apiErrorMessage = '';
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    logoutUser: (state, { payload }) => {
      state.user = null;
      state.isLoading = false;
      state.isSuccessAPI = false;
      state.apiErrorMessage = '';
      state.isResendVerifyEmailLinkEnable = 0;
      removeUserFromLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccessAPI = false;
        state.apiErrorMessage = '';
        state.user = '';
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        const { error, status, message, result } = payload;
        //alert(user)
        state.isLoading = false;
        if(status) {
          state.isSuccessAPI = true;
        } else {
          state.isSuccessAPI = false;
          state.apiErrorMessage = message;
          if(error.length > 0) {
            state.apiErrorMessage = error[0];
          }
        }
      })
      .addCase(registerUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isSuccessAPI = false;
        state.apiErrorMessage = '';
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        const { error, status, message, result } = payload;
        //alert(user)
        state.isLoading = false;
        if(status) {
          //alert(JSON.stringify(result))
          const { original } = result[0];
          const { user, access_token } = original;
          
          state.isSuccessAPI = true;
          state.user = { name: user.name, role:user.role, email:user.email, id:user.id, company_id:user.company_id, contact_number:user.contact_number, profile_picture:user.profile_picture, company_name:user.company_name, access_token: access_token };
          addUserToLocalStorage(state.user);
        } else {
          if(result.is_resend_verify_email) {
            state.isResendVerifyEmailLinkEnable = 1;
          }
          state.isSuccessAPI = false;
          state.apiErrorMessage = message;
          if(error.length > 0) {
            state.apiErrorMessage = error[0];
          }
        }
      })
      .addCase(loginUser.rejected, (state, {payload}) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      })
      .addCase(forgotPasswordUser.pending, (state) => {
        state.isResendVerifyEmailLinkEnable = 0;
        state.isLoading = true;
        state.isSuccessAPI = false;
        state.apiErrorMessage = '';
        state.user = '';
      })
      .addCase(forgotPasswordUser.fulfilled, (state, { payload }) => {
        const { error, status, message, result } = payload;
        //alert(user)
        state.isLoading = false;
        if(status) {
          state.isSuccessAPI = true;
          state.user = result.user;
        } else {
          if(result.is_resend_verify_email) {
            state.isResendVerifyEmailLinkEnable = 1;
          }
          state.isSuccessAPI = false;
          state.apiErrorMessage = message;
          if(error.length > 0) {
            state.apiErrorMessage = error[0];
          }
        }
      })
      .addCase(forgotPasswordUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      })
      .addCase(resendVerifyEmailLink.pending, (state) => {
        state.isResendVerifyEmailLinkEnable = 0;
        state.isLoading = true;
        state.isSuccessAPI = false;
        state.apiErrorMessage = '';
        state.user = '';
      })
      .addCase(resendVerifyEmailLink.fulfilled, (state, { payload }) => {
        const { error, status, message, result } = payload;
        //alert(user)
        state.isLoading = false;
        if(status) {
          state.isSuccessAPI = true;
          state.user = result.user;
        } else {
          state.isSuccessAPI = false;
          state.apiErrorMessage = message;
          if(error.length > 0) {
            state.apiErrorMessage = error[0];
          }
        }
      })
      .addCase(resendVerifyEmailLink.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      })
      .addCase(resetPasswordUser.pending, (state) => {
        state.isResendVerifyEmailLinkEnable = 0;
        state.isLoading = true;
        state.isSuccessAPI = false;
        state.apiErrorMessage = '';
        state.user = '';
      })
      .addCase(resetPasswordUser.fulfilled, (state, { payload }) => {
        const { error, status, message, result } = payload;
        //alert(user)
        state.isLoading = false;
        if(status) {
          state.isSuccessAPI = true;
          state.user = result.user;
        } else {
          state.isSuccessAPI = false;
          state.apiErrorMessage = message;
          if(error.length > 0) {
            state.apiErrorMessage = error[0];
          }
        }
      })
      .addCase(resetPasswordUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        const { user } = payload;
        state.isLoading = false;
        state.user = user;
        addUserToLocalStorage(user);

        toast.success(`User Updated!`);
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      })
      .addCase(clearStore.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessAPI = false;
        state.apiErrorMessage = 'Something went wrong.';
      });
  },
});

export const { toggleSidebar, logoutUser, clearSuccessStatus } = userSlice.actions;
export default userSlice.reducer;
