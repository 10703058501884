import React from "react";
import {EnquiriesHead} from "../../../components";
const SalesOrderTracking = () => {
    return(
        <>
            <div className="sales_order_tracking">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 order_search_listing">
                            <div className="table_heading_content">
                                <h1 className="sales_left_heading">Sales Order Tracking</h1>
                            </div>  
                            <div className="search_field_block">
                                <h3 className="search_heading">Sales orders</h3>
                                <div className="form-row"> 
                                    <span className="search_svg">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.66634 14.0002C11.1641 14.0002 13.9997 11.1646 13.9997 7.66683C13.9997 4.16903 11.1641 1.3335 7.66634 1.3335C4.16854 1.3335 1.33301 4.16903 1.33301 7.66683C1.33301 11.1646 4.16854 14.0002 7.66634 14.0002Z" stroke="#868594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M14.6663 14.6668L13.333 13.3335" stroke="#868594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    </span>
                                    <input className="search_field" placeholder="Search" />
                                </div>
                                <ul className="search_orders_listing">
                                    <li>
                                        <a href="#" className="order_search_items active">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="order_search_items">
                                            <span className="order_id_heading"># SO - D11014832</span>
                                            <span className="order_location">Location: Botswana</span>
                                            <span className="order_purchaser">Purchaser: Eclipse International Trading LLC</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-8 order_details_col">
                            <div className="track_order_details">
                                <EnquiriesHead heading="Order Details"/>
                                <div className="details_block">
                                    <div className="inner_details">
                                        <div className="row">
                                            <div className="col-md-6 ipad_col_50_100">
                                                <div className="form-row">
                                                <label>Order ID</label>
                                                <p className="details_data align_btn">NF-S-150-280
                                                    <span className="order_open">ORDER OPEN</span>
                                                </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 ipad_col_50_100">
                                                <div className="form-row">
                                                <label>PUMP DUTY POINT</label>
                                                <p className="details_data">100 GPM @ 81 M</p>
                                                </div>
                                            </div>
                                            <div className="col-md-2 ipad_col_50_100">
                                                <div className="form-row">
                                                <label>LOCATION</label>
                                                <p className="details_data">Botswana</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 max_w_inner_310 ipad_col_50_100">
                                                <div className="form-row">
                                                    <label>PROJECT</label>
                                                    <p className="details_data">Botswana Embassy chancery office and diplomated Apartment building</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 max_w_inner_275 ipad_col_50_100">
                                                <div className="form-row">
                                                    <label>PUMP PURCHASER</label>
                                                    <p className="details_data">Eclipse International Trading LLC</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sales_track_status">
                                <div className="trackStatus_block">
                                    <h3 className="sub_details_head">Tracking Status</h3>
                                    <div className="steps_flow track_flow">
                                        <ul className="progress_bar">
                                        <li className="active">
                                            <div className="step_data">
                                            <p className="step_info">Offer Created</p>
                                            <p className="step_date">01/03/2023</p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        <li className="">
                                            <div className="step_data">
                                            <p className="step_info">Offer Submitted</p>
                                            <p className="step_date">01/03/2023</p>
                                            </div>
                                            <span className="fix_border"></span>
                                            <button className="transparent_btn download_btn">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 7.33337V11.3334L7.33333 10" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M5.99984 11.3333L4.6665 10" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.6668 6.66671V10C14.6668 13.3334 13.3335 14.6667 10.0002 14.6667H6.00016C2.66683 14.6667 1.3335 13.3334 1.3335 10V6.00004C1.3335 2.66671 2.66683 1.33337 6.00016 1.33337H9.3335" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.6668 6.66671H12.0002C10.0002 6.66671 9.3335 6.00004 9.3335 4.00004V1.33337L14.6668 6.66671Z" stroke="#ED1D24" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                Download
                                            </button>
                                        </li>
                                        <li className=""> 
                                            <div className="step_data">
                                            <p className="step_info">Submittal Completed</p>
                                            <p className="step_date">02/03/2023</p>
                                            </div>
                                            <span className="fix_border"></span>
                                            <button className="request">Request</button>
                                        </li>
                                        <li className="">
                                            <div className="step_data">
                                                <p className="step_info">Drawing Completed</p>
                                                <p className="step_date">03/03/2023</p>
                                            </div>
                                            <span className="fix_border"></span>
                                            <button className="in_progress">In Progress</button>
                                        </li>
                                        <li  className="won">
                                            <div className="step_data">
                                                <p className="step_info">Won</p>
                                                <p className="step_date"></p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        <li  className="dispatched">
                                            <div className="step_data">
                                                <p className="step_info">Dispatched</p>
                                                <p className="step_date"></p>
                                            </div>
                                            <span className="fix_border"></span>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesOrderTracking;