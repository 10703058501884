import img from '../assets/images/not-found.svg';
import Wrapper from '../assets/wrappers/ErrorPage';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Error = () => {
  const { user } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const callForRedirect = () => {
    if (!user) {
      navigate('/select-pump')
    } else {
      if (user.role === 'customer') {
        navigate('/select-pump')
      } else if (user.role === 'sales') {
        navigate('/work-in-progress')
      } else {
        navigate('/customer-list')
      }
    }
  }
  
  return (
    <Wrapper className='full-page new'>
      <div>
        <img src={img} alt='not found' />
        <h3>Ohh! Page Not Found</h3>
        <p>We can't seem to find the page you're looking for</p>
        <button className="btn btn-link"  onClick={callForRedirect}>back home</button>
      </div>
    </Wrapper>
  );
};

export default Error;
