import React from 'react';
import { useState, useEffect } from 'react';
import { FormRowSelect } from '../components';
import customFetch from "../utils/axios";
import { MultiSelect } from 'primereact/multiselect';
import { CountryList } from '../utils/clientMasterAPI';


const FilterModal = (props) => {

    const [allStatus, SetAllStatus] = useState([]);
    const [isLogout, setIsLogout] = useState(false);
    // const [errorMessage, setErrorMessage] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [countryValue, setCountryValue] = useState(null);
    const [enquirySendValue, setEnquirySendValue] = useState('');
    const [statusValue, setstatusValue] = useState(null);
    const [enquiryNumber, setEnquiryNumber] = useState('');

    const enquirySendData = ["No","Yes"]
    const closeMenu = () => {
      props.closeModal();
    };

    useEffect(() => {
      fetchEnquiryStatus();
      if(props.clearFilterData==true){
        setCountryValue(null);
        setstatusValue([]);
        setEnquirySendValue('');
        props.locationValue(null);
        props.statusValue(null);
        props.enquirySendValueCall(null);
        props.enquirySendNumberCall('')
        setEnquiryNumber('')
      }
      }, [props.clearFilterData]);

    const fetchEnquiryStatus = async () => {
        // setErrorMessage('');
        try {
          let enquiry_id = 40;
          if(enquiry_id) {
            const response = await customFetch.get(`/get_enquiry_status`);
            const { status, message, error, result } = response.data;
    
            if(status === true){
              const data = response.data.enquirylist;
              var statuslist = [];
              data.map((item)=>{
                statuslist.push({name:item.status_value,code:item.name});

              })
              
              SetAllStatus(statuslist);       
            }
          }

          const getCountryListData =  await CountryList();
          if(getCountryListData[0]) {
            setCountryData(getCountryListData[1])
      
          }
        
        } catch (err) {
          if (err.response.status === 401) {
            setIsLogout(true);
          }
            // setErrorMessage('There is something wrong. Please try again');
            window.scrollTo(0,0);
        }
      };

      const applyFilter = ()=>{
        props.locationValue(countryValue);
        props.statusValue(statusValue);
        props.enquirySendValueCall(enquirySendValue);
        props.enquirySendNumberCall(enquiryNumber);
        closeMenu();
      }
      const handleChange = (e, name) => {
        let value = e.target.value;
        
        if(name === 'countryCode') {
          setCountryValue(value)
        } else if(name === 'status'){
          setstatusValue(value);
        } else if(name === 'enquiry_send'){
          setEnquirySendValue(value);
        }
      }

    return(
        <>            
            <div className="filter_modal_content" >              
                <div className="filter_modal_head">
                    <h2>Filters</h2>
                    <a href="javascript:;" onClick={closeMenu}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 19L19 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19 19L5 5" stroke="#15223B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className="filter_modal_body">
                    <form>
                        <div className="new_label">
                          <label className='form-label'>Enquiry Number</label>
                            <div className='form-row'>
                            <input 
                              type="text" 
                              className="form-control" 
                              name="item_code"
                              onChange={ (e) => setEnquiryNumber(e.target.value) }
                            />
                          </div>
                        </div>
                          
                          
                          {/* <FormRowSelect
                            name='Product Type'
                            list={statusOptions}
                          /> */}
                          <div className='new_label'>
                            <label>Country</label>
                            <FormRowSelect
                            name='filter_countryCode'
                            list={countryData}
                            value={countryValue}
                            // labelText='Select Location'
                            placeholder="Select Location" 
                            defaultValue={"placeholder"}
                            handleChange={(e)=>{ handleChange(e,'countryCode')}}
                            callFrom='enquiry_filter'
                          />
                          </div>
          

                          <div className="form-row">
                            <label>Enquiry Status</label>
                              <MultiSelect value={statusValue} optionLabel="name" onChange={(e) => handleChange(e,'status')} options={allStatus}  display="chip" 
                                  placeholder="Enquiry Status"  className="w-ful" />
                          </div>
                          <div className='new_label'>
                          <label>Enquiry Send</label>
                            <FormRowSelect
                                name='enquiry_send'
                                list={enquirySendData}
                                value={enquirySendValue}
                                placeholder="Select Enquiry Send" 
                                defaultValue={"placeholder"}
                                handleChange={(e)=>{ handleChange(e,'enquiry_send')}}
                                callFrom='enquiry_filter'
                              />
                          </div>
                          
                        <button type='button' onClick={applyFilter} className='w-100 darkred_btn'>
                            <span>Apply Filter</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default FilterModal;