import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useState, useRef } from "react";
import { CustomerService } from "../utils/productDummyData";
import { useEffect } from 'react';
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { useNavigate, useLocation } from "react-router-dom";
import customFetch from "../utils/axios";
// import { HandleUnauthorization } from "../../utils/HandleUnauthorization";

const CompareTable = (props) => {

    const [customers, setCustomers] = useState([]);
    const [stockarray, setStockArray] = useState([]);
    const [stocklist, setStockList] = useState([]);
    const secretKey = 'NAFFCO2023@GALAXY';
    const search = useLocation().search;
    const stockdata = new URLSearchParams(search).get('r');
    const navigate = useNavigate();

    // const [errorMessage, setErrorMessage] = useState('');
    // const [enquiryId, setEnquiryId] = useState('');
    const [flow_array, setFlowArray] = useState([]);
    const [pressure_array, setPressureArray] = useState([]);
    const [speed_array, setSpeedArray] = useState([]);
    const [basic_array, setBasicArray] = useState([]);
    const [tech_array, setTechArray] = useState([]);

    useEffect(() => {
        CustomerService.getCustomersMedium().then((data) => setCustomers(data));
        if (stockdata === null) {
            navigate('/stock-availability');
          } else {
              let stockdataDecrypt = AES.decrypt(stockdata, secretKey);

              if (stockdataDecrypt === "") {
                  navigate('/stock-availability');
              }
              else {
                  const requestRow = JSON.parse(stockdataDecrypt.toString(Utf8));
                  setStockArray(requestRow);
                  setTimeout(() => {
                    // setBasicArray([]);
                    fetchStock(requestRow);
                  }, 500);
                  
              }
            }
          }, [stockdata]);
    
    const fetchStock = async (data) => {
        // setErrorMessage('');
        try {
            const response = await customFetch.get(`/compare_stock`,{
                params: {selected_stocks: data, session_id : localStorage.getItem('sessionId')}
                });
            const stocklist = response.data.stocklist;

            setStockList(stocklist);
            props.stoklist(stocklist);

            setFlowArray([]);
            setPressureArray([]);
            setSpeedArray([]);
            var freq_array = [];
            var shutof_array = [];
            var duty_efficiency_array = [];
            var duty_power_array = [];
            var location_array = [];

            stocklist.map((item,index)=>{
                flow_array[index] = item.flow === null? '-':item.flow+ ' '+item.flow_uom;
                pressure_array[index] = item.pressure === null? '-':item.pressure+ ' '+item.pressure_uom;
                speed_array[index] = item.speed === null? '-':item.speed;
                freq_array[index] = item.frequency=== null? '-':item.frequency;
                shutof_array[index] = item.shut_off_pressure=== null? '-':item.shut_off_pressure;
                duty_efficiency_array[index] = item.duty_efficiency=== null? '-':item.duty_efficiency;
                duty_power_array[index] = item.duty_power=== null? '-':item.duty_power;
                location_array[index] = item.location=== null? '-':item.location;
                
            })
            setBasicArray([]);
            setTechArray([]);
            if(stocklist.length > 2){
                setBasicArray(prevArray => [...prevArray, {'data1':flow_array[0],'data2':flow_array[1],'data3':flow_array[2],'name':'Flow'},{'data1':pressure_array[0],'data2':pressure_array[1],'data3':pressure_array[2],'name':'Pressure'},{'data1':speed_array[0],'data2':speed_array[1],'data3':speed_array[2],'name':'Speed'},{'data1':location_array[0],'data2':location_array[1],'data3':location_array[2],'name':'Location'}]);
                
                setTechArray(prevArray => [...prevArray, {'data1':shutof_array[0],'data2':shutof_array[1],'data3':shutof_array[2],'name':'Shut Off Pressure'},{'data1':duty_efficiency_array[0],'data2':duty_efficiency_array[1],'data3':duty_efficiency_array[2],'name':'Duty Efficiency'},{'data1':duty_power_array[0],'data2':duty_power_array[1],'data3':duty_power_array[2],'name':'Duty Power'},{'data1':freq_array[0],'data2':freq_array[1],'data3':freq_array[2],'name':'Hertz'}]);

            }else{
                setBasicArray(prevArray => [...prevArray,{'data1':flow_array[0],'data2':flow_array[1],'name':'Flow'},{'data1':pressure_array[0],'data2':pressure_array[1],'name':'Pressure'},{'data1':speed_array[0],'data2':speed_array[1],'name':'Speed'},{'data1':location_array[0],'data2':location_array[1],'name':'Location'}]);
                setTechArray(prevArray => [...prevArray, {'data1':shutof_array[0],'data2':shutof_array[1],'name':'Shut Off Pressure'},{'data1':duty_efficiency_array[0],'data2':duty_efficiency_array[1],'name':'Duty Efficiency'},{'data1':duty_power_array[0],'data2':duty_power_array[1],'name':'Duty Power'},{'data1':freq_array[0],'data2':freq_array[1],'name':'Hertz'}]);

            } 
    } catch (err) {
            console.log(err)
            // setErrorMessage('There is something wrong. Please try again');
            window.scrollTo(0,0);
        }
    };
    const headerTemplate = (data) => {
        return (
            <div className="flex align-items-center subHeading_compare">
                <span className="font-bold">{data.name}</span>
            </div>
            
        );
    };  

    /**states */
    const headerFirst = (data) => {
        return(
            <div className="compare_table_heading">
                <span className="color_code" style={{backgroundColor: "#FFC82C"}}></span>
                <span className="Product_code">{stocklist[0].model}</span>
                <span className="Product_code">(ID : {stocklist[0].code})</span>

            </div>
        );
    }
    const headerSecond = () => {
        return(
            <div className="compare_table_heading">
                <span className="color_code" style={{backgroundColor: "#ff8c00"}}></span>
                <span className="Product_code">{stocklist[1].model} </span>
                <span className="Product_code">(ID : {stocklist[1].code})</span>

            </div>
        );
    }
    const headerThird = () => {
        if(stocklist.length === 3){
            return(
                <div className="compare_table_heading">
                    <span className="color_code" style={{backgroundColor: "#ff0d00"}}></span>
                    <span className="Product_code">{stocklist[2].model} </span>
                    <span className="Product_code">(ID : {stocklist[2].code})</span>
                </div>
            );
        }
    }
    const data1 = (data) => {
        return (   
            <div className="compare_data" style={{ textTransform: 'capitalize' }}>{data.data1}</div>
        );
    }
    const data2 = (data) => {
        return (
            <div className="compare_data" style={{ textTransform: 'capitalize' }}>{data.data2}</div>
        );
    }
    const data3 = (data) => {
        return (
            <div className="compare_data" style={{ textTransform: 'capitalize' }}>{data.data3}</div>
        );
    }
    return (
        <div className="row">
            <div className="col-md-6 px_10">
                <div className="">
                    <h3 className="compare_title">Basic Information</h3>
                    {stocklist.length>0 && <div className="custom_dataTable">
                         <DataTable
                            value={basic_array}
                            dataKey="id"
                            rowGroupMode="subheader"
                            rowGroupHeaderTemplate={headerTemplate}
                            groupRowsBy="name" sortMode="single" sortField="name"
                            
                        >
                            <Column field="data1" header={headerFirst} body={data1} sortable></Column>
                            <Column field="data2" header={headerSecond} body={data2} sortable></Column>
                            {stocklist.length === 3 && <Column field="data3" header={headerThird} body={data3} sortable></Column>}
                        </DataTable>
                    </div>}
                </div>
            </div>
            <div className="col-md-6 px_10">
                <div className="">
                    <h3 className="compare_title">Technical Information</h3>
                    <div className="custom_dataTable">
                    {stocklist.length>0 &&  <DataTable
                            value={tech_array}
                            dataKey="id"
                            rowGroupMode="subheader"
                            rowGroupHeaderTemplate={headerTemplate}
                            groupRowsBy="name" sortMode="single" sortField="name"
                        >
                            <Column field="Flow" header={headerFirst} body={data1} sortable></Column>
                            <Column field="flowTwo" header={headerSecond} body={data2} sortable></Column>
                            {stocklist.length === 3 && <Column field="data3" header={headerThird} body={data3} sortable></Column>}
                        </DataTable>}
                    </div>
                </div>
            </div>
            {/* <div className="col-md-6 px_10">
                <div className="">
                    <h3 className="compare_title">Technical</h3>
                    <div className="custom_dataTable">
                        <DataTable
                            value={customers}
                            selection={selectedProducts}
                            dataKey="id"
                            rowGroupMode="subheader"
                            rowGroupHeaderTemplate={headerTemplate}
                            groupRowsBy="representative.name" sortMode="single" sortField="representative.name"
                        >
                            <Column field="Flow" header={headerFirst} body={flowName} sortable></Column>
                            <Column field="flowTwo" header={headerSecond} body={flowNameTwo} sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div> */}
        </div>
    );
}
export default CompareTable;