import customFetch from '../../utils/axios';

export const cartThunk = async (formData, thunkAPI) => {

  try {
    const resp = await customFetch.post('/add_cart_enquiry', formData.data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
};