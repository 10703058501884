import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { FormRow, FormRowSelect } from '../components';
import { useFormik } from "formik";
import customFetch from "../utils/axios";
import { useDispatch, useSelector } from 'react-redux';
import closeSvg from '../assets/images/CloseWhite.svg';
import { postEnquiry, getSubmittal } from "../features/pumpSelection/pumpSelectionSlice";
import ToastMessage from "./ToastMessage";
import { CountryPhoneCodeList } from "../utils/clientMasterAPI";

const initialState = {
    firstName: '',
    lastName: '',
    companyName:'',
    contact_number:'',
    email: '',
    role: '',
    status: '',
    companyId: '',
    userId:'',
  };
  
  
function PopupModalEdit({callFrom, addOrEditMode, onCloseCallBack, rowdata, locationData,enquiryType}) {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const [isLoadingOnPage, setIsLoadingOnPage] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');
    const [formData, setFormData] = useState([]);
    const [isDisableEmail, setIsDisableEmail] = useState(false);
    const [countryPhoneCodeData, setCountryPhoneCodeData] = useState([]);
    const [pumpCountValue, setPumpCountValue] = useState(0);
    const [count, setCount] = useState(0);
    const [isDisableName, setIsDisableName] = useState(false);

    const { isLoading, pump_selection, selection_criteria, search_result_data_selected, selected_country_text, selected_country, status_api, message_api, submittal_file, submittal_status_api } = useSelector((store) => store.pumpSelection);
    const { user } = useSelector((store) => store.user);

    useEffect(() => {
        if(callFrom === 'Enquiry' && enquiryType !== 'dutypoint') {
            if (submittal_file && submittal_status_api) {
                const finalData = {
                    'submittal_file': submittal_file,
                    'enquiry_data': formData,
                    'pump_selection': pump_selection[0].name,
                    'country' :selected_country_text,
                    'search_criteria_data': selection_criteria,
                    'pump_selection_data': [search_result_data_selected],
                }
                dispatch(postEnquiry({data: finalData}));
            } 
            
            if (status_api) {
                let isEnquirySend = formData.is_enquiry_send;
                // if(formData.is_enquiry_send === false || formData.is_enquiry_send === '') {
                //     downloadFile()
                // }
                setFormData([]);
                formik.resetForm();
                onCloseCallBack('Post Successfully.',isEnquirySend);
            }
        }
    }, [status_api, submittal_file]);

    useEffect(() => {
        if(user) {
          if(user.role === 'customer' && callFrom === 'Enquiry') {
            setIsDisableEmail(true)
          }
          if(callFrom === 'Customer') {
            setIsDisableName(true)
          }
        }
      }, [user]);
    
    useEffect(() => {
        if(rowdata) {
            formik.setFieldValue("firstName", rowdata.first_name)
            formik.setFieldValue("lastName", rowdata.last_name)
            formik.setFieldValue("companyName", rowdata.company_name)
            formik.setFieldValue("email", rowdata.email)
            formik.setFieldValue("role", rowdata.role)
            formik.setFieldValue("status", rowdata.status)
            formik.setFieldValue("companyId", rowdata.company_id)
            if(addOrEditMode === 'Edit') {
               formik.setFieldValue("userId", rowdata.id)
                if(rowdata.contact_number)
                    formik.setFieldValue("contact_number", rowdata.contact_number)
                else
                    formik.setFieldValue("contact_number", 'N/A')
            }
            if(callFrom === 'Enquiry') {
                formik.setFieldValue("userId", rowdata.userId)
                formik.setFieldValue("contact_number", rowdata.contact_number)
                
                if(selected_country_text && selected_country) {
                    formik.setFieldValue("location", selected_country)
                    formik.setFieldValue("location_text", selected_country_text)
                    formik.setFieldValue("location_json", {'country':selected_country_text, 'code':selected_country})
                } else {
                    formik.setFieldValue("location", locationData[0].code)
                    const newItem = locationData.filter((cData) => {
                        return cData.code === locationData[0].code;
                    });
                    formik.setFieldValue("location_text", newItem[0].country)
    
                    formik.setFieldValue("location_json", {'country':locationData[0].country, 'code':locationData[0].code})
                }
                
            }
        }
        if(callFrom === 'Enquiry') {
            //alert(1)
            masterApiCall();
            let pumpCount = 0;
            if(search_result_data_selected.hasOwnProperty('first')) {
                pumpCount++;
                setPumpCountValue(pumpCount);
            }
            if(search_result_data_selected.hasOwnProperty('second')) {
                pumpCount++;
                setPumpCountValue(pumpCount);
            }
            //alert(pumpCount)
        }

        
    }, [rowdata]);

    const formik = useFormik({
        initialValues: (callFrom === 'Enquiry') ? rowdata : initialState,
        validate: (data) => {
            let errors = {};
            let isValid = true;

            if (!data.email) {
                errors.email = 'Email address is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. e.g. example@email.com';
            }
            if(formik.values.is_enquiry_send==true){
                if (data.message == null || data.message.trim() === '') {
                    errors.message = 'Message is required.';
                }
            }
            if(callFrom === 'Enquiry') {
                if (data.name == null || data.name.trim() === '') {
                    errors.name = 'Name is required.';
                } else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(data.name)) {
                    errors.name = 'Please use only alphabetic characters';
                }
                // if ((data.contact_number == null || data.contact_number.trim() === '') && (data.phone_code_json === '')) {
                //     errors.contact_number = 'Phone code and contact number is required.';
                // } else if (data.phone_code_json === '') {
                //     errors.contact_number = 'Phone code is required.';
                // }  
                if (data.contact_number == null || data.contact_number.trim() === '') {
                    errors.contact_number = 'Contact number is required.';
                } else if (!/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(data.contact_number)) {
                    errors.contact_number = 'Please use only numeric characters';
                } else if(data.contact_number.length < 9) {  
                    errors.contact_number = 'Please enter valid contact number';
                }
    
                
            } else {
                if (data.firstName == null || data.firstName.trim() === '') {
                    errors.firstName = 'First name is required.';
                } else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(data.firstName)) {
                    errors.firstName = 'Please use only alphabetic characters';
                }
                if (data.lastName == null || data.lastName.trim() === '') {
                    errors.lastName = 'Last name is required.';
                } else if (!/^[^-\s][a-zA-Z0-9_\s-]+$/i.test(data.lastName)) {
                    errors.lastName = 'Please use only alphabetic characters.';
                }
                if (data.role == null || data.role.trim() === '') {
                    errors.role = 'Please select Role.';
                }
            }
            
            if (isValid) {
                return errors;
            } else {
                return errors;
            }
        },
        onSubmit: (data) => {
          handleSubmit(data);
        }
    });
    useEffect(() => {
        if (!formik.isSubmitting) return;
        const el = document.querySelector('.p-error');
        (el?.parentElement ?? el)?.scrollIntoView();
    }, [formik]);

    useEffect(() => {
        if(formik.values.message !== undefined){
            setCount(formik.values.message.length);
        }
    }, [enquiryType]);
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
      return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    async function masterApiCall() {
        const getCountryCodeData =  await CountryPhoneCodeList();
        //alert(getCountryCodeData[0])
        if(getCountryCodeData[0]) {
          setCountryPhoneCodeData(getCountryCodeData[1])
        }
    }
    const handleSubmit = async (data) => {

        setFormData(data);
        if(callFrom === 'Enquiry' && enquiryType !== 'dutypoint') {
            // alert(data.is_enquiry_send)
            // alert(data.is_enquiry_send)
            // alert(JSON.stringify(search_result_data_selected))
            
            let finalSelectedIds = [];
            if(search_result_data_selected.hasOwnProperty('first')) {
                let selectedId = search_result_data_selected.first.id;
                finalSelectedIds.push(selectedId);
            }
            if(search_result_data_selected.hasOwnProperty('second')) {
                let selectedId = search_result_data_selected.second.id;
                finalSelectedIds.push(selectedId);
            }
            
            const finalData = {
                'id': finalSelectedIds,
                'appRegion': "naffco",
            }
            dispatch(getSubmittal({data: finalData}));
            
        } else if(enquiryType === 'dutypoint'){
            const finalData = {
                'submittal_file': null,
                'enquiry_data': data,
                'pump_selection': [],
                'country' :selected_country_text,
                'search_criteria_data': selection_criteria,
                'pump_selection_data': [search_result_data_selected],
            }
            dispatch(postEnquiry({data: finalData}));
            onCloseCallBack('Post Successfully.',true);
        }else {
            try {
                setApiErrorMessage('');
                setIsLoadingOnPage(true);
                const response = await customFetch.post('/update-user', data);
                const { status, message, error, result } = response.data;
    
                let finalApiError = "";
                if (status === false) {
                    if (error.length > 0) {
                        error.map(
                            (errorMessageData) =>
                                //finalApiError += '<li>'+errorMessage+'</li>'
                                (finalApiError = errorMessageData)
                        );
                    } else {
                        finalApiError = message;
                    }
                    setApiErrorMessage(finalApiError);
                    setIsLoadingOnPage(false);
                    window.scrollTo(0, 0);
                } else {
                    if(addOrEditMode==='Add'){
                        const email = data.email;
                        const response = await customFetch.post('/forgot-password', data);
                    }
                    onCloseCallBack(message);
                    setIsLoadingOnPage(false);
                    setShow(false);
                }
            } catch (err) {
                setIsLoadingOnPage(false);
                setApiErrorMessage(err);
                // console.log(err);
                //setErrorMessage('There is something wrong. Please try again');
                window.scrollTo(0, 0);
            }
        }
        
    };

    const handleClose = () => 
    {
        onCloseCallBack();
        setShow(false);
    }
    const countryChange = (e) => {
        let value = e.target.value;
        formik.setFieldValue("location", value.code)
        formik.setFieldValue("location_text", value.country)

        formik.setFieldValue("location_json", value)
    }
    const countryPhoneCodeChange = (e) => {
        let value = e.target.value;
        formik.setFieldValue("phone_code", value.phonecode)
        //alert(JSON.stringify(value))
        formik.setFieldValue("phone_code_json", value)
    }
    const onChangeMessage = (e) => {
        setCount(e.target.value.length)
    }
    return (
        <>
            <div className="edit_customer_modal">
                {/* <Button variant="primary" onClick={handleShow}>
                  Edit
                </Button> */}

                {/* please add className when enquiry popup open "user_popup_modal enquiries_popup_modal" */}
                <Modal data-keyboard="false" enforceFocus={false}  show={show} onHide={handleClose} centered className={`${(callFrom === 'User' || callFrom === 'Enquiry') ? (callFrom === 'Enquiry') ? 'user_popup_modal enquiries_popup_modal' : 'user_popup_modal' : "customer_popup_modal"}`} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title className=""><h2>{addOrEditMode} {callFrom}</h2></Modal.Title>
                        {
                            (!submittal_status_api) && (message_api) && (<ToastMessage showHidePanel={true} isError={1} message={message_api} />)
                        }
                        {
                            (!status_api) && (message_api) && (<ToastMessage showHidePanel={true} isError={1} message={message_api} />)
                        }
                    </Modal.Header>
                    {
                        (callFrom === 'Enquiry') && (pumpCountValue > 0) && (<div className="popup_pump_heading_block">
                        <h3 className="send_enquiry_heading">{pumpCountValue} {
                            (pumpCountValue === 1) ? 'Pump' : 'Pumps'
                        } Selected</h3>
                        </div>)
                    }
                    
                    <form className='edit_customer_form' onSubmit={formik.handleSubmit}>
                    {
                    (apiErrorMessage) && (<ToastMessage showHidePanel={true} isError={1} message={apiErrorMessage} />)
                    }
                    {/* {
                        (apiErrorMessage) && (
                            <div className="result_found_wrapper">
                            <div className="api-error container result_found error-found">
                                <p className="result_found_text">{apiErrorMessage}</p>
                                <button typ="button">
                                    <img src={closeSvg} alt='Close Button'/>
                                </button>
                            </div>
                            </div>)
                    } */}
                    <Modal.Body>
                  
                        {
                            (callFrom !== 'Enquiry') && (
                            <div className="field_wrapper">
                       
                            <FormRow 
                                type='text' 
                                labelText='first name'
                                name='firstName'
                                value={formik.values.firstName}
                                handleChange={formik.handleChange}
                                maxlengthValue={30}
                                isDisable={addOrEditMode === 'Edit' && isDisableName === true}
                            />
                            {getFormErrorMessage('firstName')}
                        </div>)
                        }
                        {
                            (callFrom !== 'Enquiry') && (
                        <div className="field_wrapper">
                            <FormRow 
                                type='text' 
                                labelText='last name'
                                name='lastName'
                                value={formik.values.lastName}
                                handleChange={formik.handleChange}
                                maxlengthValue={30}
                                isDisable={addOrEditMode === 'Edit' && isDisableName === true}
                            />
                            
                            {getFormErrorMessage('lastName')}
                        </div>)
                        }
                        {
                            (callFrom === 'Enquiry') && (
                        <div className="field_wrapper required_field">
                            <FormRow 
                                type='text' 
                                labelText='name'
                                name='name'
                                value={formik.values.name}
                                isDisable={addOrEditMode === 'Edit' || isDisableEmail === true}
                                handleChange={formik.handleChange}
                                maxlengthValue={60}
                            />
                            {getFormErrorMessage('name')}
                        </div>)
                        }
                        

                        <div className={`field_wrapper ${(callFrom === 'Enquiry') ? 'required_field' : ''}`}>
                            <FormRow 
                                type='text'
                                name='email'
                                isDisable={addOrEditMode === 'Edit' || isDisableEmail === true}
                                value={formik.values.email}
                                handleChange={formik.handleChange}
                                maxlengthValue={70}
                            />
                            {getFormErrorMessage('email')}
                        </div>
                        {
                            (callFrom === 'Enquiry' || callFrom === 'Customer') && ( <div className={`field_wrapper ${(callFrom === 'Enquiry') ? 'required_field' : ''}`}>
                                
                                <FormRow 
                                type='text' 
                                labelText='Contact'
                                name='contact_number'
                                value={formik.values.contact_number}
                                handleChange={formik.handleChange}
                                maxlengthValue={15}
                                isDisable={addOrEditMode === 'Edit' && isDisableName === true}
                            />
                            
                            {getFormErrorMessage('contact_number')}
                            </div>)
                        }
                        {
                            (callFrom === 'Customer') && (
                        <div className="field_wrapper">
                            <FormRow 
                                type='text' 
                                labelText='Company Name'
                                name='companyName'
                                value={formik.values.companyName}
                                handleChange={formik.handleChange}
                                maxlengthValue={30}
                                isDisable={addOrEditMode === 'Edit' && isDisableName === true}
                            />
                            
                            {getFormErrorMessage('lastName')}
                        </div>)
                        }
                        {/* send enquiries field start */}
                        {
                            (callFrom === 'Enquiry') && (
                        <div className="field_wrapper prime_dropdown">
                            <FormRowSelect
                                labelText='Select Location'
                                name='location'
                                list={locationData}
                                value={formik.values.location_json}
                                handleChange={(e)=>{ countryChange(e)}}
                                callFrom='enquiry_modal'
                            />
                           
                            {getFormErrorMessage('location')}
                        </div>)
                        }
                        {
                            formik.values.is_enquiry_send==true && (callFrom === 'Enquiry') && (
                        <div className="field_wrapper min_100 textarea_main required_field" >
                            
                            <div className="form-row">
                                <label>Message</label>
                                <textarea className="msg_textarea" name='message'
                                value={formik.values.message} onChange={(e) => {formik.handleChange(e); onChangeMessage(e)}}
                                maxlength="350"></textarea>
                                <span className="letter_count">{count}/350</span>
                            </div>
                            {getFormErrorMessage('message')}
                        </div>)
                        }
                        {
                            (callFrom === 'Enquiry') && (
                        <div className="field_wrapper min_100">
                            <div className="checkbox_main form-row">
                                <div className="checkbox_container">
                                    <div className="checkbox_block">
                                        <input type="checkbox" name="is_enquiry_send" id="is_enquiry_send"
                                        value={formik.values.is_enquiry_send}
                                        checked={(formik.values.is_enquiry_send) ? 'checked' : ''}
                                        onClick={formik.handleChange} />
                                        <label></label>
                                        <span htmlFor="is_enquiry_send">Send Enquiry</span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        }
                        {/* send enquiries field end */}



                        {
                            (callFrom === 'Customer') && (
                            <div className="field_wrapper">
                                <div className="form-row">
                                    <label htmlFor="email" className="form-label">Status</label>
                                    <div className="status_shows">
                                        <svg width="8" height="8" style={{ marginRight: '6px' }}>
                                        {(formik.values.status=='inactive')&&<circle cx="4" cy="4" r="4" fill="#F57E20" />}
                                        {(formik.values.status=='active')&&<circle cx="4" cy="4" r="4" fill="#0BDA6C" />}
                                        </svg>
                                        <div  style={{ textTransform: 'capitalize'}}>{formik.values.status}</div>
                                    </div>
                                </div>
                            </div>)
                        }
                        {
                            (callFrom === 'Customer') && (
                            <div className="field_wrapper">
                            <FormRow 
                                type='text' 
                                labelText='company id'
                                name='companyId'
                                value={formik.values.companyId}
                                handleChange={formik.handleChange}
                                maxlengthValue={20}
                                id="companyId"
                            />
                            </div>)
                        }
                        {
                            (callFrom === 'User') && (
                        <div className="field_wrapper">      
                         <div className="form-row">
                            <label className="form-label">Select Role</label>
                            <div className="form_radio_wrapper">
                                <div className="form_radio_block">
                                    <input id="Admin" type="radio" name="role" className="form-radio" value="admin"
                                    onClick={formik.handleChange} checked={formik.values.role === "admin"}
                                    />
                                    <label className="radio_label" htmlFor="Admin">Admin</label>
                                </div>
                                <div className="form_radio_block">
                                    <input id="Sales" type="radio" name="role" className="form-radio" value="sales"
                                    onClick={formik.handleChange} checked={formik.values.role === "sales"}
                                    
                                    />
                                    <label className="radio_label" htmlFor="Sales">Sales</label>
                                </div>
                                {getFormErrorMessage('role')}
                            </div>
                         </div>
                        </div>)
                        }
                    
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="outline_btn" onClick={handleClose} disabled={(callFrom === 'Enquiry') ? isLoading : isLoadingOnPage }>
                        Cancel
                        </Button>
                        <Button type="submit" className="darkred_btn " disabled={(callFrom === 'Enquiry') ? isLoading : isLoadingOnPage }>
                        {isLoadingOnPage ? 'loading...' : (callFrom === 'Enquiry') ? isLoading ? 'loading...' : 'Send' : addOrEditMode === 'Edit' ? (callFrom === 'Customer') ? 'Update Customer' : 'Update User' : 'Add User'}
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M14.9302 5.92993L21.0002 11.9999L14.9302 18.0699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4 12H20.83" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                        </Button>
                    </Modal.Footer>
                    </form>
                </Modal>
                
            </div>
        </>
    );
}

export default PopupModalEdit;
