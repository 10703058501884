import React from "react"; 
const ShowOrderDetail = () => {
    return(
        <>  
            <div className="details_block">
                <h3 className="sub_details_head">ORDER DETAILS</h3>
                <div className="inner_details">
                    <div className="row">
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>Order ID</label>
                          <p className="details_data align_btn">NF-S-150-280
                            <span className="order_open">ORDER OPEN</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>PUMP DUTY POINT</label>
                          <p className="details_data">100 GPM @ 81 M</p>
                        </div>
                      </div>
                      <div className="col-md-4 ipad_col_50_100">
                        <div className="form-row">
                          <label>LOCATION</label>
                          <p className="details_data">Botswana</p>
                        </div>
                      </div>
                        <div className="col-md-4 ipad_col_50_100">
                          <div className="form-row">
                            <label>PUMP PURCHASER</label>
                            <p className="details_data">Eclipse International Trading LLC</p>
                          </div>
                        </div>
                        <div className="col-md-8  iPad_col_100">
                          <div className="form-row">
                            <label>PROJECT</label>
                            <p className="details_data">Botswana Embassy chancery office and diplomated Apartment building</p>
                          </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="trackStatus_block">
                <h3 className="sub_details_head">TRACKING STATUS</h3>
                <div className="steps_flow track_flow">
                    <ul className="progress_bar">
                      <li className="active">
                        <div className="step_data">
                          <p className="step_info">Offer Created</p>
                          <p className="step_date">01/03/2023</p>
                        </div>
                        <span className="fix_border"></span>
                       </li>
                       <li className="active">
                        <div className="step_data">
                          <p className="step_info">Offer Submitted</p>
                          <p className="step_date">01/03/2023</p>
                        </div>
                        <span className="fix_border"></span>
                      </li>
                      <li className=""> 
                        <div className="step_data">
                          <p className="step_info">Submittal Completed</p>
                          <p className="step_date">02/03/2023</p>
                        </div>
                        <span className="fix_border"></span>
                      </li>
                      <li className="">
                           <div className="step_data">
                            <p className="step_info">Drawing Completed</p>
                            <p className="step_date">03/03/2023</p>
                          </div>
                          <span className="fix_border"></span>
                      </li>
                      <li  className="">
                           <div className="step_data">
                            <p className="step_info">Won</p>
                            <p className="step_date"></p>
                          </div>
                          <span className="fix_border"></span>
                      </li>
                      <li  className="">
                           <div className="step_data">
                            <p className="step_info">Dispatched</p>
                            <p className="step_date"></p>
                          </div>
                          <span className="fix_border"></span>
                      </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
export default ShowOrderDetail;