import React, { useState, useEffect } from "react";
import { DataTableField, EnquiriesHead } from '../../../components';
import customFetch from "../../../utils/axios";
import { HandleUnauthorization } from "../../../utils/HandleUnauthorization";

const CustomerList = () => {

  // const [errorMessage, setErrorMessage] = useState('');
  const [userlist, setUserList] = useState('');
  const [pagelimit, setpagelimit] = useState(10);
  const [pageNo, setpageNo] = useState(0);
  const [isLogout, setIsLogout] = useState(false);
  const [user_name, setUserName] = useState('');
  const [status_value, setStatusValue] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [company_name, setCompanyName] = useState('');

  const callBackList = () => {
    fetchUser();
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUser();
  }, [pageNo,user_name,status_value,user_email,company_name]);

  const changePage = (page=1)=>{
    setpageNo(page);
  }
  
const fetchUser = async () => {
  // setErrorMessage('');
  try {
      const response = await customFetch.get(`/userslist/customer/`+pagelimit+`?page=`+pageNo,{params:{
        name:user_name,
        status:status_value,
        email:user_email,
        company:company_name,
    }});
      const userlist = response.data.userslist;
      setUserList(userlist);

  } catch (err) {
      // console.log(err);
      if (err.response.status === 401) {
        setIsLogout(true)
      }
      // setErrorMessage('There is something wrong. Please try again');
      window.scrollTo(0,0);
  }
};
  const UserName = (value)=>{
    setUserName(value)
  }
  const statusValue = (value) =>{
    setStatusValue(value);
  }; 
  const UserEmail = (value) =>{
    setUserEmail(value);
  };
  const CompanyName = (value) =>{
    setCompanyName(value);
  }; 
    return (
      <>
      <div className="container">
        <div className="table_box">
         <EnquiriesHead heading="Customers" statusValue={statusValue} UserName={UserName}  UserEmail={UserEmail}  CompanyName={CompanyName} />
          <DataTableField callFrom="Customer" addOrEditMode="Edit" dataList={userlist} callBackList={callBackList} changePage={changePage} pagelimit={pagelimit} />
        </div>
        </div>
        {
          (isLogout) && (<HandleUnauthorization />)
        }
      </>
    )
  }
  
export default CustomerList